import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosEyeOff } from "react-icons/io";
import { IoEyeOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";

// COMPONENTS
import { loginValidationSchema } from "../../../Validation/validationSchemas";

// ASSETS (IMAGES AND SVG)
import { ReactComponent as FbIcon } from "../../../assets/images/svg/Icons/FacebookIcon.svg";
import { ReactComponent as GoogleIcon } from "../../../assets/images/svg/Icons/GoogleIcon.svg";
import { ReactComponent as Trendies } from "../../../assets/images/svg/signIn/HeaderLogo.svg";

// STYLES
import { Box, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import icons from "../../../assets/images/svg/icons";
import {
  customeGoogleLogin,
  customLogin,
  signupSocialAccount,
} from "../../../redux/slices/authSlice";
import "./SignIn.scss";

const SignIn = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSocialLoading, setIsSocialLoading] = useState(false);
  const [sdkReady, setSdkReady] = useState(false);

  const { MailIcon, LockIcon } = icons;

  useEffect(() => {
    const loadFacebookSDK = () => {
      if (window.FB) {
        window.FB.init({
          appId: process.env.REACT_APP_FB_APP_ID,
          cookie: true,
          xfbml: true,
          version: "v16.0",
        });
        setSdkReady(true);
      }
    };

    if (document.getElementById("facebook-jssdk")) {
      loadFacebookSDK();
    } else {
      const script = document.createElement("script");
      script.id = "facebook-jssdk";
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      script.async = true;
      script.defer = true;
      script.onload = loadFacebookSDK;
      document.body.appendChild(script);
    }
  }, []);

  const handleLogin = async (values) => {
    localStorage.setItem("loginViaSocial", false);
    try {
      setLoading(true);
      let loginValues = { ...values };

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (loginValues?.nickname && emailPattern.test(loginValues.nickname)) {
        loginValues.email = loginValues.nickname;
        delete loginValues.nickname;
      }

      const result = await dispatch(customLogin(loginValues));
      if (result.meta.requestStatus === "fulfilled") {
        toast.success(t("LOGIN_SUCCESS"));
        const isAdmin = result?.payload?.authData?.is_admin;

        navigate(isAdmin ? "/admin" : "/");
      } else {
        toast.error(t(result?.payload));
      }
    } catch (error) {
      console.error("LOGIN FAILED:", error);
      toast.error(t("LOGIN_FAILURE"));
    } finally {
      setLoading(false);
    }
  };

  // FORM VALIDATION USING FORMIK
  const formik = useFormik({
    initialValues: {
      password: "",
      nickname: "",
    },
    validationSchema: loginValidationSchema(t),
    validateOnBlur: false, // Disable validation on blur
    validateOnChange: false,
    onSubmit: (values) => handleLogin(values),
  });

  // TOGGLE PASSWORD VISIBILITY
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // GOOGLE LOGIN HANDLER
  const handleGoogleLogin = async (data) => {
    try {
      setIsSocialLoading(true);
      const token = data.access_token;
      localStorage.setItem("token", token);
      const userInfoResponse = await dispatch(customeGoogleLogin(token));
      if (userInfoResponse.meta?.requestStatus !== "fulfilled") {
        toast.error(t("FAILED_TO_FETCH_USER_INFO"));
        return;
      }

      const userInfo = await userInfoResponse?.payload;
      if (!userInfo.email) {
        toast.error(t("EMAIL_NOT_AVAILABLE_FOR_GOOGLE_LOGIN"));
        return;
      }
      const userData = {
        name: userInfo.name,
        email: userInfo.email,
        image: userInfo.picture,
      };
      handleSocialLogin(userData);
    } catch (error) {
      console.error("GOOGLE LOGIN ERROR:", error);
      toast.error(t("GOOGLE_LOGIN_ERROR"));
    } finally {
      setIsSocialLoading(false);
    }
  };

  // HANDLE SOCIAL LOGIN RESPONSE
  const handleSocialLogin = async (data) => {
    localStorage.setItem("loginViaSocial", true);
    const userData = {
      username: data.name,
      nickname: data.name,
      email: data.email,
      image: data.image,
    };

    try {
      setIsSocialLoading(true);
      const result = await dispatch(signupSocialAccount(userData));

      if (result.meta.requestStatus !== "fulfilled") {
        toast.error(t("ALREADY_REGISTERED_SOCIAL"));
        return;
      }

      if (result?.payload?.status === true) {
        toast.success(t("SIGN_UP_SUCCESS_WITH_SOCIAL"));

        localStorage.setItem("token", result?.payload?.data?.token);
        localStorage.setItem(
          "userData",
          JSON?.stringify(result?.payload.data?.authData)
        );

        navigate("/");
      }
    } catch (error) {
      console.log("error.message", error.message);
      console.error("SOCIAL LOGIN ERROR:", error);
      toast.error(error.message || t("SOCIAL_LOGIN_ERROR"));
    } finally {
      setIsSocialLoading(false);
    }
  };

  const handleSocialLoginError = (error) => {
    console.error("FACEBOOK LOGIN ERROR:", error);
  };

  return (
    <div className="Login">
      <div className="container">
        <div className="Login__inner">
          <Link to="/" className="themeBrand">
            <Trendies />
          </Link>
          <h2 className="Login__title">{t("SIGN_IN")}</h2>
          <form onSubmit={formik.handleSubmit} className="Login__form">
            <Box className="FormGroupOuter">
              <div className="form-group">
                <MailIcon />
                <input
                  type="text"
                  id="nickname"
                  name="nickname"
                  placeholder={t("ENTER_NICKNAME")}
                  value={formik.values.nickname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              {formik.errors.nickname && formik.touched.nickname ? (
                <div className="error-message">{formik.errors.nickname}</div>
              ) : null}
            </Box>
            <Box className="FormGroupOuter">
              <div className="form-group">
                <LockIcon />
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder={t("ENTER_PASSWORD")}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <span className="EyeIcon" onClick={togglePasswordVisibility}>
                  {showPassword ? (
                    <IoEyeOutline size={"1.5rem"} />
                  ) : (
                    <IoIosEyeOff size={"1.5rem"} />
                  )}
                </span>
              </div>
              {formik.errors.password && formik.touched.password ? (
                <div className="error-message">{formik.errors.password}</div>
              ) : null}
            </Box>

            <Link className="forgotPass" to="/forgot-password">
              {t("FORGOT_PASSWORD")} ?
            </Link>
            <div className="login-btn">
              <Button
                type="submit"
                className="btn"
                onClick={formik.handleSubmit}
              >
                {loading ? "Loading..." : t("SIGN_IN")}
              </Button>
            </div>
          </form>

          <div className="content-text">
            {t("BY_LOGGING_YOU_AGREE_TO")}
            <span> {t("PRIVACY_POLICY")} </span> {t("AND")} <br />
            <span> {t("TERMS_OF_USE")}</span>
          </div>

          <div className="continue-text">
            <p className="separatorText">
              {" "}
              <span>{t("OR_CONTINUE_WITH")}</span>
            </p>
          </div>

          <div className="social-icons">
            <LoginSocialGoogle
              isOnlyGetToken
              client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
              scope="profile email"
              onLoginStart={() => {}}
              onResolve={({ data }) => handleGoogleLogin(data)}
              onReject={(err) => console.error(err)}
            >
              <button className="social-button">
                <GoogleIcon />
              </button>
            </LoginSocialGoogle>

            <div>
              {sdkReady ? (
                <LoginSocialFacebook
                  appId={process.env.REACT_APP_FB_APP_ID || ""}
                  fieldsProfile="id,first_name,last_name,email,picture"
                  onResolve={({ data }) => {
                    data.image = data.picture?.data?.url;
                    data.name = data?.first_name;
                    handleSocialLogin(data);
                  }}
                  onReject={handleSocialLoginError}
                >
                  <button className="social-button">
                    <FbIcon />
                  </button>
                </LoginSocialFacebook>
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>

          <p className="signup-link">
            {t("DO_NOT_HAVE_AN_ACCOUNT")}?{" "}
            <Link to="/signup">
              {t("SIGN_UP")} {t("HERE")}
            </Link>
          </p>
        </div>
        {isSocialLoading && (
          <div className="loader-wrapper">
            <div className="loader"></div>
            <p>Loading...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignIn;
