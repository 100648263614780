import { Typography } from "@mui/material";
import { t } from "i18next";

function getTimeFromTimestamp(timestamp) {
  return new Date(timestamp).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
}

const MessageContent = ({ content }) =>
  content ? (
    <Typography variant="body1" className="content">
      {content}
    </Typography>
  ) : null;

const FilePreview = ({ fileUrl }) => {
  try {
    const url = new URL(fileUrl);
    const extension = url.pathname.split(".").pop().toLowerCase();

    if (extension === "pdf") {
      return (
        <div className="file-embed-preview">
          <div className="file-embed-preview__inner">
            <embed src={fileUrl} alt="PDF file" type="application/pdf" />
          </div>
        </div>
      );
    } else {
      return <img src={fileUrl} alt="ImageFile" />;
    }
  } catch (error) {
    console.error("Invalid file URL:", error);
    return <span>Invalid file format</span>;
  }
};

const Message = ({ msg, senderId }) => (
  <div
    className={`message ${msg.senderId === senderId ? "sender" : "receiver"}`}
  >
    <MessageContent content={msg.content} />
    {msg.files && typeof msg.files === "string" && (
      <div className="file-preview">
        <FilePreview fileUrl={msg.files} />
      </div>
    )}
    <Typography variant="body1" className="time">
      {getTimeFromTimestamp(msg.timestamp)}
    </Typography>
  </div>
);

const MessagesList = ({ messages, senderId, messageEndRef, loading, currentProductSellerBuyerDetails }) => (
  <div
    className={`messages ${currentProductSellerBuyerDetails?.product?.isSold ? "blurred" : ""}`}
    style={{ position: "relative" }}
  >
    {currentProductSellerBuyerDetails?.product?.isSold && (
      <div className="sold-out-overlay">
        <p className="sold-out-text">{t("PRODUCT_SOLD_OUT")}</p>
      </div>
    )}

    {loading && (
      <div className="">
        <div className=""></div>
        <p>Loading...</p>
      </div>
    )}
    {messages?.map((msg, index) => (
      <Message key={index} msg={msg} senderId={senderId} />
    ))}
    <div ref={messageEndRef} />
  </div>
);

export default MessagesList;
