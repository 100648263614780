import React, { useState } from "react";
import Products from "../Products/Products";
import Users from "../Users/Users";

import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { RiLoginBoxLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { ReactComponent as Cross } from "../../../assets/svg/cross.svg";
import { ReactComponent as Menu } from "../../../assets/svg/menu-new.svg";
import { ReactComponent as Trendies } from "../../../assets/svg/trendies_logo.svg";
import "./AdminDashboard.scss";

import { AiOutlineProduct } from "react-icons/ai";
import { CiUser } from "react-icons/ci";
import icons from "../../../assets/images/svg/icons";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [activeView, setActiveView] = useState("users");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [languageSelect, setLanguageSelect] = useState(false);
  const languageDataOption = [
    { label: "FR", value: "fr" },
    { label: "EN", value: "en" },
  ];

  const { Globe } = icons;

  const handleLanguageChange = () => {
    setLanguageSelect(!languageSelect);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      caretColor: "transparent",
    }),
  };

  return (
    <div
      className={`admin-dashboard ${
        isSidebarOpen ? "sidebar-open" : " sidebar-closed"
      }`}
    >
      <div
        className="sidebar-toggle"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        {isSidebarOpen ? <Cross /> : <Menu />}
      </div>
      {/* Sidebar */}
      <div className={`sidebar ${isSidebarOpen ? "collapsed" : ""}`}>
        <a href="/admin" class="themeBrand">
          <Trendies />
        </a>

        <ul>
          <li className={activeView === "users" ? "active" : ""}>
            <Link
              onClick={() => {
                setActiveView("users");
                setIsSidebarOpen(false);
              }}
              href=""
            >
              <CiUser /> <span className="sidebar_value">{t("USERS")}</span>
            </Link>
          </li>
          <li className={activeView === "products" ? "active" : ""}>
            <Link
              onClick={() => {
                setActiveView("products");
                setIsSidebarOpen(false);
              }}
              href=""
            >
              <AiOutlineProduct />{" "}
              <span className="sidebar_value">{t("PRODUCTS")}</span>
            </Link>
          </li>
        </ul>
      </div>

      <div className="AdminMainContent">
        <div className="container">
          <div className="top-bar">
            <div className="border-btn languageBtn">
              <Globe onClick={handleLanguageChange} />

              {languageSelect && (
                <div className="select-dropdown">
                  <Select
                    onChange={(selectedOption) => {
                      setLanguageSelect(!languageSelect);
                      i18n.changeLanguage(selectedOption?.value);
                    }}
                    options={languageDataOption}
                    styles={customStyles}
                    value={languageDataOption.find(
                      (option) => option.value === i18n.language
                    )}
                  />
                </div>
              )}
            </div>
            <div className="border-btn languageBtn" onClick={handleLogout}>
              <RiLoginBoxLine />
            </div>
          </div>
        </div>

        {activeView === "users" && <Users activeView={activeView} />}
        {activeView === "products" && <Products activeView={activeView} />}
      </div>
    </div>
  );
};

export default AdminDashboard;
