import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SearchService from "../services/SearchService";

export const performSearch = createAsyncThunk(
  "search/performSearch",
  async ({ query, params }, thunkAPI) => {
    try {
      return await SearchService.search(query, params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const searchSlice = createSlice({
  name: "search",
  initialState: {
    results: [],
    pagination: {
      total: 0,
      page: 1,
      totalPages: 0,
      limit: 10,
    },
    loading: false,
    error: null,
  },
  reducers: {
    resetSearchState: (state) => {
      state.results = [];
      state.pagination = {
        total: 0,
        page: 1,
        totalPages: 0,
        limit: 10,
      };
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(performSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(performSearch.fulfilled, (state, action) => {
        state.loading = false;
        state.results = action.payload.data.products;
        state.pagination = action.payload.data.pagination;
      })
      .addCase(performSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// EXPORT ACTIONS
export const { resetSearchState } = searchSlice.actions;
// EXPORT REDUCER
export default searchSlice.reducer;
