import { Box, Button, Typography } from "@mui/material";
import React from "react";

import "./ItemCard.scss";

import icons from "../../assets/images/svg/icons";
import { CapitalizedText } from "../../constraints/Home";

const ItemCard = (props) => {
  const { HeartIcon, HeartFill } = icons;
  const isFavorite = props?.userFavoriteProduct
    ? Object.values(props.userFavoriteProduct).some(
      (item) => item.productId === props?.productId
    )
    : false;
  return (
    <Box className={`SingleItemCard ${props.isSold ? "product-sold" : ""}`}>
      {props.isSold && (
        <span className={`payment-status pending`}>Sold Out</span>
      )}
      {!props?.isSold && props?.userData?.id !== props?.item?.userId && (
        <Button
          className="IconBtn"
          onClick={() => props?.handleSetFav(props?.productId)}
        >
          {isFavorite ? <HeartFill className="icon" /> : <HeartIcon />}
        </Button>
      )}

      <Box
        onClick={() =>
          props?.goToProductDetails(props?.productId)
        }
        className="SingleItemCardImage"
      >
        <img src={props.imageSrc} alt={props.name} />
      </Box>

      <Box className="SingleItemCardContent">
        <Typography variant="h6" className="SingleItemCardName">
          <CapitalizedText text={props?.name} />  <strong>{props.price}</strong>
        </Typography>
        <Typography variant="body2" className="SingleItemCardDescription">
          {props.description}
        </Typography>
      </Box>
    </Box>
  );
};

export default ItemCard;
