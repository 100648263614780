import partners6 from "./assets/jpeg/Footer/azulos_icon.png";
import partners1 from "./assets/jpeg/Footer/partners1.jpg";
import partners3 from "./assets/jpeg/Footer/partners3.jpg";
import partners4 from "./assets/jpeg/Footer/partners4.jpg";
import partners5 from "./assets/jpeg/Footer/partners5.jpeg";

export const OurPartners = [
  {
    id: 1,
    name: "Legit App",
    href: "https://legitapp.com/",
    src: partners1,
    alt: "partners1",
  },
  // {
  //   id: 2,
  //   name: "Mounier & Bouvard",
  //   href: "https://mounier-bouvard.com/",
  //   src: partners2,
  //   alt: "partners2",
  // },
  {
    id: 3,
    name: "Mounier ",
    href: "https://mounier-bouvard.com/",
    src: partners3,
    alt: "partners3",
  },
  {
    id: 4,
    name: "DHL",
    href: "https://www.mydhli.com/global-en/home.html",
    src: partners4,
    alt: "partners4",
  },
  {
    id: 5,
    name: "CMI",
    href: "https://www.cmi.co.ma/fr/solutions-paiement-ecommerce",
    src: partners5,
    alt: "partners5",
  },
  {
    id: 6,
    name: "Azuelos",
    href: "https://www.azuelos.ma/",
    src: partners6,
    alt: "partners6",
  },
  // {
  //   id: 7,
  //   name: "Alya",
  //   href: "https://www.alyapay.com/",
  //   src: partners7,
  //   alt: "partners7",
  // },
];
