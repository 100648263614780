import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import "./ResetPassword.scss";

import { Button } from "@mui/material";
import { t } from "i18next";
import { IoIosEyeOff } from "react-icons/io";
import { IoEyeOutline } from "react-icons/io5";
import icons from "../../../assets/images/svg/icons";
import { ReactComponent as Trendies } from "../../../assets/images/svg/signIn/HeaderLogo.svg";
import {
  changePassword,
  forgotPassword,
} from "../../../redux/slices/authSlice";

const ResetPassword = ({
  isChangePassword,
  userId,
  setChangePassword,
  getUserPassword,
  currentPasswordStatus,
}) => {
  const { LockIcon } = icons;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  // State management
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: ""
  });
  const [showPasswords, setShowPasswords] = useState({
    password: false,
    confirmPassword: false
  });
  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
    match: ""
  });
  const [loading, setLoading] = useState(false);

  // Password validation function
  const validatePassword = (password) => {
    const criteria = [];

    if (!password) {
      return t("PASSWORD_REQUIRED");
    }

    if (!/[a-z]/.test(password)) criteria.push(t("PASSWORD_LOWERCASE"));
    if (!/[0-9]/.test(password)) criteria.push(t("PASSWORD_NUMBER"));
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) criteria.push(t("PASSWORD_SPECIAL"));
    if (password.length < 6) criteria.push(t("PASSWORD_MIN_LENGTH"));

    return criteria.length > 0 ? criteria.join(", ") : "";
  };

  // Handle input changes
  const handleInputChange = (field) => (e) => {
    const value = e.target.value;
    setFormData(prev => ({ ...prev, [field]: value }));

    // Clear errors when typing
    setErrors(prev => ({
      ...prev,
      [field]: "",
      match: ""
    }));
  };

  // Toggle password visibility
  const togglePasswordVisibility = (field) => {
    setShowPasswords(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate passwords
    const passwordError = validatePassword(formData.password);
    const confirmPasswordError = validatePassword(formData.confirmPassword);
    const passwordsMatch = formData.password === formData.confirmPassword;

    setErrors({
      password: passwordError,
      confirmPassword: confirmPasswordError,
    });

    if (passwordError || confirmPasswordError) {
      return;
    }

    setErrors({
      match: !passwordsMatch ? t("Password_Not_Match") : ""
    });

    if (!passwordsMatch) {
      return;
    }

    setErrors({
      password: passwordError,
      confirmPassword: confirmPasswordError,
      match: !passwordsMatch ? t("Password_Not_Match") : ""
    });

    setLoading(true);
    try {
      const actionPayload = isChangePassword
        ? { userId, password: formData.password }
        : { token, password: formData.password };

      const action = isChangePassword ? changePassword : forgotPassword;
      const response = await dispatch(action(actionPayload)).unwrap();

      if (response.statusCode === 200) {
        if (isChangePassword) {
          await getUserPassword();
          setChangePassword(false);
        } else {
          navigate("/login");
        }
      }
    } catch (error) {
      setErrors(prev => ({
        ...prev,
        password: t("CHANGE_PASSWORD_FAILED")
      }));
    } finally {
      setLoading(false);
    }
  };

  // Handle body overflow
  useEffect(() => {
    document.body.style.overflow = isChangePassword ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isChangePassword]);

  if (!isChangePassword) return null;

  return (
    <div className="ResetPassword">
      <div className="container">
        <div className="ResetPassword__inner Login__inner">
          <a href="/" className="themeBrand">
            <Trendies />
          </a>
          <h2 className="Login__title">
            {isChangePassword
              ? currentPasswordStatus === "update_password"
                ? t("Update_Password")
                : t("Add_Password")
              : t("Reset_Password")}
          </h2>

          <form onSubmit={handleSubmit} className="ResetPassword__form Login__form">
            {/* Password Field */}
            <div className="formGroupWrapper">
              <div className="form-group">
                <LockIcon className="icon" />
                <input
                  type={showPasswords.password ? "text" : "password"}
                  value={formData.password}
                  onChange={handleInputChange("password")}
                  placeholder={t("New_Password")}
                  className="input-with-icon"
                />
                <span
                  className="EyeIcon"
                  onClick={() => togglePasswordVisibility("password")}
                >
                  {showPasswords.password ? <IoEyeOutline size="1.5rem" /> : <IoIosEyeOff size="1.5rem" />}
                </span>
              </div>
              {errors.password && <div className="error-message">{errors.password}</div>}
            </div>

            {/* Confirm Password Field */}
            <div className="formGroupWrapper">
              <div className="form-group">
                <LockIcon className="icon" />
                <input
                  type={showPasswords.confirmPassword ? "text" : "password"}
                  value={formData.confirmPassword}
                  onChange={handleInputChange("confirmPassword")}
                  placeholder={t("Confirm_Password")}
                  className="input-with-icon"
                />
                <span
                  className="EyeIcon"
                  onClick={() => togglePasswordVisibility("confirmPassword")}
                >
                  {showPasswords.confirmPassword ? <IoEyeOutline size="1.5rem" /> : <IoIosEyeOff size="1.5rem" />}
                </span>
              </div>
              {errors.confirmPassword && <div className="error-message">{errors.confirmPassword}</div>}
              {errors.match && <div className="error-message">{errors.match}</div>}
            </div>

            {/* Submit Button */}
            <div className="ResetPassword__btn login-btn">
              <Button type="submit" className="btn_dark" disabled={loading}>
                {loading
                  ? "Resetting..."
                  : isChangePassword
                    ? currentPasswordStatus === "update_password"
                      ? t("Update_Password")
                      : t("Add_Password")
                    : t("Reset_Password")}
              </Button>
            </div>

            {!isChangePassword && (
              <div className="ResetPassword__footer">
                <p>
                  Know your password? <Link to="/login">Log in here</Link>
                </p>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;