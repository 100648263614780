import { useState, useEffect } from "react";
import "./ScheduleModal.scss";
import { toast } from "react-toastify";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../utils/DateFormator";

const ScheduleModal = ({
  isOpen,
  onClose,
  userId,
  sellerId,
  productId,
  priceDetails,
  editTimeSlot,
  setEditTimeSlot,
  handlePayment,
}) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const timeSlots = {
    morning: ["8H-10H", "10H-12H"],
    afternoon: ["12H-14H", "14H-16H"],
    evening: ["16H-18H", "18H-20H"],
  };

  // Single selection state with complete delivery information
  const [selectedDelivery, setSelectedDelivery] = useState({
    day: "",
    month: "",
    year: "",
    time: "",
    receiverId: userId || "",
    sellerId: sellerId || "",
    productId: productId || "",
  });

  const [days, setDays] = useState([]);

  useEffect(() => {
    const today = new Date();
    const generatedDays = Array.from({ length: 7 }, (_, i) => {
      const day = new Date(today);
      day.setDate(today.getDate() + i);
      const formattedDate = formatDate(day, currentLanguage);
      return {
        fullDisplay: formattedDate.fullDisplay,
        day: formattedDate.day,
        month: formattedDate.month,
        year: formattedDate.year,
        dayName: formattedDate.dayName,
      };
    });
    setDays(generatedDays);
  }, [currentLanguage]);

  const handleSlotSelect = (dayInfo, time) => {
    setSelectedDelivery({
      day: dayInfo.day,
      month: dayInfo.month,
      year: dayInfo.year,
      time: time,
      userId: userId || "",
      sellerId: sellerId || "",
      productId: productId || "",
    });
  };

  const isSlotSelected = (dayInfo, time) => {
    return (
      selectedDelivery.day === dayInfo.day &&
      selectedDelivery.month === dayInfo.month &&
      selectedDelivery.year === dayInfo.year &&
      selectedDelivery.time === time
    );
  };

  const isSlotPassed = (dayInfo, slot) => {
    const day = new Date();
    const formattedDay = formatDate(day, currentLanguage);

    if (
      dayInfo.day === formattedDay.day &&
      dayInfo.month === formattedDay.month &&
      dayInfo.year === formattedDay.year
    ) {
      const currentHour = day.getHours();
      const [startHour] = slot.split("H-").map(Number);
      return currentHour >= startHour; // Disable slots that have ended
    }
    return false;
  };

  const handleSave = async () => {
    if (!selectedDelivery.time) {
      return toast.error(t("SELECT_TIME_SLOT"));
    }

    const createSoldProduct = async () => {
      try {
        const sendData = {
          sellerId,
          amountCalculated: priceDetails,
          receiverId: userId,
          productId,
          timeSlot: selectedDelivery,
          status: false,
        };
        let response;

        if (!editTimeSlot) {
          response = await axios.post(
            `${process.env.REACT_APP_API_URL}/create-sold-product`,
            sendData
          );
          if (response?.status === 200) {
            toast.success(t("UPDATE_TIME_SLOT"));
            handlePayment();
          }
        } else {
          response = await axios.post(
            `${process.env.REACT_APP_API_URL}/update-sold-product/${productId}/${userId}/${sellerId}`,
            sendData
          );
          if (response?.status === 200) {
            toast.success(t("UPDATE_TIME_SLOT"));
            handlePayment();
          }
        }
      } catch (error) {
        toast.error("Error updating time slot!");
        console.error(error);
      }
    };
    await createSoldProduct();
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="schedule-modal-overlay">
      <div className="schedule-modal-container">
        <header className="schedule-modal-header">
          <h2>{t("MY_AVAILABILITY")}...</h2>
        </header>

        <p className="schedule-modal-description">
          {t("INDICATE_AVAILABILITY_FOR_DELIVERY")}
        </p>

        <div className="schedule-modal-time-periods">
          <div></div>
          <div className="period-label">{t("MORNING")}</div>
          <div className="period-label">{t("AFTERNOON")}</div>
          <div className="period-label">{t("EVENING")}</div>
        </div>

        {days.map((dayInfo) => (
          <div key={dayInfo.fullDisplay} className="schedule-modal-day-row">
            <div className="day-label">{dayInfo.fullDisplay}</div>
            <div className="time-slot-group morning">
              {timeSlots.morning.map((slot) => (
                <button
                  key={slot}
                  className={`schedule-modal-time-slot ${
                    isSlotSelected(dayInfo, slot) ? "selected" : ""
                  }`}
                  onClick={() => handleSlotSelect(dayInfo, slot)}
                  disabled={isSlotPassed(dayInfo, slot)}
                >
                  {slot}
                </button>
              ))}
            </div>

            <div className="time-slot-group afternoon">
              {timeSlots.afternoon.map((slot) => (
                <button
                  key={slot}
                  className={`schedule-modal-time-slot ${
                    isSlotSelected(dayInfo, slot) ? "selected" : ""
                  }`}
                  onClick={() => handleSlotSelect(dayInfo, slot)}
                  disabled={isSlotPassed(dayInfo, slot)}
                >
                  {slot}
                </button>
              ))}
            </div>

            <div className="time-slot-group evening">
              {timeSlots.evening.map((slot) => (
                <button
                  key={slot}
                  className={`schedule-modal-time-slot ${
                    isSlotSelected(dayInfo, slot) ? "selected" : ""
                  }`}
                  onClick={() => handleSlotSelect(dayInfo, slot)}
                  disabled={isSlotPassed(dayInfo, slot)}
                >
                  {slot}
                </button>
              ))}
            </div>
          </div>
        ))}

        <button
          className="schedule-modal-save-button"
          onClick={handleSave}
          // disabled={!selectedDelivery.time}
        >
          {t("SAVE")}
        </button>
      </div>
    </div>
  );
};

export default ScheduleModal;
