import { ReactComponent as DismissCircle } from "../../../assets/svg/DismissCircle.svg";

const PreviewItem = ({ file, index, selectedFiles, setSelectedFiles, t }) => (
  <div className="imgPreview__inner">
    {file.type === "application/pdf" ? (
      <embed src={URL.createObjectURL(file)} type="application/pdf" />
    ) : (
      <img src={URL.createObjectURL(file)} alt="PreviewImg" />
    )}
    <button
      className="remove-image"
      onClick={() => {
        setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
      }}
    >
      <DismissCircle />
    </button>
  </div>
);

const FilePreviewList = ({ selectedFiles, setSelectedFiles, t }) => {
  if (selectedFiles.length === 0) return null;

  return (
    <div className="imgPreview">
      {selectedFiles.map((file, index) => (
        <PreviewItem
          key={index}
          file={file}
          index={index}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
      ))}
    </div>
  );
};

export default FilePreviewList;
