import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SoldProductService from "../services/SoldProductService";

// Thunks
export const createSoldProductThunk = createAsyncThunk(
  "soldProduct/createSoldProduct",
  async (sendData, thunkAPI) => {
    try {
      const response = await SoldProductService.createSoldProduct(sendData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateSoldProductThunk = createAsyncThunk(
  "soldProduct/updateSoldProduct",
  async ({ productId, userId, sellerId, sendData }, thunkAPI) => {
    try {
      const response =
        await SoldProductService.updateSoldProductByProductWithSellerWithUserId(
          productId,
          userId,
          sellerId,
          sendData
        );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getSoldProductByProductWithUserIdThunk = createAsyncThunk(
  "soldProduct/getSoldProductByProductWithUserId",
  async ({ productId, userId }, thunkAPI) => {
    try {
      const response =
        await SoldProductService.getSoldProductByProductWithUserId(
          productId,
          userId
        );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getSoldProductByUserIdThunk = createAsyncThunk(
  "soldProduct/getSoldProductByUserId",
  async (userId, thunkAPI) => {
    try {
      const response = await SoldProductService.getSoldProductByUserId(userId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getSoldProductByUserIdAndProductIdThunk = createAsyncThunk(
  "soldProduct/getSoldProductByUserIdAndProductId",
  async ({ productId, userId }, thunkAPI) => {
    try {
      const response =
        await SoldProductService.getSoldProductByUserIdAndProductId(
          productId,
          userId
        );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const soldProductSlice = createSlice({
  name: "soldProduct",
  initialState: {
    soldProductData: null,
    soldProductList: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetSoldProductState: (state) => {
      state.soldProductData = null;
      state.soldProductList = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSoldProductThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSoldProductThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.soldProductData = action.payload.data;
      })
      .addCase(createSoldProductThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to create sold product";
      })

      .addCase(updateSoldProductThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSoldProductThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.soldProductData = action.payload.data;
      })
      .addCase(updateSoldProductThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to update sold product";
      })

      .addCase(getSoldProductByProductWithUserIdThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getSoldProductByProductWithUserIdThunk.fulfilled,
        (state, action) => {
          state.loading = false;
          state.soldProductData = action.payload.data;
        }
      )
      .addCase(
        getSoldProductByProductWithUserIdThunk.rejected,
        (state, action) => {
          state.loading = false;
          state.error =
            action.payload ||
            "Failed to get sold product by product and user ID";
        }
      )

      .addCase(getSoldProductByUserIdThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSoldProductByUserIdThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.soldProductList = action.payload.data;
      })
      .addCase(getSoldProductByUserIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload || "Failed to get sold products by user ID";
      })

      .addCase(getSoldProductByUserIdAndProductIdThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getSoldProductByUserIdAndProductIdThunk.fulfilled,
        (state, action) => {
          state.loading = false;
          state.soldProductData = action.payload.data;
        }
      )
      .addCase(
        getSoldProductByUserIdAndProductIdThunk.rejected,
        (state, action) => {
          state.loading = false;
          state.error =
            action.payload ||
            "Failed to get sold product by user ID and product ID";
        }
      );
  },
});

export const { resetSoldProductState } = soldProductSlice.actions;
export default soldProductSlice.reducer;
