import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const SecondHandGoods = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="AboutMainContainer">
      <div className="container">
          <h2>{t("TRENDIES_BENEFIT")}</h2>
        <div>
          <ul>
            <li>
              <p>
                <strong>{t("TRENDIES_BENEFIT_1")}</strong>
                {t("TRENDIES_BENEFIT_1_DESC")}
              </p>
            </li>
            <li>
              <p>
                <strong>{t("TRENDIES_BENEFIT_2")}</strong>
                {t("TRENDIES_BENEFIT_2_DESC")}
              </p>
            </li>
            <li>
              <p>
                <strong>{t("TRENDIES_BENEFIT_3")}</strong>
                {t("TRENDIES_BENEFIT_3_DESC")}
              </p>
            </li>
            <li>
              <p>
                <strong>{t("TRENDIES_BENEFIT_4")}</strong>
                {t("TRENDIES_BENEFIT_4_DESC")}
              </p>
            </li>
            <li>
              <p>
                <strong>{t("TRENDIES_BENEFIT_5")}</strong>
                {t("TRENDIES_BENEFIT_5_DESC")}
              </p>
            </li>
            <li>
              <p>
                <strong>{t("TRENDIES_BENEFIT_6")}</strong>
                {t("TRENDIES_BENEFIT_6_DESC")}
              </p>
            </li>
          </ul>
        </div>
        <div>
          <p>{t("ABOUT_LAST")}</p>
        </div>
        <div>
          <h3>{t("Benefits.Title")}</h3>
          <p>{t("Benefits.Heading")}</p>
          <ul>
            <li>
              <h4>{t("Benefits.Advantage.Advantage_One")}</h4>
              <p>{t("Benefits.Advantage.Advantage_Content")}</p>
            </li>

            <li>
              <h4>{t("Benefits.Advantage.Advantage_Two")}</h4>
              <p>{t("Benefits.Advantage.Advantage_Two_Content")}</p>
            </li>

            <li>
              <h4>{t("Benefits.Advantage.Advantage_Three")}</h4>
              <p>{t("Benefits.Advantage.Advantage_Three_Content")}</p>
            </li>

            <li>
              <h4>{t("Benefits.Advantage.Advantage_Four")}</h4>
              <p>{t("Benefits.Advantage.Advantage_Four_Content")}</p>
            </li>

            <li>
              <h4>{t("Benefits.Advantage.Advantage_Five")}</h4>
              <p>{t("Benefits.Advantage.Advantage_Five_Content")}</p>
            </li>

            <li>
              <h4>{t("Benefits.Advantage.Advantage_Six")}</h4>
              <p>{t("Benefits.Advantage.Advantage_Six_Content")}</p>
            </li>
          </ul>
        </div>
        <p>{t("Benefits.Footer")}</p>
      </div>
    </main>
  );
};

export default SecondHandGoods;
