import { useNavigate } from "react-router-dom";
import { OurPartners } from "../../constraints/Footer";

import { BsTwitterX } from "react-icons/bs";
import { CiYoutube } from "react-icons/ci";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { SiLinkedin } from "react-icons/si";
import { ReactComponent as Trendies } from "../../assets/images/svg/Icons/MainLogo.svg";

// IMPORT  FOOTER SCSS
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./Footer.scss";

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__container">
          <div className="footer__brand" style={{cursor:"pointer"}}>
            <Trendies />
          </div>
          <Box className="footer__links">
            <Box className="footer__linksContainer">
              <div className="footer__column">
                <h4>{t("Information")}</h4>
                <ul>
                  <li onClick={() => navigate("/about")}>{t("AboutUs")}</li>
                  <li onClick={() => navigate("/goods")}>{t("Benefits_Title_About_Us")}</li>
                  <li onClick={() => navigate("/terms-conditions")}>{t("TERMS_AND_CONDITION")}</li>
                  {/* <li onClick={() => navigate("/blog")}>{t("Blog")}</li> */}
                  <li onClick={() => navigate("/faq")}>{t("FAQs")}</li>
                  <li onClick={() => navigate("/contact-us")}>
                    {t("ContactUs")}
                  </li>
                  {/* <li onClick={() => navigate("/journey")}>
                    Trendies {t("Stories")}
                  </li> */}
                </ul>
              </div>
              <div className="footer__column">
                <h4>{t("OurPartners")}</h4>
                <ul>
                  {OurPartners?.map((partner, index) => (
                    <li key={index}>
                      <a
                        href={partner.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-image-logo"
                        aria-label={partner.name}
                      >
                        <div className="footer-image">
                          <img
                            src={partner.src}
                            alt={partner.name}
                            style={{ width: "20px", height: "20px" }}
                          />
                          {partner.name}
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </Box>
          </Box>
          <div className="footer__column">
            <h3>{t("FollowUs")}</h3>
            <div className="social-links">
              <a
                href="https://www.facebook.com/profile.php?id=61572461244750"
                className="footer-image-logo"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook style={{ color: "white", fontSize: 20 }} />
              </a>
              <a
                href="https://www.youtube.com/@TrendiesMaroc"
                aria-label="Instagram"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-image-logo"
              >
                <CiYoutube style={{ color: "white", fontSize: 20 }} />
              </a>
              <a
                href="https://x.com/TrendiesMaroc"
                aria-label="Instagram"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-image-logo"
              >
                <BsTwitterX style={{ color: "white", fontSize: 20 }} />
              </a>
              <a
                href="https://www.linkedin.com/company/trendiesmaroc/"
                aria-label="linkedin"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-image-logo"
              >
                <SiLinkedin style={{ color: "white", fontSize: 20 }} />
              </a>
              <a
                href="https://www.instagram.com/trendies_mar?igsh=MXNpNzU4amJzdTVidg%3D%3D&utm_source=qr"
                aria-label="Instagram"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-image-logo"
              >
                <FaInstagram style={{ color: "white", fontSize: 20 }} />
              </a>
            </div>
          </div>
        </div>
        <div className="footer__bottom">
          <p>
            All copy rights reserved to © {new Date().getFullYear()} Trendies
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
