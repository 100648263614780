import React from "react";
import { useTranslation } from "react-i18next";
import "./CreditDebitModal.scss";

const CreditDebitModal = ({ isOpen, onClose, openModal , updatePaymentStatus}) => {
  const { t } = useTranslation();
  if (!isOpen) return null;

  const handleCreditPayment = () => {
    openModal();
    updatePaymentStatus()
  };

  return (
    <div className="modal-overlay CreditDebitModalWrapper">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <div className="payment-header">{t("PAYMENT")}</div>
        <div className="card-display">
          <div className="card-logo">VISA</div>
          <div className="card-number">•••• •••• •••• 3456</div>
          <div className="card-info">
            <span className="card-holder">KELLY OLIVER</span>
            <span className="card-expiry">Expires 12/23</span>
          </div>
        </div>
        <form className="payment-form">
          <label>
            <div>{t("DEBIT_CARD_NUMBER")}</div>

            <input type="text" placeholder="XXXX XXXX XXXX 8014" disabled />
          </label>
          <label>
            {t("CARD_HOLDER_NAME")}
            <input type="text" placeholder="KELLY OLIVER" disabled />
          </label>
          <label>
            {t("EXPIRY")}
            <input type="text" placeholder="08/21" disabled />
          </label>
          <label>
            CVV
            <input type="password" placeholder="XXX" />
          </label>

          <div className="total-amount">TOTAL: MAD 999</div>
          <button
            onClick={() => handleCreditPayment()}
            type="button"
            className="pay-button"
          >
            {t("PROCEED_TO_PAY")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreditDebitModal;
