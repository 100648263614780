import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import NotificationService from "../services/NotificationService";

// ASYNC THUNKS

// CREATE NOTIFICATION ASYNC THUNK
export const addNotification = createAsyncThunk(
  "notification/addNotification",
  async (notificationData, thunkAPI) => {
    try {
      return await NotificationService.createNotification(notificationData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// GET ALL NOTIFICATION BY USER ID ASYNC THUNK
export const fetchAllNotifications = createAsyncThunk(
  "notification/fetchAllNotifications",
  async (userId, thunkAPI) => {
    try {
      return await NotificationService.getAllNotificationsByUserId(userId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// DELETE NOTIFICATION BY ID  ASYNC THUNK
export const removeNotification = createAsyncThunk(
  "notification/removeNotification",
  async (notificationId, thunkAPI) => {
    try {
      return await NotificationService.deleteNotificationById(notificationId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// UPDATE NOTIFICATION BY ID ASYNC THUNK
export const updateNotification = createAsyncThunk(
  "notification/updateNotification",
  async ({ notificationId, notificationData }, thunkAPI) => {
    try {
      return await NotificationService.updateNotificationById(
        notificationId,
        notificationData
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// MARK NOTIFICATION READ ASYNC THUNK
export const markNotificationRead = createAsyncThunk(
  "notification/markNotificationRead",
  async (notificationId, thunkAPI) => {
    try {
      return await NotificationService.markNotificationAsRead(notificationId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// SLICE
const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    notifications: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetNotificationState: (state) => {
      state.notifications = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.notifications.push(action.payload.data);
      })
      .addCase(addNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchAllNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.notifications = action.payload.data;
      })
      .addCase(fetchAllNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(removeNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.notifications = state.notifications.filter(
          (notification) => notification.id !== action.meta.arg
        );
      })
      .addCase(removeNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(updateNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateNotification.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.notifications.findIndex(
          (notification) => notification.id === action.meta.arg.notificationId
        );
        if (index !== -1) {
          state.notifications[index] = {
            ...state.notifications[index],
            ...action.payload.data,
          };
        }
      })
      .addCase(updateNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(markNotificationRead.pending, (state) => {
        state.loading = true;
      })
      .addCase(markNotificationRead.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.notifications.findIndex(
          (notification) => notification.id === action.meta.arg
        );
        if (index !== -1) {
          state.notifications[index].isRead = true;
        }
      })
      .addCase(markNotificationRead.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// EXPORT ACTIONS
export const { resetNotificationState } = notificationSlice.actions;

// EXPORT REDUCER
export default notificationSlice.reducer;
