import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import OfferService from "../services/OfferService";

// ASYNC THUNK

// OFFER ASYNC THUNK
export const offerThunk = createAsyncThunk(
  "offer/offerThunk",
  async ({ endpoint, token }, thunkAPI) => {
    try {
      const response = await OfferService.offerAccept(endpoint, token);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteOffersWithoutChatByUserId = createAsyncThunk(
  "offer/deleteOffersWithoutChatByUserId",
  async (userId, thunkAPI) => {
    try {
      return await OfferService.deleteOffersWithoutChat(userId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);



// SLICE
const offerSlice = createSlice({
  name: "offer",
  initialState: {
    offerDelete: null,
    offerThunk: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetOfferState: (state) => {
      state.offerThunk = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(offerThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(offerThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.offerThunk = action?.payload;
      })
      .addCase(offerThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Offer rejection failed";
      })
      .addCase(deleteOffersWithoutChatByUserId.pending, (state) => {
        state.isLoading = true;
        state.successMessage = null;
        state.errorMessage = null;
      })
      .addCase(deleteOffersWithoutChatByUserId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.successMessage = "Offer deleted successfully.";
      })
      .addCase(deleteOffersWithoutChatByUserId.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.payload || "Failed to delete the offer.";
      });
  },
});

// EXPORT ACTIONS
export const { resetOfferState } = offerSlice.actions;
// EXPORT REDUCER
export default offerSlice.reducer;
