import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

// AUTH HEADER FUNCTION
export function authHeader() {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
}

// SELLER DECRYPT REQUEST
async function decryptSellerData(userId) {
  try {
    const { data } = await axios.get(`${baseURL}/decrypt/${userId}`, {
      headers: authHeader(),
    });
    if (!data.status) {
      throw new Error(data.message || "Failed to decrypt seller data");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
        e.response?.data?.Error ||
        e.message ||
        "An error occurred while decrypting seller data"
    );
  }
}

// CREATE SELLER
async function createSeller(formData) {
  try {
    const { data } = await axios.post(`${baseURL}/seller`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    });

    if (!data.status) {
      throw new Error(data.message || "Failed to create seller");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
        e.response?.data?.Error ||
        e.message ||
        "An error occurred while creating seller"
    );
  }
}

// EXPORT SELLER SERVICE
const SellerService = {
  decryptSellerData,
  createSeller,
};

export default SellerService;
