import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./FAQ.scss";

const FAQData = [
  {
    questionKey: "faq.q1.question",
    answerKey: ["faq.q1.answer.ans1", "faq.q1.answer.ans2", "faq.q1.answer.ans3"],
  },
  // {
  //   questionKey: "faq.q2.question",
  //   answerKey: ["faq.q2.answer.ans1", "faq.q2.answer.ans2", "faq.q2.answer.ans3", "faq.q2.answer.ans4"],
  // },
  {
    questionKey: "faq.q2.question",
    answerKey: ["SELLER_STEP_1", "SELLER_STEP_2", "SELLER_STEP_3", "SELLER_STEP_4", "SELLER_STEP_5a", "SELLER_STEP_5b", "SELLER_STEP_6", "SELLER_STEP_7", "SELLER_STEP_8", "SELLER_STEP_9", "SELLER_STEP_10",]
  },
  {
    questionKey: "faq.q3.question",
    answerKey: ["faq.q3.answer.ans1", "faq.q3.answer.ans2", "faq.q3.answer.ans3", "faq.q3.answer.ans4", "faq.q3.answer.ans5"],
  }, {
    questionKey: "faq.q4.question",
    answerKey: ["faq.q4.answer.ans1"],
  },
  {
    questionKey: "faq.q5.question",
    answerKey: ["faq.q5.answer.ans1"],
  },
  {
    questionKey: "faq.q6.question",
    answerKey: ["faq.q6.answer.ans1"],
  },
  {
    questionKey: "faq.q7.question",
    answerKey: ["faq.q7.answer.ans1"],
  },
  {
    questionKey: "faq.q8.question",
    answerKey: ["faq.q8.answer.ans1", "Also_Accept"],
  },
  {
    questionKey: "faq.q9.question",
    answerKey: ["faq.q9.answer.ans1", "faq.q9.answer.ans2", "faq.q9.answer.ans3"],
  },
  {
    questionKey: "faq.q10.question",
    answerKey: ["faq.q10.answer.ans1"],
  },
  {
    questionKey: "faq.q11.question",
    answerKey: ["BUYER_STEP_1", "BUYER_STEP_2","BUYER_STEP_3","BUYER_STEP_4","BUYER_STEP_5","BUYER_STEP_6","BUYER_STEP_7",]
  },
];

const Faq = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="FaqMainWrapper">
      <div className="container">
        <div className="faq_main_container">
          <Typography variant="h2" textAlign="center">
            {t("faq.title")}
          </Typography>
          {FAQData.map((faq, index) => (
            <div className="faq_container" key={index}>
              <div
                className={`faq_question ${activeIndex === index ? "active" : ""}`}
                onClick={() => toggleFAQ(index)}
              >
                <div className="faq_question-text">
                  <h3>{t(faq.questionKey)}</h3>
                </div>
                <div className="icon">
                  <div
                    className={`icon-shape ${activeIndex === index ? "active" : ""}`}
                  ></div>
                </div>
              </div>
              <div
                className="answercont"
                style={{
                  maxHeight: activeIndex === index ? "400px" : "0",
                }}
              >
                <div className="answer">
                  <ul>
                    {Array.isArray(faq.answerKey) ? (
                      faq.answerKey.map((key, idx) => (
                        <li key={idx}>{t(key)}</li>
                      ))
                    ) : (
                      <p>{t(faq.answerKey)}</p>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </main>
  );
};

export default Faq;