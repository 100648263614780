import { Box, Button } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { BsChatLeftDots } from "react-icons/bs";
import { FaHandHoldingUsd } from "react-icons/fa";
import { TbCreditCardPay } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import icons from "../../../assets/images/svg/icons";
import verified from "../../../assets/images/verified.png";
import BidModal from "../../../component/Modals/BiddingModal/BidModal";
import { fetchProductById } from "../../../redux/slices/productSlice";
import { incrementCartCount } from "../../../store/actions/cartAction";
import ChatWindow from "../../../utils/ChatWindow/ChatWindow";

import { LiaCartPlusSolid, LiaShoppingCartSolid } from "react-icons/lia";

import { addCartItem } from "../../../redux/slices/cartSlice";
import { removeFavoriteByProductId } from "../../../redux/slices/favoriteSlice";
import { fetchFavoriteProduct } from "../../../redux/slices/userSlice";
import "./ProductDetails.scss";

const ProductDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const { loading, productDetails } = useSelector((store) => store?.product);

  const userData = JSON.parse(localStorage.getItem("userData"));
  const userId = userData?.id;

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bidDetails, setBidDetails] = useState({});
  const [quantity] = useState(1);
  const [cartAddedSuccess, setCartAddSuccess] = useState(false);
  // const [mainImage, setMainImage] = useState();
  const [rating, setRating] = useState(0);
  const [alreadyCartItem, setAlreadyCartItem] = useState([]);
  // const [isProductFavorite, setIsProductFavorite] = useState(false);
  const [isFavoriteLoading, setIsFavoriteLoading] = useState(false);
  // const [initiateChatLoading, setInitiateChatLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [offerCount, setOfferCount] = useState([]);
  const [userFavProductList, setUserFavProductList] = useState([]);
  const [bidLoading, setBidLoading] = useState(false);

  useEffect(() => {
    setBidDetails({
      productId: productDetails?.id,
      sellerId: productDetails?.userId,
      productPrice: Number(productDetails?.price),
      offerAmount: "",
      receiverId: id,
      offerCount: 0,
      offerLimit: 10,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetails]);

  useEffect(() => {
    if (productDetails?.productImage?.length > 0) {
      setSelectedImage(productDetails.productImage[0]);
    } else {
      setSelectedImage("");
    }
  }, [productDetails]);

  useEffect(() => {
    setRating((Math.random() * 5).toFixed(1));
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(fetchProductById(id));
      fetchProductsInCart();
    }
    if (userId && id) {
      getOfferCount(userId, id);
      getFavoriteProductStatus(userId, id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, id]);

  useEffect(() => {
    if (userId && id) {
      fetchFavoriteProducts();
      getProductOfferCount(userId, id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getOfferCount = async (receiverId, productId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/offer-count`,
        {
          params: { productId, receiverId },
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data.offerCount;
    } catch (error) {
      console.error("Fetching offer count failed:", error.response.data);
      throw error;
    }
  };

  const fetchProductsInCart = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-cart`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.status === 200) {
        setAlreadyCartItem(response.data.data);
      }
    } catch (error) {
      console.log("Error fetching cart products:", error);
    }
  };

  const handleToggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const handleBidSubmit = (bidAmount) => {
    const createBidData = {
      ...bidDetails,
      offerAmount: bidAmount,
      receiverId: userId,
    };

    if (bidDetails?.sellerId) {
      submitBid(createBidData);
    } else {
      toast.error(t("ERROR_IN_CREATING_OFFER"));
    }
  };

  const submitBid = async (createBidData) => {
    setBidLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/offer`,
        createBidData
      );
      if (response?.data?.status) {
        if (userId && id) {
          getProductOfferCount(userId, id);
        }
        toast.success(t("OFFER_CREATED"));
      }
    } catch (error) {
      toast.error(t(error?.response?.data?.data?.message));
    } finally {
      setIsModalOpen(false);
      setBidLoading(false);
    }
  };

  const handleAddToCart = async (productId, text) => {
    if (!userId) {
      navigate("/login");
      return;
    }

    if (text === "Go to cart" || text === "Aller au panier") {
      navigate(`/cart`);
    } else {
      const formData = new FormData();
      formData.append("productId", productId || 0);
      formData.append("quantity", quantity || 1);

      try {
        const response = await dispatch(addCartItem(formData));
        if (response?.payload.statusCode === 200) {
          setCartAddSuccess(() => true);
          dispatch(incrementCartCount(quantity));
        }
      } catch (error) {
        console.log("Error adding to cart:", error);
      }
    }
  };
  const handleImageClick = (image) => {
    // setMainImage(image);
    setSelectedImage(image);
  };

  const toggleFavorite = async (productId) => {
    if (!userId) {
      navigate("/login");
      return;
    }

    try {
      setIsFavoriteLoading(true);
      const response = await dispatch(removeFavoriteByProductId(productId));

      if (response?.payload?.statusCode === 200) {
        toast.success(t("PRODUCT_ADDED_TO_FAVORITES"));
        // eslint-disable-next-line no-unused-vars
        const status = await getFavoriteProductStatus(userData?.id, id);
        await fetchFavoriteProducts();
      }
    } catch (error) {
      toast.error(t("ERROR_IN_TOGGING_FAVORITE"));
      console.log("Error toggling favorite:", error);
    } finally {
      setIsFavoriteLoading(false);
    }
  };

  const fetchFavoriteProducts = async () => {
    if (!userData?.id) return;
    try {
      const response = await dispatch(fetchFavoriteProduct(userData?.id));
      if (response?.payload?.data) {
        const data = response.payload?.data;
        setUserFavProductList(data);
      }
    } catch (error) {
      console.log("Error fetching favorite products:", error);
    }
  };

  const getFavoriteProductStatus = async (userId, productId) => {
    if (!userId) {
      navigate("/login");
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products/userFavorites/${userId}/${productId}`
      );
      // setIsProductFavorite(response?.data?.data);
      return response?.data?.data;
    } catch (error) {
      toast.error(t("ERROR_IN_TOGGING_FAVORITE"));
      console.log("Error fetching favorite status:", error);
    }
  };

  const getProductOfferCount = async (userId, productId) => {
    if (!userId) {
      navigate("/login");
      return;
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/offers/user/${userId}/${productId}`
      );
      console.log("response", response);
      setOfferCount(response?.data?.data);
    } catch (error) {
      console.log("Error fetching offer status:", error);
    }
  };

  const initiateChat = async () => {
    const sendData = {
      receiverId: userId,
      sellerId: productDetails?.userId,
      productId: productDetails?.id,
      productPrice: productDetails?.price,
      offerAmount: 0,
      offerCount: 0,
      status: true,
    };

    const urlParams = new URLSearchParams({
      receiverId: sendData.receiverId,
      sellerId: sendData.sellerId,
      productId: sendData.productId,
    });

    try {
      // setInitiateChatLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/initiate-chat`,
        sendData
      );

      if (response?.data?.status) {
        toast.success(t(response?.data?.message), {
          onClose: () => navigate(`/chat?${urlParams.toString()}`),
          autoClose: 800,
        });
      }
    } catch (error) {
      if (error?.response.data.message === "CHAT_ALREADY_EXISTS") {
        toast.error(t(error?.response?.data?.message), {
          onClose: () => navigate(`/chat?${urlParams.toString()}`),
          autoClose: 800,
        });
      }
    } finally {
      console.log("Final");
      // setInitiateChatLoading(false);
    }
  };

  const { HeartFill, Heart } = icons;

  const handleCartButtonClick = (e) => {
    const isAlreadyInCart =
      Array.isArray(alreadyCartItem) &&
      alreadyCartItem.some(
        (cartItem) =>
          cartItem?.userId === userId &&
          cartItem?.productId === productDetails?.id
      );

    if (cartAddedSuccess || isAlreadyInCart) {
      navigate("/cart");
    } else {
      handleAddToCart(productDetails.id, e.target.textContent);
      toast.success(t("ADD_TO_CART_SUCCESS"));
    }
  };

  console.log("offerCount", offerCount);

  return (
    <>
      {loading ? (
        <div className="noDataBlock">
          <div className="loader-wrapper">
            <div className="loader"></div>
            <p>Loading...</p>
          </div>
        </div>
      ) : (
        <>
          <div className="ProductMainWrapper">
            <div className="container">
              <Box>
                <div className="productDetail">
                  <div className="productDetail__inner">
                    <div className="productDetail__img">
                      <div
                        className="productImg"
                        style={{ position: "relative" }}
                      >
                        {selectedImage && (
                          <img src={selectedImage} alt="Product" />
                        )}
                        {userId !== productDetails?.userId &&
                          !productDetails?.isSold && (
                            <div
                              className="productItem__img-btn"
                              onClick={() => toggleFavorite(productDetails?.id)}
                            >
                              {userFavProductList.length > 0 &&
                              userFavProductList?.some(
                                (item) => item.productId === productDetails?.id
                              ) ? (
                                <HeartFill className="icon" />
                              ) : (
                                <Heart className="icon" />
                              )}
                            </div>
                          )}
                        {productDetails?.isSold && (
                          <span class="payment-status pending">Sold Out</span>
                        )}
                      </div>

                      <div className="productImgSmall">
                        {productDetails?.productImage?.map((image, index) => (
                          <img
                            key={index}
                            src={image}
                            alt={`Product_image ${index + 1}`}
                            onClick={() => handleImageClick(image)}
                            className="product-thumbnail"
                          />
                        ))}
                      </div>
                    </div>

                    <div className="product-info-section">
                      <h2 className="product-title">{productDetails?.title}</h2>
                      <div className="product-info-list">
                        <p className="product-category-brand">
                          <span>{t("CATEGORY")}:</span>{" "}
                          {productDetails?.category}
                        </p>
                        <p className="product-category-brand">
                          <span>{t("BRAND")}:</span>
                          {productDetails?.brand}
                        </p>
                        <p className="product-category-brand">
                          <span>{t("SELLER")}:</span>
                          {productDetails?.user?.username ||
                            productDetails?.user?.nickname}{" "}
                          <span>
                            <img width={"20px"} src={verified} alt="verified" />
                          </span>
                        </p>
                        <div className="product_rating">
                          <div className="stars">
                            {[...Array(5)].map((_, index) => (
                              <span
                                key={index}
                                className="star"
                                style={{
                                  color: index < rating ? "#FFD700" : "#ccc",
                                }}
                              >
                                ★
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="product-price-section">
                        <p className="product-price">
                          {productDetails?.price} MAD{" "}
                        </p>
                      </div>
                      <div className="product-description">
                        <h4>{t("DESCRIPTION")}</h4>
                        <p>{productDetails?.description}</p>
                      </div>

                      {userData?.id !== productDetails?.userId &&
                        productDetails?.isSold !== true && (
                          <div className="btnPart">
                            <Button
                              className="btn_dark add-to-cart-btn"
                              disabled={productDetails.isSold}
                              onClick={(e) => {
                                handleCartButtonClick(e);
                              }}
                            >
                              {cartAddedSuccess ||
                              (Array.isArray(alreadyCartItem) &&
                                alreadyCartItem.some(
                                  (cartItem) =>
                                    cartItem?.userId === userId &&
                                    cartItem?.productId === productDetails?.id
                                )) ? (
                                <>
                                  <LiaShoppingCartSolid fontSize={"24px"} />
                                  {t("GO_TO_CART")}
                                </>
                              ) : (
                                <>
                                  <LiaCartPlusSolid fontSize={"24px"} />
                                  {t("ADD_TO_CART")}
                                </>
                              )}
                            </Button>
                            <Button
                              onClick={() =>
                                userId
                                  ? navigate(`/payment/${id}`)
                                  : navigate("/login")
                              }
                              className="btn btn_dark buy-now-btn"
                              disabled={productDetails.isSold}
                            >
                              <TbCreditCardPay fontSize={"24px"} />
                              {t("BUY_NOW")}
                            </Button>
                            <Button
                              onClick={() => {
                                if (offerCount?.length >= 3) {
                                  toast.success("ALREADY_MADE_THREE_OFFER");
                                }
                                userId
                                  ? setIsModalOpen(true)
                                  : navigate("/login");
                              }}
                              className="btn_dark add-to-cart-btn"
                              disabled={
                                productDetails.isSold ||
                                offerCount?.length === 3
                              }
                            >
                              <FaHandHoldingUsd fontSize={"24px"} />{" "}
                              {t("MAKE_AN_OFFER")}
                            </Button>
                            <Button
                              onClick={() =>
                                userId ? initiateChat() : navigate("/login")
                              }
                              className="btn_dark add-to-cart-btn"
                              disabled={productDetails.isSold}
                            >
                              <BsChatLeftDots fontSize={"24px"} /> {t("CHAT")}
                            </Button>
                            <p></p>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                {isChatOpen && <ChatWindow onClose={handleToggleChat} />}
                <BidModal
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  currentPrice={productDetails?.price}
                  onSubmitBid={handleBidSubmit}
                  bidLoading={bidLoading}
                  // setBidDetails={setBidDetails}
                  // bidDetails={bidDetails}
                  loading={loading}
                />
              </Box>
              {isFavoriteLoading && (
                <div className="loader-wrapper">
                  <div className="loader"></div>
                  <p>Loading...</p>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProductDetails;
