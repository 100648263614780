import { Box, Typography } from "@mui/material";
import { ReactComponent as Cross } from "../../../assets/svg/cross.svg";

const ChatHeaderInfo = ({ productDetails }) => (
  <div className="chat-body-header__info">
    <Typography variant="h6" className="chat-body-header__title">
      {productDetails?.title}
    </Typography>
    <Typography variant="body1" className="chat-body-header__text">
      {productDetails?.description}
    </Typography>
  </div>
);

const ChatInnerHeader = ({
  currentProductSellerBuyerDetails,
  handleGoBack,
  handleOpenModal,
}) => {
  if (!currentProductSellerBuyerDetails) return null;


  return (
    <div className="ChatInnerHeader">
      <div className="chat-body-header main">
        <Box className="ChatBodyUserInfo" onClick={handleOpenModal}>
          <div className="userItem__product">
            <h5 className="userItem__sold">
              {currentProductSellerBuyerDetails?.product?.isSold ? "Sold" : null}
            </h5>
            <img
              className="chat-product-img"
              src={currentProductSellerBuyerDetails?.product?.productImage[0]}
              alt="Product"
            />
          </div>
          <ChatHeaderInfo
            productDetails={currentProductSellerBuyerDetails?.product}
          />
        </Box>
        <Box>
          <span className="closeBtn" onClick={handleGoBack}>
            <Cross />
          </span>
        </Box>
      </div>
    </div>
  );
};

export default ChatInnerHeader;
