import icons from "../assets/images/svg/icons";
import { useTranslation } from "react-i18next";

const {
  FemaleIcon,
  MaleIcon,
  ChildIcon,
  BagIcon,
  ShoesIcon,
  AccessoriesIcon,
  JewelleryIcon,
  WatchIcon,
  ProductBox,
} = icons;

export const useCategories = () => {
  const { t } = useTranslation();

  const mainCategory = [
    { name: t("MAIN_CATEGORY.ALL"), gender: "", icon: <ProductBox /> },
    { name: t("MAIN_CATEGORY.WOMEN"), gender: "female", icon: <FemaleIcon /> },
    { name: t("MAIN_CATEGORY.MEN"), gender: "male", icon: <MaleIcon /> },
    { name: t("MAIN_CATEGORY.KID"), gender: "kids", icon: <ChildIcon /> },
  ];

  const subCategory = [
    { name: t("SUB_CATEGORY.ALL"), category: "", icon: <ProductBox /> },
    {
      name: t("SUB_CATEGORY.WATCHES"),
      category: "watches",
      icon: <WatchIcon />,
    },
    {
      name: t("SUB_CATEGORY.JEWELRY"),
      category: "jewelry",
      icon: <JewelleryIcon />,
    },
    { name: t("SUB_CATEGORY.BAGS"), category: "bags", icon: <BagIcon /> },
    { name: t("SUB_CATEGORY.SHOES"), category: "shoes", icon: <ShoesIcon /> },
    {
      name: t("SUB_CATEGORY.ACCESSORIES"),
      category: "accessories",
      icon: <AccessoriesIcon />,
    },
  ];

  return { mainCategory, subCategory };
};

export const CapitalizedText = ({ text }) => {
  const capitalizeWords = (str) =>
    str.replace(/\b\w/g, (char) => char.toUpperCase());

  return <>{capitalizeWords(text)}</>;
};