import * as Yup from "yup";

export const loginValidationSchema = (t) =>
  Yup.object().shape({
    password: Yup.string().required(t("PASSWORD_REQUIRED")),
    // .test("password-validation", (value, context) => {
    //   const criteria = [];

    //   if (!/[a-z]/.test(value || "")) criteria.push(t("PASSWORD_LOWERCASE"));
    //   if (!/[0-9]/.test(value || "")) criteria.push(t("PASSWORD_NUMBER"));
    //   if (!/[!@#$%^&*(),.?":{}|<>]/.test(value || "")) criteria.push(t("PASSWORD_SPECIAL"));
    //   if ((value || "").length < 6) criteria.push(t("PASSWORD_MIN_LENGTH"));

    //   // If criteria is not met, return error message; otherwise, validation passes.
    //   return criteria.length > 0
    //     ? context.createError({ message: `${t("PASSWORD_RECOMMENDATIONS")}: ${criteria.join(", ")}` })
    //     : true;
    // }),
    nickname: Yup.string()
      .required(t("NICKNAME_REQUIRED"))
      .test(
        "no-empty-space",
        t("NICKNAME_NO_SPACES"),
        (value) => value && value.trim().length > 0
      ),
  });

export const SignupSchema = (t) =>
  Yup.object().shape({
    username: Yup.string()
      .test("no-empty-space", t("USER_NAME_CANNOT_BE_EMPTY_SPACE"), (value) =>
        value ? value.trim().length > 0 : false
      )
      .min(3, t("USER_NAME_ATLEATST_3_CHAR"))
      .max(20, t("USER_NAME_NOT_MORE_THAN_20_CHAR"))
      .required(t("USER_NAME_REQUIRED")),

    nickname: Yup.string()
      .test("no-empty-space", t("NICK_NAME_CANNOT_BE_EMPTY_SPACE"), (value) =>
        value ? value.trim().length > 0 : false
      )
      .required(t("NICK_NAME_REQUIRED")),

    email: Yup.string()
      .email(t("INVAILD_EMAIL_FORMAT"))
      .required(t("EMAIL_REQUIRED")),

    password: Yup.string()
      .required(t("PASSWORD_REQUIRED"))
      .test("password-validation", (value, context) => {
        const criteria = [];

        if (!/[a-z]/.test(value || "")) criteria.push(t("PASSWORD_LOWERCASE"));
        if (!/[0-9]/.test(value || "")) criteria.push(t("PASSWORD_NUMBER"));
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(value || ""))
          criteria.push(t("PASSWORD_SPECIAL"));
        if ((value || "").length < 6) criteria.push(t("PASSWORD_MIN_LENGTH"));

        // If criteria is not met, return error message; otherwise, validation passes.
        return criteria.length > 0
          ? context.createError({
            message: `${t("PASSWORD_RECOMMENDATIONS")}: ${criteria.join(
              ", "
            )}`,
          })
          : true;
      }),
  });

export const SellerProfileValidationSchema = (t, country) =>
  Yup.object().shape({
    fullName: Yup.string()
      .test("no-empty-space", t("USER_NAME_CANNOT_BE_EMPTY_SPACE"), (value) =>
        value ? value.trim().length > 0 : false
      )
      .required(t("FULL_NAME_REQUIRED")),

    emailAddress: Yup.string()
      .required(t("EMAIL_REQUIRED"))
      .lowercase() // Forces lowercase
      .matches(
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
        t("INVALID_EMAIL_FORMAT")
      )
      .test(
        "no-double-at",
        t("EMAIL_CANNOT_CONTAIN_MULTIPLE_@"),
        (value) => (value?.match(/@/g) || []).length <= 1
      )
      .test(
        "no-consecutive-dots",
        t("EMAIL_CANNOT_HAVE_CONSECUTIVE_DOTS"),
        (value) => !value?.includes("..")
      )
      .max(254, t("EMAIL_TOO_LONG")),

    phoneNumber: Yup.string()
      .matches(/^[0-9]{9,10}$/, t("INVALID_PHONE_NUMBER")) // Ensure only digits and length is 9 or 10
      .required(t("PHONE_NUMBER_REQUIRED")),

    // phoneNumber: Yup.string()
    //   .test('valid-phone', t("INVALID_PHONE_NUMBER"), function (value) {
    //     const { country } = this.parent;
    //     if (country === 'IN') {
    //       return /^[789]\d{9}$/.test(value); // Valid Indian phone number
    //     }
    //     if (country === 'FR') {
    //       return /^(?:\+33|0)[1-9](?:\d{2}){4}$/.test(value); // Valid French phone number
    //     }
    //     return false;
    //   })
    //   .required(t("PHONE_NUMBER_REQUIRED")),

    password: Yup.string()
      .required(t("PASSWORD_REQUIRED"))
      .test("password-validation", (value, context) => {
        const criteria = [];

        if (!/[a-z]/.test(value || "")) criteria.push(t("PASSWORD_LOWERCASE"));
        if (!/[0-9]/.test(value || "")) criteria.push(t("PASSWORD_NUMBER"));
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(value || ""))
          criteria.push(t("PASSWORD_SPECIAL"));
        if ((value || "").length < 6) criteria.push(t("PASSWORD_MIN_LENGTH"));

        // If criteria is not met, return error message; otherwise, validation passes.
        return criteria.length > 0
          ? context.createError({
            message: `${t("PASSWORD_RECOMMENDATIONS")}: ${criteria.join(
              ", "
            )}`,
          })
          : true;
      }),

    // governmentId: Yup.mixed().required(t("GOVERNMENT_ID_REQUIRED")),

    dateOfBirth: Yup.date()
      .required(t("BIRTH_DATE_REQUIRED"))
      .max(new Date(), t("BIRTH_DATE_PAST"))
      .test(
        "min-age",
        t("BIRTH_DATE_TOO_YOUNG"),
        (value) => {
          const calculateMinDate = () => {
            const minAge = 18;
            const currentDate = new Date();
            currentDate.setFullYear(currentDate.getFullYear() - minAge);
            return currentDate;
          };

          const minDate = calculateMinDate();
          return value <= minDate;
        }
      ),

    residentialAddress: Yup.string()
      .test("no-empty-space", t("ADDRESS_CANNOT_BE_EMPTY_SPACE"), (value) =>
        value ? value.trim().length > 0 : false
      )
      .required(t("RESIDENTIAL_ADDRESS_REQUIRED")),

    // sellerType: Yup.string().required(t("SELLER_TYPE_REQUIRED")),
    sellerType: Yup.string()
      .oneOf(["Individual", "Business"], t("SELLER_TYPE_INVALID"))
      .required(t("SELLER_TYPE_REQUIRED")),

    // accountHolderName: Yup.string().required(t("ACCOUNT_HOLDER_NAME_REQUIRED")),

    // bankName: Yup.string().required(t("BANK_NAME_REQUIRED")),

    // IBAN: Yup.string()
    //   // .matches(/^([A-Z]{2}[0-9]{2})(?=\w{11,34}$)/, t("IBAN_INVALID"))
    //   .required(t("IBAN_REQUIRED")),

    // taxIdentificationNumber: Yup.string().when("sellerType", {
    //   is: "Business",
    //   then: Yup.string().required(t("TAX_ID_REQUIRED")),
    //   otherwise: Yup.string().notRequired(),
    // }),

    // taxIdentificationNumber: Yup.string().required(t("TAX_ID_REQUIRED")),

    // companyName: Yup.string().required(t("COMPANY_NAME_REQUIRED")),

    // websiteOrSocialMedia: Yup.string().url(t("WEBSITE_SOCIAL_MEDIA")),

    taxIdentificationNumber: Yup.string().when(
      "sellerType",
      (sellerType, schema) =>
        sellerType === "Business"
          ? schema.required(t("TAX_ID_REQUIRED"))
          : schema.notRequired()
    ),

    companyName: Yup.string().when("sellerType", (sellerType, schema) =>
      sellerType === "Business"
        ? schema.required(t("COMPANY_NAME_REQUIRED"))
        : schema.notRequired()
    ),

    websiteOrSocialMedia: Yup.string().when(
      "sellerType",
      (sellerType, schema) =>
        sellerType === "Business"
          ? schema.url(t("WEBSITE_SOCIAL_MEDIA"))
          : schema.notRequired()
    ),

    productCategories: Yup.array().min(1, t("PRODUCT_CATEGORY_REQUIRED")),

    // termsAccepted: Yup.boolean().oneOf([true], t("TERMS_ACCEPTANCE_REQUIRED")),

    // privacyPolicyAccepted: Yup.boolean().oneOf(
    //   [true],
    //   t("PRIVACY_POLICY_ACCEPTANCE_REQUIRED")
    // ),

    // previousSalesExperience: Yup.boolean().oneOf(
    //   [true],
    //   t("PREVIOUS_SALES_EXPERIENCE_REQUIRED")
    // ),

    authenticationProcess: Yup.string().required(
      t("AUTHENTICATION_PROCESS_REQUIRED")
    ),

    // profilePhoto: Yup.mixed().notRequired(),
  });
