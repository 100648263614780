import { t } from "i18next";
import React, { useEffect, useState } from "react";
import {
  FaArrowDown,
  FaArrowRight,
  FaCalendarAlt,
  FaCamera,
  FaCheckCircle,
  FaClipboardList,
  FaCreditCard,
  FaEnvelope,
  FaGavel,
  FaHandshake,
  FaIdCard,
  FaMoneyCheck
} from "react-icons/fa";
import "./SellerFlow.scss";

const SellerFlow = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
    } else {
      document.body.style.overflow = "unset";
      document.body.style.position = "static";
      document.body.style.width = "auto";
    }

    return () => {
      document.body.style.overflow = "unset";
      document.body.style.position = "static";
      document.body.style.width = "auto";
    };
  }, [showModal]);

  const steps = [
    {
      icon: <FaClipboardList size={35} />,
      label: t("SELLER_STEP_1"),
    },
    {
      icon: <FaGavel size={35} />,
      label: t("SELLER_STEP_2"),
    },
    {
      icon: <FaIdCard size={35} />,
      label: t("SELLER_STEP_3"),
    },
    {
      icon: <FaCreditCard size={35} />,
      label: t("SELLER_STEP_4"),
    },
    {
      icon: <FaCalendarAlt size={35} />,
      label: t("SELLER_STEP_5a"),
    },
    {
      icon: <FaCamera size={35} />,
      label: t("SELLER_STEP_5b"),
    },
    {
      icon: <FaEnvelope size={35} />,
      label: t("SELLER_STEP_6"),
    },
    {
      icon: <FaCheckCircle size={35} />,
      label: t("SELLER_STEP_7"),
    },
    {
      icon: <FaHandshake size={35} />,
      label: t("SELLER_STEP_8"),
    },
    {
      icon: <FaIdCard size={35} />,
      label: t("SELLER_STEP_9"),
    },
    {
      icon: <FaMoneyCheck size={35} />,
      label: t("SELLER_STEP_10"),
    },
  ];



  return (
    <div className="seller-flow-container">
      <p className="open-modal-button" onClick={() => setShowModal(true)}>
        {t("SHOW_SELLER_FLOW")}
      </p>

      {showModal && (
        <div
          className="modal-overlay"
          onClick={() => setShowModal(false)}
          onTouchMove={(e) => e.preventDefault()}
        >
          <div
            className="seller-flow-modal-content"
            onClick={(e) => e.stopPropagation()}
            onTouchMove={(e) => e.preventDefault()}
          >
            <h2 className="modal-title">{t("Seller_Flow")}</h2>
            <button
              className="close-modal-button"
              onClick={() => setShowModal(false)}
            >
              ✖
            </button>
            <div
              className={`flow-structure ${isMobile ? "vertical" : "vertical"}`}
            >
              {steps.map((step, index) => (
                <React.Fragment key={index}>
                  <div className="flow-step">
                    <div className="flow-icon">{step.icon}</div>
                    <div className="flow-label">{step.label}</div>
                    {step.description && (
                      <div className="flow-description">{step.description}</div>
                    )}
                    {step.subSteps && (
                      <div className="sub-steps">
                        {step.subSteps.map((subStep, subIndex) => (
                          <div key={subIndex} className="sub-step-container">
                            <div className="sub-step">
                              <div className="sub-step-icon">
                                {subStep.icon}
                              </div>
                              <div className="sub-step-label">
                                {subStep.label}
                              </div>
                            </div>
                            {subIndex < step.subSteps.length - 1 && (
                              <div className="sub-step-arrow">
                                <FaArrowRight size={12} />
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {index < steps.length - 1 && (
                    <div className="flow-arrow">
                      <FaArrowDown size={20} />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SellerFlow;
