import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import noImageFound from "../../../assets/images/noProductFound.png";
import {
  performSearch,
  resetSearchState,
} from "../../../redux/slices/searchSlice";
import "./SearchProduct.scss";
import ItemCard from "../../../component/ItemCard/ItemCard";

const SearchProduct = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { searchItem } = useParams();

  const [searchTerm, setSearchTerm] = useState(searchItem || "");
  const [searchParams, setSearchParams] = useState({
    page: 1,
    limit: 10,
    sortBy: "createdAt",
    sortOrder: "DESC",
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1100);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const { favoriteProducts: userFavoriteProduct } = useSelector(
    (state) => state.user
  );
  const {
    results: searchResults,
    pagination,
    loading,
  } = useSelector((state) => state.search);
  const filterData = useSelector((state) => state.filterData);

  const userData = JSON.parse(localStorage.getItem("userData"));

  const getFilteredProducts = () => {
    if (!searchResults) return [];
    return searchResults
      .filter((product) => {
        if (filterData.priceRange[0] && filterData.priceRange[1]) {
          const price = parseFloat(product.price);
          if (
            price < filterData.priceRange[0] ||
            price > filterData.priceRange[1]
          ) {
            return false;
          }
        }
        if (filterData.brand && filterData.brand.length > 0) {
          return filterData.brand.includes(product.brand);
        }
        return true;
      })
      .sort((a, b) => {
        if (filterData.sortBy === "price") {
          return filterData.sortOrder === "ASC"
            ? parseFloat(a.price) - parseFloat(b.price)
            : parseFloat(b.price) - parseFloat(a.price);
        }
        if (filterData.sortBy === "createdAt") {
          return filterData.sortOrder === "ASC"
            ? new Date(a.createdAt) - new Date(b.createdAt)
            : new Date(b.createdAt) - new Date(a.createdAt);
        }
        return 0;
      });
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 1100);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setSearchTerm(searchItem || "");
  }, [searchItem]);

  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeoutId = setTimeout(() => {
      if (searchTerm?.length > 0) {
        dispatch(
          performSearch({
            query: searchTerm,
            params: searchParams,
          })
        );
      } else {
        dispatch(resetSearchState());
      }
    }, 0);

    setDebounceTimeout(timeoutId);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, searchParams, dispatch]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    if (e.key === "Enter" && searchTerm) {
      navigate(`/search-product/${searchTerm}`);
    }
  };

  const handlePageChange = (newPage) => {
    setSearchParams((prev) => ({
      ...prev,
      page: newPage,
    }));
  };

  const handleSetFavorite = () => {
    // Implement favorite functionality
  };

  const filteredProducts = getFilteredProducts();
  const showNoResults =
    !loading && searchTerm?.length > 0 && filteredProducts?.length === 0;

  return (
    <main className="SearchResultsWrapper">
      <Box className="container">
        {isMobile && (
          <div className="search-container">
            <input
              type="text"
              placeholder={t("SEARCH_PRODUCT")}
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyDown={handleSearchSubmit}
            />
            <div className="search-span-icon">
              <span className="SearchIcon">
                <FaSearch className="icon" />
              </span>
            </div>
          </div>
        )}

        {searchTerm.length > 0 && (
          <div className="sectionHeader" style={{ marginTop: "10px" }}>
            <h3>
              {t("ShowingResult")} "<span>{searchTerm}</span>"
            </h3>
          </div>
        )}

        <div className="favorite-products-container">
          {loading ? (
            <div className="loader-wrapper">
              <div className="loader"></div>
              <p>Loading...</p>
            </div>
          ) : showNoResults ? (
            <div className="noDataBlock">
              <img
                src={noImageFound}
                alt={t("NoResultFound")}
                className="no-image-placeholder"
              />
              <p>{t("NoResultFound")}</p>
            </div>
          ) : (
            filteredProducts?.length > 0 && (
              <>
                <div className="product-list">
                  {filteredProducts.map((product, index) => (
                    <ItemCard
                      userFavoriteProduct={userFavoriteProduct}
                      productId={product?.id}
                      key={index}
                      item={product}
                      name={product.title}
                      price={product.price}
                      description={product.description}
                      imageSrc={product?.productImage[0]}
                      likes={product.likes}
                      isFavorite={product.isFavorite}
                      isSold={product.isSold}
                      goToProductDetails={() =>
                        navigate(`/product/${product.id}`)
                      }
                      handleSetFav={handleSetFavorite}
                      userData={userData}
                      isSearchShownData={true}
                    />
                  ))}
                </div>

                {pagination.totalPages > 1 && (
                  <div className="pagination">
                    <button
                      onClick={() => handlePageChange(pagination.page - 1)}
                      disabled={pagination.page === 1}
                      className="pagination-btn"
                    >
                      {t("PREVIOUS")}
                    </button>

                    <span className="pagination-info">
                      {t("PAGE")} {pagination.page} {t("OF")}{" "}
                      {pagination.totalPages}
                    </span>

                    <button
                      onClick={() => handlePageChange(pagination.page + 1)}
                      disabled={pagination.page === pagination.totalPages}
                      className="pagination-btn"
                    >
                      {t("NEXT")}
                    </button>
                  </div>
                )}
              </>
            )
          )}
        </div>
      </Box>
    </main>
  );
};

export default SearchProduct;
