import React from "react";
import Slider from "react-slick";

import Slide2 from "../../assets/images/slide2.png";
import Slide3 from "../../assets/images/slide3.png";
import Slide4 from "../../assets/images/slide4.png";

import "./Slider.scss";

export default function SimpleSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="slider_outer_container">
      <Slider {...settings}>
        <div>
          <a
            href="https://mounier-bouvard.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="categorySlider">
              <div className="categorySlider__img">
                <img src={Slide2} alt="" />
              </div>
              <div className="categorySlider__content">
                {/* <h3>{t("A_VARIETY_OF_ACCESSORIES_WHICH_MATCHES_YOUR_STYLE")}</h3> */}
              </div>
            </div>
          </a>
        </div>

        <div>
          <a
            href="https://www.azuelos.ma"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="categorySlider">
              <div className="categorySlider__img">
                <img src={Slide3} alt="" />
              </div>
              <div className="categorySlider__content">
                {/* <h3>{t("A_VARIETY_OF_ACCESSORIES_WHICH_MATCHES_YOUR_STYLE")}</h3> */}
              </div>
            </div>
          </a>
        </div>

        <div>
          <a
            href="https://legitapp.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="categorySlider">
              <div className="categorySlider__img">
                <img src={Slide4} alt="" />
              </div>
              <div className="categorySlider__content">
                {/* <h3>{t("A_VARIETY_OF_ACCESSORIES_WHICH_MATCHES_YOUR_STYLE")}</h3> */}
              </div>
            </div>
          </a>
        </div>

        {/* <div>
          <a
            href="https://www.alyapay.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="categorySlider">
              <div className="categorySlider__img">
                <img src={Slide5} alt="" />
              </div>
              <div className="categorySlider__content">
                <h3>{t("A_VARIETY_OF_ACCESSORIES_WHICH_MATCHES_YOUR_STYLE")}</h3>
              </div>
            </div>
          </a>
        </div> */}
      </Slider>
    </div>
  );
}
