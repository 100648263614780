import { Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import "./SectionHeader.scss";

import { IoMdArrowRoundBack } from "react-icons/io";

const SectionHeader = ({ sectionTitle }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleGoBack = () => {
    if (location.key !== "default") {
      navigate(-1); // Go to the previous page in the history stack
    } else {
      navigate("/"); // Fallback in case there is no history
    }
  };

  return (
    <div className="sectionHeader">
      <Typography variant="h3">{t(sectionTitle)}</Typography>
      <Button className="btn btn-primary" onClick={() => handleGoBack()}>
        <IoMdArrowRoundBack /> {t("BACK")}
      </Button>
    </div>
  );
};

export default SectionHeader;
