import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FavoriteService from "../services/FavoriteService";

// ASYNC THUNKS

// ADD FAV ASYNC THUNK
export const addFavorite = createAsyncThunk(
  "favorite/addFavorite",
  async (productId, thunkAPI) => {
    try {
      return await FavoriteService.addFavoriteItem(productId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// FETCH ALL FAV ASYNC THUNK
export const fetchAllFavorites = createAsyncThunk(
  "favorite/fetchAllFavorites",
  async (userId, thunkAPI) => {
    try {
      return await FavoriteService.getAllFavoriteItemsByUserId(userId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// DELETE FAV ASYNC THUNK
export const removeFavorite = createAsyncThunk(
  "favorite/removeFavorite",
  async (favoriteItemId, thunkAPI) => {
    try {
      return await FavoriteService.deleteFavoriteItemById(favoriteItemId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const removeFavoriteByProductId = createAsyncThunk(
  "favorite/removeFavoriteByProductId",
  async (productId, thunkAPI) => {
    try {
      const response = await FavoriteService.removeFavoriteByProductId(
        productId
      );

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// SLICE
const favoriteSlice = createSlice({
  name: "favorite",
  initialState: {
    removedFavoriteItem: null,
    favoriteItems: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetFavoriteState: (state) => {
      state.favoriteItems = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addFavorite.pending, (state) => {
        state.loading = true;
      })
      .addCase(addFavorite.fulfilled, (state, action) => {
        state.loading = false;
        state.favoriteItems.push(action.payload.data);
      })
      .addCase(addFavorite.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchAllFavorites.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllFavorites.fulfilled, (state, action) => {
        
        state.loading = false;
        state.favoriteItems = action.payload.data;
      })
      .addCase(fetchAllFavorites.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(removeFavorite.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeFavorite.fulfilled, (state, action) => {
        state.loading = false;
        state.favoriteItems = state.favoriteItems.filter(
          (item) => item.id !== action.meta.arg
        );
      })
      .addCase(removeFavorite.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(removeFavoriteByProductId.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = null;
        state.successMessage = null;
      })
      .addCase(removeFavoriteByProductId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.successMessage = action.payload.message || "Removed successfully";
        state.removedFavoriteItem = action?.payload;
      })
      .addCase(removeFavoriteByProductId.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.payload || "Failed to remove favorite";
      });
  },
});

// EXPORT ACTIONS
export const { resetFavoriteState } = favoriteSlice.actions;

// EXPORT REDUCER
export default favoriteSlice.reducer;
