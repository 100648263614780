import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import userReducer from "./slices/userSlice";
import cartReducer from "./slices/cartSlice";
import userFavoriteReducer from "./slices/favoriteSlice";
import searchReducer from "./slices/searchSlice";
import notificationReducer from "./slices/notificationSlice";
import productReducer from "./slices/productSlice";
import offerReducer from "./slices/offerSlice";
import sellerReducer from "./slices/sellerSlice";
import soldProductReducer from "./slices/soldProductSlice";
import chatReducer from "./slices/chatSlice"
import filterDataReducer from "./slices/filterSlice"

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    cart: cartReducer,
    offer: offerReducer,
    search: searchReducer,
    product: productReducer,
    notification: notificationReducer,
    userFavorite: userFavoriteReducer,
    seller: sellerReducer,
    soldProduct: soldProductReducer,
    chat: chatReducer,
    filterData: filterDataReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
