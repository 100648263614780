import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../../component/Footer/Footer";
import Header from "../../../component/Header/Header";
import "./notFound.scss";

const NotFound = () => {
  return (
    <>
      <Header />
      <main className="ErrorMainContainer">
        <div className="container">
          <div className="error__title">
            <h1 className="error__num">
              4<span>0</span>4
            </h1>
            <h2>Page Not Found</h2>
          </div>
          <p className="error__message">
            Oops! The page you're looking for doesn't exist.
          </p>
          <Link to="/">Go back to Home</Link>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default NotFound;
