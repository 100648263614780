import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";

import "./SellerProfileForm.scss";

import { ReactComponent as Accessories } from "../../../assets/svg/accessories.svg";
import { ReactComponent as Bag } from "../../../assets/svg/bag.svg";
import { ReactComponent as Jewelry } from "../../../assets/svg/jewelry.svg";
import { ReactComponent as Shoes } from "../../../assets/svg/shoes.svg";
import { ReactComponent as Watch } from "../../../assets/svg/watch.svg";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as DismissCircle } from "../../../assets/svg/DismissCircle.svg";
import { ReactComponent as EyeHide } from "../../../assets/svg/EyeHide.svg";
import { ReactComponent as EyeShow } from "../../../assets/svg/EyeShow.svg";
import { ReactComponent as UploadIcon } from "../../../assets/svg/UploadIcon.svg";

import { useFormik } from "formik";
import { SellerProfileValidationSchema } from "../../../Validation/validationSchemas";

import TermsAndConditionsModal from "../../../component/TermsAndConditions/TermsAndConditions";
import { fetchUserById } from "../../../redux/slices/userSlice";

import { Box, Button } from "@mui/material";
import SectionHeader from "../../../component/SectionHeader/SectionHeader";
import { changePassword } from "../../../redux/slices/authSlice";
import {
  createSellerThunk,
  decryptSellerDataThunk,
} from "../../../redux/slices/sellerSlice";

const SellerProfileForm = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const loginViaSocial = localStorage.getItem("loginViaSocial");
  const [loading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [getSellerPassword, setGetSellerPassword] = useState("");
  const [fileError, setFileError] = useState("");
  const fileInputRef = useRef(null);
  const identityInputRef = useRef(null);
  const [termsAnsConditionLoading, setTermsAnsConditionLoading] =
    useState(false);

  useEffect(() => {
    getSellerUserPassword();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getSellerPassword) {
      formik.setFieldValue("password", getSellerPassword);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSellerPassword]);

  const handleFocus = () => {
    // setInputType("date");
  };
  const handleBlur = () => {
    // setInputType("text");
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [openTermsAndConditions, setOpenTermsAndConditions] = useState(false);

  const authenticationProcessOption = [
    {
      value: "Platform",
      label: t("PLATFORM"),
    },
    {
      value: "Own Proof",
      label: t("PROOF"),
    },
  ];

  const sellerTypeOption = [
    { value: "Individual", label: t("INDIVIDUAL") },
    { value: "Business", label: t("BUSINESS") },
  ];

  async function getSellerUserPassword() {
    try {
      const response = await dispatch(
        decryptSellerDataThunk(userData?.id)
      ).unwrap();
      if (response?.data?.decryptedPassword) {
        setGetSellerPassword(response.data.decryptedPassword);
      }

      if (response?.status === 200) {
        const decryptedPassword = response?.data?.data?.decryptedPassword;
        setGetSellerPassword(decryptedPassword);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      fullName: loginViaSocial
        ? userData?.username
        : userData?.nickname
          ? userData?.nickname
          : "",
      emailAddress: userData?.email ?? "",
      phoneNumber: "",
      password: getSellerPassword,
      governmentId: null,
      dateOfBirth: "",
      residentialAddress: "",
      showroomAddress: "",
      sellerType: "",
      accountHolderName: "",
      bankName: "",
      IBAN: "",
      taxIdentificationNumber: "",
      companyName: "",
      websiteOrSocialMedia: "",
      productCategories: [],
      termsAccepted: false,
      privacyPolicyAccepted: false,
      previousSalesExperience: false,
      authenticationProcess: "",
      profilePhoto: null,
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: SellerProfileValidationSchema(t, i18n.language),
    // onSubmit: (values) => handleSubmitSellerProfile(values),
    onSubmit: () => setOpenTermsAndConditions(true),
  });

  useEffect(() => {
    formik.validateForm();
  }, [i18n.language]);


  const handleSubmitSellerProfile = async (values) => {
    try {
      setTermsAnsConditionLoading(true);

      setTermsAnsConditionLoading(true);
      const formData = new FormData();
      Object.keys(values).forEach((key) => formData.append(key, values[key]));

      const response = await dispatch(createSellerThunk(formData)).unwrap();
      // toast.success(t("SELLER_PROFILE_SUCCESS"), {
      //   onClose: () => navigate("/"),
      //   autoClose: 1500,
      // });

      // formik.resetForm();

      if (response?.statusCode === 200) {
        await handleSubmit(values?.password, userData?.id);
        await dispatch(fetchUserById());
        toast.success(t("SELLER_PROFILE_SUCCESS"), {
          onClose: () => {
            navigate("/");
          },
          autoClose: 1500, // 1.5 seconds
        });
        formik.resetForm();
      }
    } catch (error) {
      toast.error("Error in creating seller profile!");
      console.error("Error:", error);
    } finally {
      setTermsAnsConditionLoading(false);
    }
  };

  const handleSubmit = async (password, userId) => {
    try {
      const response = await dispatch(
        changePassword({ password, userId })
      ).unwrap();

      if (response.statusCode === 200) {
        console.log("Password update successful!");
      }
    } catch (error) {
      console.error("Change password error:", error);
      toast.error(error.response?.data?.message || "Change failed. Try again.");
    }
  };

  const categoryOptions = [
    { value: "watches", label: t("Watches"), icon: <Watch /> },
    { value: "jewelry", label: t("Jewelry"), icon: <Jewelry /> },
    { value: "bags", label: t("Bags"), icon: <Bag /> },
    { value: "shoes ", label: t("Shoes"), icon: <Shoes /> },
    { value: "accessories", label: t("Accessories"), icon: <Accessories /> },
  ];

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileError(""); // Clear previous errors

    if (file) {
      // Check file type
      const supportedFormats = ["image/jpeg", "image/jpg", "image/png"];
      if (!supportedFormats.includes(file.type)) {
        setFileError(t("ONLY_JPG_PNG_ALLOWED"));
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        return;
      }

      // Check file size (5MB)
      if (file.size > 5 * 1024 * 1024) {
        setFileError(t("FILE_SIZE_TOO_LARGE"));
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        return;
      }

      formik.setFieldValue("profilePhoto", file);
    }
  };

  return (
    <main className="ProfileMainWrapper SellerWrapper">
      <div className="container">
        <SectionHeader sectionTitle={t("SELLER_PROFILE")} />
        <div className="add-product-container">
          <form onSubmit={formik.handleSubmit} className="productForm">
            <h4>{t("PERSONAL_INFORMATION")}</h4>

            <div className="productForm__inner">
              <div className="form-group">
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  placeholder={t("FULL_NAME")}
                  value={formik.values.fullName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.fullName && formik.errors.fullName ? (
                  <div className="error-message">{formik.errors.fullName}</div>
                ) : null}
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="emailAddress"
                  placeholder={t("EMAIL_ADDRESS")}
                  value={formik.values.emailAddress}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.emailAddress && formik.errors.emailAddress ? (
                  <div className="error-message">
                    {formik.errors.emailAddress}
                  </div>
                ) : null}
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  // ref={inputRef}
                  name="phoneNumber"
                  placeholder={t("PHONE_NUMBER")}
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                  <div className="error-message">
                    {formik.errors.phoneNumber}
                  </div>
                ) : null}
              </div>
              <Box className="FormGroupOuter">
                <div className="form-group PasswordContainer">
                  {/* <Lock className="icon" /> */}

                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder={t("PASSWORD")}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <span className="EyeIcon" onClick={togglePasswordVisibility}>
                    {showPassword ? <EyeShow /> : <EyeHide />}
                  </span>
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <div className="error-message">{formik.errors.password}</div>
                ) : null}
              </Box>
            </div>
            <div>
              <div className="inputUpload profile_image">
                <input
                  type="file"
                  name="profilePhoto"
                  accept="image/jpeg,image/jpg,image/png"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                />
                <UploadIcon />
                <p>{t("PROFILE_PICTURE")}</p>
              </div>
              {fileError && <div className="error-message">{fileError}</div>}
            </div>

            {formik.values.profilePhoto && (
              <div style={{ width: "100px" }} className="imgPreview">
                <div className="imgPreview__inner">
                  <img
                    src={URL.createObjectURL(formik.values.profilePhoto)}
                    alt={t("UPLOADED_IMAGE")}
                  />
                  <button
                    className="remove-image"
                    onClick={() => {
                      formik.setFieldValue("profilePhoto", null);
                      if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                      }
                    }}
                  >
                    <DismissCircle />
                  </button>
                </div>
              </div>
            )}

            {/* Identity Verification */}
            <div className="SellerFormWrapper">
              <h4>{t("IDENTITY_VERIFICATION")}</h4>
              <div className="productForm__inner">
                <div className="ImageUpload">
                  <div className="inputUpload">
                    <input
                      type="file"
                      accept=".pdf,.doc,.docx"
                      name="governmentId"
                      onChange={(event) =>
                        formik.setFieldValue(
                          "governmentId",
                          event.target.files[0]
                        )
                      }
                      ref={identityInputRef}
                    />
                    <UploadIcon />
                    <p>{t("IDENTIFICATION_PROOF")}</p>
                    {formik.touched.governmentId &&
                      formik.errors.governmentId ? (
                      <div className="error-message">
                        {formik.errors.governmentId}
                      </div>
                    ) : null}
                  </div>
                  {formik.values.governmentId && (
                    <div className="imgPreview">
                      <div className="imgPreview__inner">
                        {formik.values.governmentId.type ===
                          "application/pdf" ? (
                          <embed
                            style={{ maxHeight: "fit-content" }}
                            src={URL.createObjectURL(
                              formik.values.governmentId
                            )}
                            type="application/pdf"
                          />
                        ) : (
                          <img
                            src={URL.createObjectURL(
                              formik.values.governmentId
                            )}
                            alt={t("UPLOADED_IMAGE")}
                          />
                        )}
                        <button
                          className="remove-image"
                          onClick={() => {
                            formik.setFieldValue("governmentId", null);
                            if (identityInputRef.current) {
                              identityInputRef.current.value = "";
                            }
                          }}
                        >
                          <DismissCircle />
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="dateOfBirth" className="date-placeholder">
                    {t("BIRTH_DATE")}
                  </label>
                  <input
                    type={"date"}
                    name="dateOfBirth"
                    placeholder={t("BIRTH_DATE")}
                    value={formik.values.dateOfBirth}
                    onChange={formik.handleChange}
                    onFocus={handleFocus}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      handleBlur();
                    }}
                    max={new Date().toISOString().split("T")[0]}
                  />
                  {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? (
                    <div className="error-message">
                      {formik.errors.dateOfBirth}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            {/* Address Details */}
            <div className="SellerFormWrapper">
              <h4>{t("ADDRESS_DETAILS")}</h4>
              <div className="productForm__inner">
                <div className="form-group">
                  <input
                    type="text"
                    name="residentialAddress"
                    placeholder={t("RESIDENTIAL_ADDRESS")}
                    value={formik.values.residentialAddress}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.residentialAddress &&
                    formik.errors.residentialAddress ? (
                    <div className="error-message">
                      {formik.errors.residentialAddress}
                    </div>
                  ) : null}
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    name="residentialStreet"
                    placeholder={t("CITY")}
                    value={formik.values.residentialStreet}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.residentialStreet &&
                    formik.errors.residentialStreet && (
                      <div className="error-message">
                        {formik.errors.residentialStreet}
                      </div>
                    )}
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    name="residentialZip"
                    placeholder={t("ZIP_CODE")}
                    value={formik.values.residentialZip}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.residentialZip &&
                    formik.errors.residentialZip && (
                      <div className="error-message">
                        {formik.errors.residentialZip}
                      </div>
                    )}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="showroomAddress"
                    placeholder={t("SHOW_ROOM_ADDRESS")}
                    value={formik.values.showroomAddress}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {/* {formik.touched.showroomAddress &&
                formik.errors.showroomAddress ? (
                  <div className="error-message">
                    {formik.errors.showroomAddress}
                  </div>
                ) : null} */}
                </div>
              </div>
            </div>

            {/* Payment Information */}
            <div className="SellerFormWrapper PaymentInfo">
              <h4>{t("PAYMENT_INFORMATION")}</h4>
              <div className="productForm__inner">
                <div className="form-group">
                  <input
                    type="text"
                    name="accountHolderName"
                    placeholder={t("ACCOUNT_NAME")}
                    value={formik.values.accountHolderName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.accountHolderName &&
                    formik.errors.accountHolderName ? (
                    <div className="error-message">
                      {formik.errors.accountHolderName}
                    </div>
                  ) : null}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="IBAN"
                    placeholder="IBAN"
                    value={formik.values.IBAN}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.IBAN && formik.errors.IBAN ? (
                    <div className="error-message">{formik.errors.IBAN}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="bankName"
                    placeholder={t("BANK_NAME")}
                    value={formik.values.bankName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.bankName && formik.errors.bankName ? (
                    <div className="error-message">
                      {formik.errors.bankName}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="sellerInfo">
              <div className="productForm__inner">
                <div className="form-group">
                  <h4>{t("SELLER_TYPE")}</h4>
                  <Select
                    className="customSelect"
                    name="sellerType"
                    options={sellerTypeOption}
                    value={sellerTypeOption.find(
                      (option) => option.value === formik.values.sellerType
                    )}
                    onChange={(selectedOption) =>
                      formik.setFieldValue("sellerType", selectedOption.value)
                    }
                    placeholder={t("SELLER_TYPE")}
                  />
                  {formik.touched.sellerType && formik.errors.sellerType && (
                    <div className="error-message">
                      {formik.errors.sellerType}
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <h4>{t("PRODUCT_CATEGORIES")}</h4>
                  <Select
                    classNamePrefix="customSelect"
                    name="productCategories"
                    placeholder={t("PRODUCT_CATEGORIES")}
                    isMulti={true}
                    options={categoryOptions}
                    value={categoryOptions.filter((option) =>
                      formik.values.productCategories.includes(option.value)
                    )}
                    onChange={(selectedOptions) => {
                      formik.setFieldTouched("productCategories", true);
                      formik.setFieldValue(
                        "productCategories",
                        selectedOptions
                          ? selectedOptions.map((option) => option.value)
                          : []
                      );
                    }}
                  />
                  {/* <Select
                      className="customSelect"
                      name="category"
                      options={categoryOptions}
                      value={formData.category}
                      onChange={handleChange}
                      placeholder={t("SELECT_A_CATEGORY")}
                    /> */}
                  {formik.touched.productCategories &&
                    formik.errors.productCategories ? (
                    <div className="error-message">
                      {formik.errors.productCategories}
                    </div>
                  ) : null}
                </div>
              </div>
              {formik.values.sellerType === "Business" && (
                <div
                  className="SellerFormWrapper"
                  style={{ position: "relative", zIndex: "0" }}
                >
                  <h4>{t("TAX_INFORMATION")}</h4>
                  <div className="productForm__inner">
                    <div className="form-group">
                      <input
                        type="text"
                        name="taxIdentificationNumber"
                        placeholder={t("TAX_IDENTIFICATION_NUMBER")}
                        value={formik.values.taxIdentificationNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.taxIdentificationNumber &&
                        formik.errors.taxIdentificationNumber ? (
                        <div className="error-message">
                          {formik.errors.taxIdentificationNumber}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="companyName"
                        placeholder={t("COMPANY_NAME")}
                        value={formik.values.companyName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.companyName &&
                        formik.errors.companyName ? (
                        <div className="error-message">
                          {formik.errors.companyName}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <input
                        type="url"
                        name="websiteOrSocialMedia"
                        placeholder={t("WEBSITE_SOCIAL_MEDIA")}
                        value={formik.values.websiteOrSocialMedia}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.websiteOrSocialMedia &&
                        formik.errors.websiteOrSocialMedia ? (
                        <div className="error-message">
                          {formik.errors.websiteOrSocialMedia}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="SellerFormWrapper">
              <h4>{t("BRAND_AUTHENTICATION")}</h4>
              <div className="SellerFormWrapperInner">
                <label>
                  <input
                    type="checkbox"
                    name="previousSalesExperience"
                    checked={formik.values.previousSalesExperience}
                    onChange={(event) => {
                      formik.setFieldValue(
                        "previousSalesExperience",
                        event.target.checked
                      );
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {t("PREVIOUS_SALES_EXPERIENCE")}
                  {formik.touched.previousSalesExperience &&
                    formik.errors.previousSalesExperience ? (
                    <div className="error-message">
                      {formik.errors.previousSalesExperience}
                    </div>
                  ) : null}
                </label>
                <div className="form-group">
                  <Select
                    className="customSelect"
                    name="authenticationProcess"
                    placeholder={t("AUTHENTICATION_PROCESS")}
                    options={authenticationProcessOption}
                    value={authenticationProcessOption.find(
                      (option) =>
                        option.value === formik.values.authenticationProcess
                    )}
                    onChange={(selectedOption) =>
                      formik.setFieldValue(
                        "authenticationProcess",
                        selectedOption.value
                      )
                    }
                  />
                  {formik.touched.authenticationProcess &&
                    formik.errors.authenticationProcess ? (
                    <div className="error-message">
                      {formik.errors.authenticationProcess}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="btnPart">
              <Button type="submit" className="btn_dark">
                {loading ? "Loading..." : t("CREATE_PROFILE")}
              </Button>
            </div>
          </form>
        </div>

        <TermsAndConditionsModal
          show={openTermsAndConditions}
          onHide={() => setOpenTermsAndConditions(false)}
          onProceed={() => handleSubmitSellerProfile(formik.values)}
          termsAnsConditionLoading={termsAnsConditionLoading}
        />
      </div>
    </main>
  );
};

export default SellerProfileForm;
