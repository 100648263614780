import { createTheme } from "@mui/material";

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#f5f5f5",
          margin: 0,
          fontFamily: `'Poppins', sans-serif`,
        },
        a: {
          textDecoration: "none",
          color: "inherit",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "8px 10px",
          backgroundColor: "#F5F5F5",
          borderRadius: "10px",
          color: "#333333",
          textTransform: "unset",
        },
      },
    },
  },
});

export default theme;
