import React from "react";
import { Navigate } from "react-router-dom";

const PublicRoute = ({ children }) => {
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const isAdminLogin = userData?.is_admin === true;

  // If token exists
  if (token) {
    return isAdminLogin !== false? <Navigate to="/admin" /> : <Navigate to="/" />;
  }

  return children;
};

export default PublicRoute;
