import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosSearch } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import io from "socket.io-client";

import {
  updateGlobalChatCount,
  updateMessageSeenStatus,
  uploadFileAction,
} from "../../redux/slices/chatSlice";

import ProductDetailsModel from "../ProductDetails/ProductDetailsModel";
import ChatForm from "./component/ChatInput";
import ChatInnerHeader from "./component/ChatSellerDetails";
import ChatList from "./component/LeftSidebar";
import MessagesList from "./component/MessageContent";
import FilePreviewList from "./component/PreviewItem";
import SideBarButton from "./component/SideBarButton";

import noChatFound from "../../assets/images/no_chat_found.jpg";
import "./ChatInterface.scss";

const socket = io(process.env.REACT_APP_SOCKET_URL);

const ChatApp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userData = JSON.parse(localStorage.getItem("userData"));

  const senderId = userData?.id;

  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [input, setInput] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [receiverId, setReceiverId] = useState(null);
  const [receivers, setReceivers] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [productId, setProductId] = useState(null);
  const [currentPerson, setCurrentPerson] = useState("buyer");
  const [activeChat, setActiveChat] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sellerUnseenCount, setSellerUnseenCount] = useState(0);
  const [BuyerUnseenCount, setBuyerUnseenCount] = useState(0);
  const [documentsUrl, setDocumentsUrl] = useState(null);
  const [
    currentProductSellerBuyerDetails,
    setCurrentProductSellerBuyerDetails,
  ] = useState(null);
  const messageEndRef = useRef(null);
  const handleCloseModal = () => setIsModalOpen(false);

  useEffect(() => {
    let intervalId;

    if (senderId) {
      intervalId = setInterval(() => {
        fetchOfferDetails();
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [senderId]);

  const fetchOfferDetails = async () => {
    socket.emit("getOfferAcceptDetails", { userId: senderId });

    socket.on("offerAcceptDetailsResponse", (data) => {
      setSellers(data?.sellerChat || []);
      setReceivers(data?.receiverChat || []);

      const totalSellerUnseenCount = data?.sellerChat?.reduce(
        (total, seller) => total + seller?.unseenCount,
        0
      );
      const totalBuyerUnseenCount = data?.receiverChat?.reduce(
        (total, receiver) => total + receiver?.unseenCount,
        0
      );
      setSellerUnseenCount(totalSellerUnseenCount);
      setBuyerUnseenCount(totalBuyerUnseenCount);
      const totalMessageCount = totalBuyerUnseenCount + totalSellerUnseenCount;
      dispatch(updateGlobalChatCount(totalMessageCount));
      setLoadingData(false);
    });

    socket.on("error", (error) => console.error("Socket error:", error));

    return () => {
      socket.off("offerAcceptDetailsResponse");
      socket.off("error");
    };
  };

  const handleUserClick = async (id, pId) => {
    if (isMobile) {
      setIsChatOpen(true);
    }
    try {
      if (id !== receiverId || pId !== productId) {
        setMessages([]);
        setReceiverId(id);
        setProductId(pId);
        setActiveChat({ id, productId: pId });

        dispatch(updateMessageSeenStatus({ senderId: id, productId: pId }));
        socket.emit("messageSeen", { receiverId: id, productId: pId });
        socket.emit("joinRoom", { userId: senderId, receiverId: id });
      }
    } catch (error) {
      console.error("Error in handleUserClick function:", error);
    }
  };

  useEffect(() => {
    if (senderId && receiverId && productId) {
      socket.emit("getHistory", { senderId, receiverId, productId });

      const handleNewMessage = (msg) => {
        if (msg.productId === productId && msg.receiverId === senderId) {
          dispatch(
            updateMessageSeenStatus({
              senderId: msg.senderId,
              productId: msg.productId,
            })
          );
          setMessages((prev) => [...prev, msg]);
        }
      };

      socket.on("messageHistory", (history) => setMessages(history));
      socket.on("newMessage", handleNewMessage);

      return () => {
        socket.emit("leaveRoom", { userId: senderId, receiverId });
        socket.off("messageHistory");
        socket.off("newMessage");
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [senderId, receiverId, productId]);

  useEffect(() => {
    if (
      messageEndRef.current &&
      !currentProductSellerBuyerDetails?.product?.isSold
    ) {
      const element = messageEndRef.current;
      setLoading(true);

      element.scrollIntoView({ behavior: "auto" });

      const handleScroll = () => {
        const isAtBottom =
          element.getBoundingClientRect().bottom <= window.innerHeight;
        if (isAtBottom) {
          setLoading(false);
          window.removeEventListener("scroll", handleScroll);
        }
      };

      window.addEventListener("scroll", handleScroll);

      const fallbackTimeout = setTimeout(() => {
        setLoading(false);
        window.removeEventListener("scroll", handleScroll);
      }, 1000);

      return () => {
        clearTimeout(fallbackTimeout);
        window.removeEventListener("scroll", handleScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if ((input.trim() || selectedFiles.length) && senderId && receiverId) {
      const message = {
        senderId,
        receiverId,
        content: input.trim(),
        files: documentsUrl,
        productId,
        timestamp: new Date(),
      };
      socket.emit("chatMessage", message);
      setMessages((prev) => [...prev, message]);
      setInput("");
      setSelectedFiles([]);
    }
    setDocumentsUrl(null);
  };

  const handleGoBack = () => {
    if (isMobile) {
      setIsChatOpen(false);
    } else if (location.key !== "default") {
      navigate(-1);
    } else {
      navigate("/home");
    }
  };

  const resetChat = async () => {
    setMessages([]);
    setProductId(null);
    setReceiverId(null);
    setActiveChat(null);
    setCurrentProductSellerBuyerDetails(null);
    setSelectedFiles([]);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleFileChange = async (e) => {
    e.preventDefault();
    const files = Array.from(e?.target?.files);
    const imageFiles = files.filter((file) => file.type.startsWith("image/"));
    if (files.length !== imageFiles.length) {
      toast.error("Only image files are allowed.");
      return;
    }
    setSelectedFiles(files);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    dispatch(uploadFileAction(formData))
      .unwrap()
      .then((response) => {
        if (response?.fileUrls?.length) {
          const url = response.fileUrls[0];
          setDocumentsUrl(url);

          toast.success("Files uploaded successfully!");
        } else {
          toast.error("File upload failed.");
        }
      })
      .catch((error) => {
        toast.error(`Error uploading files: ${error}`);
      });
  };
  return (
    <main className="ChatMainContainer">
      <Box className="container">
        {sellers?.length || receivers?.length ? (
          <div className="ChatAppContainer">
            <div className="ChatSidebar sidebar">
              <div className="SideBarHeader">
                {userData?.isSeller !== false && (
                  <>
                    <SideBarButton
                      label={t("AsABuyer")}
                      isActive={currentPerson === "buyer"}
                      unseenCount={BuyerUnseenCount}
                      onClick={() => {
                        setCurrentPerson("buyer");
                        resetChat();
                      }}
                    />
                    <SideBarButton
                      label={t("AsASeller")}
                      isActive={currentPerson === "seller"}
                      unseenCount={sellerUnseenCount}
                      onClick={() => {
                        setCurrentPerson("seller");
                        resetChat();
                      }}
                    />
                  </>
                )}
              </div>
              <div className="UserChatList">
                <div className="search-container">
                  <div className="search-span-icon">
                    <IoIosSearch className="icon" />
                  </div>
                  <input
                    type="text"
                    placeholder={t("Cherche ici...")}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
                <ChatList
                  users={currentPerson === "seller" ? sellers : receivers}
                  activeChat={activeChat}
                  handleUserClick={handleUserClick}
                  setCurrentProductSellerBuyerDetails={
                    setCurrentProductSellerBuyerDetails
                  }
                  userType={currentPerson}
                  searchQuery={searchQuery}
                />
              </div>
            </div>
            <div
              className={`ChatBody ${isMobile && isChatOpen ? "open" : ""} ${
                productId ? "open-chat" : ""
              }`}
            >
              {!currentProductSellerBuyerDetails && (
                <div>
                  <Button
                    className="btn btn_dark"
                    onClick={() => handleGoBack()}
                  >
                    {t("BACK")}
                  </Button>
                </div>
              )}
              <ChatInnerHeader
                currentProductSellerBuyerDetails={
                  currentProductSellerBuyerDetails
                }
                handleGoBack={() => handleGoBack()}
                handleOpenModal={() => setIsModalOpen(true)}
              />

              <MessagesList
                currentProductSellerBuyerDetails={
                  currentProductSellerBuyerDetails
                }
                messages={messages}
                senderId={senderId}
                messageEndRef={messageEndRef}
                loading={loading}
              />
              {productId !== null &&
              !currentProductSellerBuyerDetails?.product?.isSold ? (
                <>
                  <ChatForm
                    input={input}
                    setInput={setInput}
                    handleSubmit={handleSubmit}
                    selectedFiles={selectedFiles}
                    handleFileChange={handleFileChange}
                    receiverId={receiverId}
                  />
                  <FilePreviewList
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                  />
                </>
              ) : null}
            </div>
          </div>
        ) : (
          sellers?.length === 0 &&
          receivers?.length === 0 && (
            <>
              {loadingData ? (
                <div className="noDataBlock">
                  <div className="loader-wrapper">
                    <div className="loader"></div>
                    <p>Loading...</p>
                  </div>
                </div>
              ) : (
                <div className="noDataBlock">
                  <img
                    src={noChatFound}
                    alt={t("NoResultFound")}
                    className="no-image-placeholder"
                  />
                  <Typography>{t("NO_CHAT_FOUND")}</Typography>
                </div>
              )}
            </>
          )
        )}
      </Box>
      <ProductDetailsModel
        currentProductSellerBuyerDetails={currentProductSellerBuyerDetails}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        isChatDescriptions={true}
      />
    </main>
  );
};

export default ChatApp;
