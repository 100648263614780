import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ children, adminOnly = false }) => {
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const isAdminLogin = userData?.is_admin === true;
  const location = useLocation();

  // Admin should only access /admin route
  if (isAdminLogin && location.pathname !== "/admin") {
    return <Navigate to="/admin" />; 
  }

  
  if (adminOnly) {
    if (token && isAdminLogin && location.pathname === "/admin") {
      return children; 
    }
    return <Navigate to="/" />; 
  }


  if (token ) {
    return children;
  }


  return <Navigate to="/" />;
};

export default PrivateRoute;