import { useTranslation } from "react-i18next";

export const useSensitivePatterns = () => {
  const { t } = useTranslation();

  const createBidirectionalPattern = (terms) => {
    return terms.map(term => {
      // Add both LTR and RTL markers around the term
      return `\\u200e${term}\\u200e|\\u200f${term}\\u200f|${term}`;
    }).join("|");
  };



  const arabicTerms = createBidirectionalPattern([
    // Directional terms
    "شمال", "جنوب", "شرق", "غرب", "قدّام", "وراء", "جنب",
    // Location terms
    "دار", "شقة", "عمارة", "إقامة", "رقم", "بناية", "حي",
    "زنقة", "شارع", "طريق", "حومة", "درب", "قصبة", "سكّة",
    "العنوان", "بلاصة", "الموقع", "لوكال",
    // Additional terms
    "شعبي", "راقي", "قرية", "دوار", "المدينة", "المنطقة"
  ]);

  const transliteratedTerms = [
    // Basic terms
    "l3ounwan", "blasa", "lmawqi3", "local",
    "dar", "shqqa", "3imara", "iqama", "raqm", "binaya", "7ay",
    // Additional terms
    "sha3bi", "cha3bi", "9riya", "qriya", "douar", "lmdina", "lmintaqa",
    "chamal", "jnoub", "shrq", "gharb", "9ddam", "qddam", "wara", "janb"
  ].join("|");

  const generalAddressTerms = [
    // English terms
    "address", "residence", "location", "place", "spot", "point", "area", "coordinates",
    "building", "apartment", "unit", "block", "suite", "floor", "level", "house",
    "flat", "tower", "villa", "compound", "complex", "postal", "zip",
    // French terms
    "adresse", "résidence", "localisation", "lieu", "emplacement", "point", "zone",
    "coordonnées", "bâtiment", "appartement", "logement", "bloc", "suite", "étage",
    "niveau", "maison", "pavillon", "villa", "tour", "complexe", "postal", "appt",

    // Moroccan Darija terms
    "l3ounwan", "blasa", "lmawqi3", "local", "العنوان", "بلاصة", "الموقع", "لوكال",
    "dar", "shqqa", "3imara", "iqama", "raqm", "binaya", "7ay",
    "دار", "شقة", "عمارة", "إقامة", "رقم", "بناية", "حي"
  ].join("|");

  const streetTerms = [
    // English terms
    "street", "st", "avenue", "ave", "boulevard", "blvd", "road", "rd",
    "drive", "dr", "lane", "ln", "alley", "path", "court", "ct",
    "circle", "cir", "terrace", "way", "route", "highway", "hwy", "junction",
    // French terms
    "rue", "avenue", "av", "boulevard", "bd", "route", "rte", "chemin",
    "impasse", "imp", "allée", "all", "passage", "cour", "rond-point",
    "terrasse", "voie", "sentier", "périphérique", "traverse",

    // Moroccan Darija terms
    "zanqa", "shari3", "triq", "7ouma", "darb", "qasba", "skka",
    "زنقة", "شارع", "طريق", "حومة", "درب", "قصبة", "سكّة",
    "zan", "tr", "sh", "drb", "زن", "طر", "ش", "درب"
  ].join("|");

  const locationIndicators = [
    // English terms
    "district", "neighborhood", "quarter", "town", "village", "city",
    "state", "region", "province", "county", "landmark", "crossroads",
    "corner", "near",
    // French terms
    "quartier", "arrondissement", "secteur", "commune", "ville", "village",
    "département", "région", "province", "pays", "repère", "carrefour",
    "coin", "près de",

    // Moroccan Darija terms
    "7ay sha3bi", "7ay raqi", "qriya", "douar", "lmdina", "lmintaqa",
    "حي شعبي", "حي راقي", "قرية", "دوار", "المدينة", "المنطقة"
  ].join("|");

  const directions = [
    // English terms
    "north", "south", "east", "west",
    "northeast", "northwest", "southeast", "southwest",
    // French terms
    "nord", "sud", "est", "ouest",
    "nord-est", "nord-ouest", "sud-est", "sud-ouest",

    // Moroccan Darija terms
    "chamal", "jnoub", "shrq", "gharb", "qddam", "wara", "janb",
    "شمال", "جنوب", "شرق", "غرب", "قدّام", "وراء", "جنب"
  ].join("|");

  return [

    {
      pattern: new RegExp(`\\b(${arabicTerms})\\b`, 'um'),
      message: t("ARABIC_TERM_SENSITIVE_MESSAGE")
    },
    {
      pattern: new RegExp(`\\b(${transliteratedTerms})\\b`, 'i'),
      message: t("TRANSLITERATED_TERM_SENSITIVE_MESSAGE")
    },
    {
      pattern: /[\u0600-\u06FF]+\s*\d+|\d+\s*[\u0600-\u06FF]+/u,
      message: t("MIXED_NUMBER_SENSITIVE_MESSAGE")
    },
    {
      pattern: /\b\w*[379]\w*\b/i,
      message: t("ARABIC_NUMBER_SUBSTITUTION_SENSITIVE_MESSAGE")
    },
    // Arabic with numbers pattern
    {
      pattern: /\b\d+\s*[\u0600-\u06FF]+|\b[\u0600-\u06FF]+\s*\d+/u,
      message: t("ADDRESS_NUMBER_SENSITIVE_MESSAGE"),
    },
    // Enhanced pattern for mixed Arabic script
    {
      pattern: /[\u0600-\u06FF]{2,}/u,
      message: t("ARABIC_TEXT_SENSITIVE_MESSAGE"),
    },
    // Previous patterns...
    {
      pattern: new RegExp(`\\b(${generalAddressTerms})\\b`, 'i'),
      message: t("GENERAL_ADDRESS_TERM_SENSITIVE_MESSAGE"),
    },

    {
      pattern: /\b\d+\s*(?:زنقة|شارع|طريق|درب|زن|طر|ش|درب)\b/i,
      message: t("STREET_ADDRESS_SENSITIVE_MESSAGE"),
    },
    {
      pattern: /\b(?:دار|شقة|عمارة|إقامة|بناية)\s*\d+\b/i,
      message: t("BUILDING_NUMBER_SENSITIVE_MESSAGE"),
    },
    {
      pattern: /\b(?:حي|حومة)\s*(?:\w+|\d+)\b/i,
      message: t("NEIGHBORHOOD_SENSITIVE_MESSAGE"),
    },

    {
      pattern: /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i,
      message: t("EMAIL_ADDRESS_SENSITIVE_MESSAGE"),
    },
    // Address patterns
    {
      pattern: new RegExp(`\\b(${generalAddressTerms})\\b`, 'i'),
      message: t("GENERAL_ADDRESS_TERM_SENSITIVE_MESSAGE"),
    },
    {
      pattern: new RegExp(`\\b(${streetTerms})\\b`, 'i'),
      message: t("STREET_TERM_SENSITIVE_MESSAGE"),
    },
    {
      pattern: new RegExp(`\\b(${locationIndicators})\\b`, 'i'),
      message: t("LOCATION_INDICATOR_SENSITIVE_MESSAGE"),
    },
    {
      pattern: new RegExp(`\\b(${directions})\\b`, 'i'),
      message: t("DIRECTIONAL_REFERENCE_SENSITIVE_MESSAGE"),
    },
    // French postal code pattern
    {
      pattern: /\b\d{5}\b/,
      message: t("ZIP_STREET_ADDRESS_SENSITIVE_MESSAGE"),
    },
    // French specific address pattern
    {
      pattern: /\d+[\s,]+(?:rue|avenue|av|boulevard|bd|route|rte|chemin|impasse|imp|allée|all|passage|cour)\b/i,
      message: t("STREET_ADDRESS_SENSITIVE_MESSAGE"),
    },
    // P.O. Box in French
    {
      pattern: /\b[B|b]?[P|p]?\s*\d+\b|\b[C|c]ode\s*[P|p]ostal\b/i,
      message: t("PO_STREET_ADDRESS_SENSITIVE_MESSAGE"),
    },

    // Existing email patterns
    {
      pattern: /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i,
      message: t("EMAIL_ADDRESS_SENSITIVE_MESSAGE"),
    },
    {
      pattern: /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi,
      message: t("ALTERNATE_EMAIL_ADDRESS_SENSITIVE_MESSAGE"),
    },

    // Existing phone patterns
    {
      pattern: /\b\d{10}\b/,
      message: t("BASIC_PHONE_NUMBER_SENSITIVE_MESSAGE"),
    },
    {
      pattern: /\b(\+?\d{1,3}[-.\s]?)?(\d{2,3}[-.\s]?)?\d{3}[-.\s]?\d{3,4}[-.\s]?\d{4}\b/,
      message: t("INTERNATIONAL_PHONE_NUMBER_SENSITIVE_MESSAGE"),
    },
    {
      pattern: /(\d{3}[-.)]\s*\d{3}[-.)]\s*\d{4})/,
      message: t("SEPARATOR_PHONE_NUMBER_SENSITIVE_MESSAGE"),
    },

    // New general address term patterns
    {
      pattern: new RegExp(`\\b(${generalAddressTerms})\\b`, 'i'),
      message: t("GENERAL_ADDRESS_TERM_SENSITIVE_MESSAGE"),
    },

    // New street-related patterns
    {
      pattern: new RegExp(`\\b(${streetTerms})\\b`, 'i'),
      message: t("STREET_TERM_SENSITIVE_MESSAGE"),
    },

    // New location indicator patterns
    {
      pattern: new RegExp(`\\b(${locationIndicators})\\b`, 'i'),
      message: t("LOCATION_INDICATOR_SENSITIVE_MESSAGE"),
    },

    // New directional reference patterns
    {
      pattern: new RegExp(`\\b(${directions})\\b`, 'i'),
      message: t("DIRECTIONAL_REFERENCE_SENSITIVE_MESSAGE"),
    },

    // Existing address patterns
    {
      pattern: /\d+\s+([a-zA-Z]+\s*)+(?:street|st|avenue|ave|road|rd|highway|hwy|square|sq|trail|trl|drive|dr|court|ct|park|parkway|pkwy|circle|cir|boulevard|blvd)\b/i,
      message: t("STREET_ADDRESS_SENSITIVE_MESSAGE"),
    },
    {
      pattern: /\b[P|p]?(OST|ost)?\s*[O|o]?(FFICE|ffice)?\s*[B|b]?(OX|ox)?\s+\d+/i,
      message: t("PO_STREET_ADDRESS_SENSITIVE_MESSAGE"),
    },

    // Postal code patterns
    {
      pattern: /\b\d{5}(-\d{4})?\b|\b[A-Z]\d[A-Z]\s?\d[A-Z]\d\b/i,
      message: t("ZIP_STREET_ADDRESS_SENSITIVE_MESSAGE"),
    },
    {
      pattern: /\b\d{6}\b/,
      message: t("COMMON_INDIAN_PIN_SENSITIVE_MESSAGE"),
    },

    // Location patterns
    {
      pattern: /\b(?:[A-Z][a-zA-Z\-\s]+,\s*[A-Z]{2})\b/,
      message: t("CITY_STATE_COMBINATION_SENSITIVE_MESSAGE"),
    },
    {
      pattern: /\b(?:mumbai|delhi|bangalore|hyderabad|chennai|kolkata|pune|ahmedabad|jaipur)(?:\s*,\s*(?:maharashtra|delhi|karnataka|telangana|tamil nadu|west bengal|rajasthan|gujarat))?\b/i,
      message: t("CITY_STATE_COMBINATION_SENSITIVE_MESSAGE"),
    },

    // Keep all existing financial and identification patterns...
    {
      pattern: /\b(?:\d[ -]*?){13,16}\b/,
      message: t("CREDIT_CARD_SENSITIVE_MESSAGE"),
    },
    {
      pattern: /\b\d{9,18}\b/,
      message: t("BANK_ACCOUNT_NUMBER_SENSITIVE_MESSAGE"),
    },
    {
      pattern: /\b[A-Z]{4}0[A-Z0-9]{6}\b/,
      message: t("IFSC_CODE_SENSITIVE_MESSAGE"),
    },
    {
      pattern: /\b\d{4}\s?\d{4}\s?\d{4}\b/,
      message: t("IDENTIFICATION_NUMBER_SENSITIVE_MESSAGE"),
    },
    {
      pattern: /\b[A-Z]{5}[0-9]{4}[A-Z]{1}\b/,
      message: t("PAN_CARD_SENSITIVE_MESSAGE"),
    },
    {
      pattern: /\b[A-Z]{1,2}[0-9]{6,7}\b/,
      message: t("PASSPORT_NUMBER_SENSITIVE_MESSAGE"),
    },

    // Keep all existing sensitive keyword patterns...
    {
      pattern: /\b(password|passwd|pwd)\b/i,
      message: t("PASSWORD_SENSITIVE_MESSAGE"),
    },
    {
      pattern: /\b(credit\s*card|debit\s*card|cvv|pin\s*code)\b/i,
      message: t("PAYMENT_OR_BANK_SENSITIVE_MESSAGE"),
    },
    {
      pattern: /\b(ssn|social\s*security|tax\s*id)\b/i,
      message: t("GOVT_ID_SENSITIVE_MESSAGE"),
    },
    {
      pattern: /\b(aadhar|aadhaar|pan\s*card|voter\s*id)\b/i,
      message: t("INDIAN_ID_RELATED_SENSITIVE_MESSAGE"),
    },
  ];
};