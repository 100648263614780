export const HeaderPath = [
  "/",
  "/favorites",
  "/cart",
  "/chat",
  "/profile",
  "/messages",
  "/payment/:id",
  "/products",
  "/add-product",
  "/product",
  "/seller-profile",
  "/search-product/:searchItem",
  "/about",
  "/goods",
  "/contact-us",
  "/faq",
  "/blog",
  "/journey",
  "/terms-conditions"
];

export const FooterPath = [
  "/",
  "/favorites",
  "/cart",
  "/profile",
  "/messages",
  "/payment/:id",
  "/search-product/:searchItem",
  "/products",
  "/add-product",
  "/product",
  "/seller-profile",
  "/about",
  "/goods",
  "/contact-us",
  "/faq",
  "/blog",
  "/journey",
  "/terms-conditions"
];


