import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ChatInterfaceService from "../services/ChatInterface";

export const fetchUserOfferList = createAsyncThunk(
  "chatInterface/fetchUserOfferList",
  async (senderId, thunkAPI) => {
    try {
      return await ChatInterfaceService.getUserOfferList(senderId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateMessageSeenStatus = createAsyncThunk(
  "chatInterface/updateSeenStatus",
  async ({ senderId, productId }, thunkAPI) => {
    try {
      return await ChatInterfaceService.updateSeenStatus(senderId, productId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchUnseenCount = createAsyncThunk(
  "chatInterface/fetchUnseenCount",
  async ({ receiverId, productId, senderId }, thunkAPI) => {
    try {
      return await ChatInterfaceService.getUnseenCount(
        receiverId,
        productId,
        senderId
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const uploadFileAction = createAsyncThunk(
  "chatInterface/uploadFile",
  async (formData, thunkAPI) => {
    try {
      return await ChatInterfaceService.uploadFile(formData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const checkChatInitiated = createAsyncThunk(
  "chat/checkChatInitiated",
  async ({ userId, productId }, thunkAPI) => {
    try {
      const status = await ChatInterfaceService.isChatInitiated(userId, productId); 
      return status; 
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message); 
    }
  }
);

const chatInterfaceSlice = createSlice({
  name: "chatInterface",
  initialState: {
    userOffers: [],
    status: "idle",
    error: null,
    unseenCount: 0,
    uploadedFile: null,
    globalChatCount: 0,
    isChatInitiated: null,
  },
  reducers: {
    updateGlobalChatCount: (state, action) => {
      state.globalChatCount = action.payload ?? 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserOfferList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserOfferList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userOffers = action.payload.data.offers || [];
      })
      .addCase(fetchUserOfferList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateMessageSeenStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateMessageSeenStatus.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(updateMessageSeenStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
  
      .addCase(uploadFileAction.pending, (state) => {
        state.status = "loading";
      })
      .addCase(uploadFileAction.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.uploadedFile = action.payload.data;
      })
      .addCase(uploadFileAction.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchUnseenCount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUnseenCount.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.unseenCount = action.payload.data.count || 0;
        state.globalChatCount =
          action.payload.data.totalGlobalCount || state.globalChatCount;
      })
      .addCase(fetchUnseenCount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(checkChatInitiated.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(checkChatInitiated.fulfilled, (state, action) => {
        state.isChatInitiated = action.payload; 
        state.loading = false;
      })
      .addCase(checkChatInitiated.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to check chat status";
      });
  },
});

export const { updateGlobalChatCount } = chatInterfaceSlice.actions;
export default chatInterfaceSlice.reducer;
