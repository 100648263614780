import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosEyeOff, IoMdEye } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import UserDetails from "../UserDetails/UserDetails";
import "./Users.scss";

const Users = ({ activeView }) => {
  const { t } = useTranslation();

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    getAllUser();
  }, []);

  // const handleApprove = async (id) => {
  //   try {
  //     setLoading(true);
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}/update-seller-status/${id}`
  //     );
  //     if (response?.status === 200) {
  //       getAllUser();
  //     }
  //   } catch (error) {
  //     toast.error("Error in update seller status!");
  //     console.error(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const getAllUser = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-all-user`
      );
      if (response?.status === 200) {
        const allUsers = response?.data?.data?.filter(
          (user) => !user?.is_admin
        );
        setUsers(allUsers);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const formatDateTime = (isoString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    return new Date(isoString).toLocaleString("en-US", options);
  };

  const handleUserDelete = async (userId) => {
    try {
      setLoading(true);
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/delete-user/${userId}`
      );

      if (response?.data?.status) {
        getAllUser();
        toast.success(t("USER_DELETED"));
      }
      console.log("response", response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      getAllUser();
    }
  };

  const handlePauseStatus = async (userId, status) => {
    const sendData = { userId, status };
    try {
      setLoading(true);
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/user/change-status`,
        sendData
      );
      if (response?.data?.status) {
        const message = response?.data?.message;
        toast.success(t(message));
        getAllUser();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="users-table">
      <h2>{t("USER_LIST")}</h2>
      {!showUserDetails && (
        <>
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>{t("NAME")}</th>

                  <th>{t("EMAIL")}</th>
                  <th>{t("ACCOUNT_STATUS")}</th>
                  <th>{t("SELLER_STATUS")}</th>
                  <th>{t("CREATED_AT")}</th>
                  <th>{t("ACTION")}</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td
                      className="hoverContent"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setShowUserDetails(true);
                        setSelectedId(user.id);
                      }}
                    >
                      {user.username}
                    </td>

                    <td>{user.email}</td>
                    <td
                      className={
                        user.isVerified ? "active_product" : "inactive_product"
                      }
                    >
                      {user.isVerified ? "Active" : "Inactive"}
                    </td>
                    <td
                      className={
                        user.isSeller ? "active_product" : "inactive_product"
                      }
                    >
                      {!user.isSellerCreated && !user.isSeller ? (
                        t("NOT_CREATED")
                      ) : user.isSeller ? (
                        "Active"
                      ) : (
                        <p style={{ color: "#feb906" }}>{t("CREATED")}</p>
                      )}
                    </td>
                    <td>{formatDateTime(user?.createdAt)}</td>
                    <td>
                      <div className="userActionContainer">
                        {/* <div
                          className={`actionBtn ${user?.isSeller ? "Approved" : "Pending"
                            }`}
                          disabled={user.isSeller}
                        >
                          {!user?.isVerified ? (
                            <>
                              <button className="infoBtn">
                                <img src={Info} alt="" />
                              </button>
                              <div className="infoText">
                                {t("PLEASE_VERIFY_ACCOUNT")}
                              </div>
                            </>
                          ) : !user?.isSellerCreated ? (
                            <>
                              <button className="infoBtn">
                                <img src={Info} alt="" />
                              </button>
                              <div className="infoText">
                                {t("SELLER_ACCOUNT_NOT_CREATED")}
                              </div>
                            </>
                          ) : user?.isSeller ? (
                            <>
                              <button className="infoBtn">
                                <img src={Approve} alt="" />
                              </button>
                              <div className="infoText">{t("SELLER_ACTIVE")}</div>
                            </>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                                gap: "20px",
                              }}
                            >
                              <div>
                                <button
                                  className="infoBtn"
                                  onClick={() => handleApprove(user?.id)}
                                >
                                  <img src={Check} alt="" />
                                </button>
                                <div className="infoText">{t("ACCEPT_SELLER")}</div>
                              </div>

                              <div>
                                <button className="infoBtn">
                                  <img src={Close} alt="" />

                                </button>
                                <div className="infoText">{t("REJECT_SELLER")}</div>
                              </div>
                            </div>
                          )}
                        </div> */}
                        <div>
                          {!user?.isPauseByAdmin ? (
                            <IoMdEye
                              style={{ color: "green" }}
                              size={25}
                              onClick={() =>
                                handlePauseStatus(user?.id, "pause")
                              }
                            />
                          ) : (
                            <IoIosEyeOff
                              style={{ color: "red" }}
                              onClick={() =>
                                handlePauseStatus(user?.id, "resume")
                              }
                              size={25}
                            />
                          )}
                        </div>
                        <div onClick={() => handleUserDelete(user?.id)}>
                          <MdDelete style={{ color: "gray" }} size={25} />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {loading && (
              <>
                <div className="loader-wrapper">
                  <div className="loader"></div>
                  <p>Loading...</p>
                </div>
              </>
            )}
          </div>
        </>
      )}
      {showUserDetails && (
        <UserDetails
          activeView={activeView}
          data={users}
          selectedId={selectedId}
          setShowUserDetails={setShowUserDetails}
        />
      )}
    </div>
  );
};

export default Users;
