import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Modal,
  Tab,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GoBell } from "react-icons/go";
import { IoIosSearch, IoMdArrowRoundBack } from "react-icons/io";
import { IoBagHandle, IoNotifications } from "react-icons/io5";
import { RiLoginBoxLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import Sidebar from "../SideBar/Siderbar";
import { RxCross2 } from "react-icons/rx";

import io from "socket.io-client";
import icons from "../../assets/images/svg/icons";

import Trendies from "../../assets/svg/trendies_logo.svg";
import { languageDataOption } from "../../constraints/Language";
import { fetchAllCartItems } from "../../redux/slices/cartSlice";
import { fetchAllNotifications } from "../../redux/slices/notificationSlice";
import {
  fetchFavoriteProduct,
  fetchUserById,
} from "../../redux/slices/userSlice";
import NotificationPanel from "../Modals/Notification/NotificationPanel";

import { updateGlobalChatCount } from "../../redux/slices/chatSlice";
import { resetFilters, updateFilters } from "../../redux/slices/filterSlice";
import { deleteOffersWithoutChatByUserId } from "../../redux/slices/offerSlice";
import FilterPopup from "../FilterPopup/FilterPopup";
import "./Header.scss";

const socket = io(process.env.REACT_APP_SOCKET_URL);

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { notifications } = useSelector((state) => state.notification);
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState("1");
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [activeButton, setActiveButton] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  // const [selectedGender, setSelectedGender] = useState("");
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 100000]);
  const globalChatCount = useSelector((state) => state.chat.globalChatCount);

  const filterRef = useRef(null);

  const [isMobile] = useState(window.innerWidth <= 1100);
  const [searchEnable, setSearchEnable] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({
    label: "Français",
    value: "fr",
    code: "FR",
  });

  // LOCAL STORAGE IMPORT DATA
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userId = userData?.id;

  // ICONS IMPORTED
  const { Add, Globe, Chat, HeartFill, Heart, Bag, User, Home, FilterIcon } =
    icons;

  const { cartItems } = useSelector((state) => state.cart);
  const favoriteCount = useSelector((state) => state.user.favoriteProducts);
  const updatedUserData = useSelector((state) => state.user.userData);

  // Function to handle offer accept details with interval
  const fetchOfferAcceptDetails = (userId, dispatch) => {
    const intervalId = setInterval(() => {
      socket.emit("getOfferAcceptDetails", { userId });

      socket.on("offerAcceptDetailsResponse", (data) => {
        const totalSellerUnseenCount = data?.sellerChat?.reduce(
          (total, seller) => total + (seller?.unseenCount || 0),
          0
        );
        const totalBuyerUnseenCount = data?.receiverChat?.reduce(
          (total, receiver) => total + (receiver?.unseenCount || 0),
          0
        );

        const totalMessageCount =
          totalBuyerUnseenCount + totalSellerUnseenCount;
        dispatch(updateGlobalChatCount(totalMessageCount));
      });

      socket.on("error", (error) => console.error("Socket error:", error));
    }, 1000);

    return intervalId;
  };

  useEffect(() => {
    if (userData?.id !== undefined) {
      const intervalId = fetchOfferAcceptDetails(userData.id, dispatch);

      return () => {
        clearInterval(intervalId);
        socket.off("offerAcceptDetailsResponse");
        socket.off("error");
      };
    }
  }, [dispatch, userData?.id]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleLanguageClick = () => {
    setIsOpen(!isOpen);
  };

  // HANDLE LANGUAGE CHANGE
  const handleLanguageChange = (selectedOption) => {
    if (selectedOption) {
      i18n.changeLanguage(selectedOption.value);
      setSelectedLanguage(selectedOption);
      setIsOpen(false);
    }
  };

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  const handleSearch = () => {
    setSearchEnable(true);
    if (searchTerm?.length > 0) {
      navigate(`/search-product/${searchTerm}`);
    } else {
      toast.error(t("EMPTY_SEARCH_INPUT"));
    }
  };

  useEffect(() => {
    if (token !== null || (undefined | "" && userId !== undefined)) {
      dispatch(fetchUserById());
      dispatch(fetchAllCartItems());
      if (userId) {
        dispatch(fetchFavoriteProduct(userId));
        dispatch(fetchAllNotifications(userId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleChatClicked = () => {
    dispatch(deleteOffersWithoutChatByUserId(userData?.id));
    navigate(userData?.id ? "/chat" : "/login");
  };

  const handleOpenModal = () => {
    if (!updatedUserData?.id) {
      navigate("/login");
      return;
    }

    if (updatedUserData?.isSeller) {
      navigate("/add-product");
    } else {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    if (!updatedUserData?.id) {
      navigate("/login");
    } else if (
      !updatedUserData?.isSeller &&
      !updatedUserData?.isSellerCreated
    ) {
      navigate("/seller-profile");
    }
  };

  const handleHomeClick = () => {
    dispatch(deleteOffersWithoutChatByUserId(userData?.id));
    navigate("/");
  };

  const buttonsConfig = [
    {
      key: "home",
      text: t("HOME"),
      icon: <Home />,
      path: "/",
      condition: true,
    },
    {
      key: "chat",
      text: t("CHAT"),
      icon: <Chat />,
      onClick: handleChatClicked,
      path: "/chat",
      condition: userData?.id,
      count: globalChatCount,
    },
    {
      key: "add-product",
      text: t("ADD_PRODUCT"),
      icon: <Add />,
      onClick: handleOpenModal, // Add onClick handler
      path: "/add-product", // Keep path for reference
      condition: true,
    },
    {
      key: "favorites",
      text: t("FAVORITE"),
      icon: favoriteCount.length > 0 ? <HeartFill /> : <Heart />,
      path: userData?.id ? "/favorites" : "/login",
      condition: true,
      count: favoriteCount.length,
    },
    {
      key: "cart",
      text: t("CART"),
      icon: cartItems?.length > 0 ? <IoBagHandle /> : <Bag />,
      path: userData?.id ? "/cart" : "/login",
      condition: true,
      count: cartItems?.length,
    },
  ];

  const iconsConfig = [
    {
      key: "chat",
      text: t("CHAT"),
      icon: <Chat />,
      onClick: handleChatClicked,
      condition: userData?.id,
      count: globalChatCount,
    },
    {
      key: "add-product",
      text: t("ADD_PRODUCT"),
      icon: <Add />,
      onClick: handleOpenModal,
      condition: true,
    },
    {
      key: "favorites",
      text: t("FAVORITE"),
      icon: favoriteCount.length > 0 ? <HeartFill /> : <Heart />,
      onClick: () => navigate(userData?.id ? "/favorites" : "/login"),
      condition: true,
      count: favoriteCount.length,
    },
    {
      key: "cart",
      text: t("CART"),
      icon: cartItems?.length > 0 ? <IoBagHandle /> : <Bag />,
      onClick: () => navigate(userData?.id ? "/cart" : "/login"),
      condition: true,
      count: cartItems?.length,
    },
    {
      key: "language",
      text: t("LANGUAGE"),
      icon: <Globe />,
      onClick: handleLanguageClick,
      condition: true,
    },
    {
      key: "notification",
      text: t("NOTIFICATION"),
      icon: notifications?.length > 0 ? <IoNotifications /> : <GoBell />,
      onClick: () => setIsNotification(true),
      condition: true,
      count: notifications?.length,
    },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      caretColor: "transparent",
    }),
  };

  const handlePriceRangeChange = (event) => {
    dispatch(updateFilters({ priceRange: event.target.value }));
    setPriceRange(event.target.value);
  };

  const handleReset = () => {
    dispatch(resetFilters());
    setPriceRange([0, 100000]);
    setSelectedBrands([]);
  };

  const handleFilterClick = () => {
    setShowFilterPopup(!showFilterPopup);
  };

  const handleCloseBrandFilter = () => {
    setShowFilterPopup(!showFilterPopup);
  };

  useEffect(() => {
    const excludedPaths = [
      "/cart",
      "/",
      "/favorites",
      "/payment/:id",
      "seller-profile",
      "/profile",
    ];

    if (!excludedPaths.includes(location.pathname)) {
      setSearchEnable(true);
    } else {
      setSearchEnable(false);
      setShowFilterPopup(false);
      setSearchTerm("");
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setShowFilterPopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <header className="header">
        <div className="container">
          <div onClick={handleHomeClick} className="themeBrand">
            <img src={Trendies} alt="" />
          </div>

          {!isMobile && (
            <div className="search-container">
              <div className="search-span-icon">
                <IoIosSearch onClick={handleSearch} className="icon" />
              </div>
              <input
                type="text"
                placeholder={t("SEARCH_PRODUCT")}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && handleSearch()}
                className="border px-4 py-2 rounded-md"
              />
              {searchEnable !== false && (
                <FilterIcon
                  className="ml-2 text-gray-500 cursor-pointer"
                  onClick={handleFilterClick}
                />
              )}

              <Box ref={filterRef} className="CatBtnOuterContainer">
                <FilterPopup
                  advanceFilter={true}
                  isOpen={showFilterPopup}
                  priceRange={priceRange}
                  selectedBrands={selectedBrands}
                  setPriceRange={setPriceRange}
                  onClose={handleCloseBrandFilter}
                  setSelectedBrands={setSelectedBrands}
                  onPriceRangeChange={handlePriceRangeChange}
                  handleReset={handleReset}
                />
              </Box>
            </div>
          )}

          <div className="header-icons">
            {iconsConfig
              .filter((icon) => icon.condition)
              .map((icon) => (
                <CustomTooltip
                  key={icon.key}
                  text={icon.text}
                  position="bottom"
                >
                  <Button
                    className={`btn border-btn d-sm-none ${icon.key === "language" ? "language_container" : ""
                      }`}
                    onClick={icon.onClick}
                  >
                    {icon.icon}
                    {icon.count > 0 && (
                      <span className="notification-count">{icon.count}</span>
                    )}
                  </Button>
                </CustomTooltip>
              ))}

            {isOpen && (
              <div className="select-dropdown">
                <Select
                  value={selectedLanguage}
                  options={languageDataOption}
                  onChange={handleLanguageChange}
                  placeholder="Select Language"
                  className="LanguageSelectContainer"
                  styles={customStyles}
                  onBlur={() => setIsOpen(false)}
                />
              </div>
            )}

            {userData?.id ? (
              <CustomTooltip text={t("PROFILE")} position="bottom">
                <button
                  className="profileBtn"
                  onClick={() => navigate("/profile")}
                >
                  {userData?.image ? (
                    <img
                      src={userData?.image}
                      className="profile-img"
                      alt={userData?.id}
                    />
                  ) : (
                    <User className="icon" />
                  )}
                </button>
              </CustomTooltip>
            ) : (
              <CustomTooltip text={t("LOGIN")} position="bottom">
                <button
                  onClick={() => navigate("/login")}
                  className="btn border-btn notification-btn"
                >
                  <RiLoginBoxLine />
                </button>
              </CustomTooltip>
            )}
          </div>

          <Sidebar isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />

          {isNotification && (
            <Dialog
              open={isNotification}
              keepMounted
              className="notification-dialog"
              onClose={() => setIsNotification(false)}
              PaperProps={{
                style: {
                  margin: "0",
                  maxWidth: "450px",
                  width: "90%",
                  position: "fixed",
                  top: "90px",
                  right: "30px",
                  borderRadius: "12px",
                  padding: "20px",
                  gap: "10px",
                },
              }}
            >
              <div className="dialog-header">
                <DialogTitle>Notification</DialogTitle>
                <IconButton
                  className="close-btn"
                  onClick={() => setIsNotification(false)}
                >
                  X
                </IconButton>
              </div>
              <Box className="Tabbing NotificationMainWrapper">
                <TabContext value={value}>
                  <TabList className="Tabbing__btn" onChange={handleChange}>
                    <Tab label={t("VIEW_ALL")} value="1" />
                    {/* <Tab label={t("MENTIONS")} value="2" /> */}
                  </TabList>
                  <NotificationPanel
                    notifications={notifications}
                    // getNotificationByUserId={getNotificationByUserId}
                    setIsNotification={setIsNotification}
                  />
                  <TabPanel value="2">{t("MENTIONS")}</TabPanel>
                </TabContext>
              </Box>
            </Dialog>
          )}
        </div>
      </header>

      <div style={{ zIndex: "99999" }} className="bottomMobileIcons">
        {location.pathname.startsWith("/chat") || location.pathname.startsWith("/payment") ? (
          // Render Back button for /chat path
          <div className="">
            {/* <Button
              className="btn border-btn"
              onClick={() => navigate(-1)} 
            >
              <IoMdArrowRoundBack className="btn btn-primary"/>
             
              <span>{t("BACK")}</span>
            </Button> */}

          </div>
        ) : (
          // Render other icons for non-chat paths
          <div className="bottomMobileIcons__inner">
            {buttonsConfig
              .filter((button) => button.condition)
              .map((button) => (
                <CustomTooltip
                  key={button.key}
                  text={button.text}
                  position="bottom"
                >
                  <button
                    className={`btn border-btn ${activeButton === button.key ? "active" : ""
                      }`}
                    onClick={() => {
                      setActiveButton(button.key);
                      if (button.onClick) {
                        button.onClick();
                      } else {
                        navigate(button.path);
                      }
                    }}
                  >
                    {button.icon}
                    {button.count > 0 && (
                      <span className="notification-count">{button.count}</span>
                    )}
                  </button>
                </CustomTooltip>
              ))}
          </div>
        )}
        <Modal
          disableBackdropClick
          open={openModal}
          onClose={(event, reason) => {
            if (reason === "backdropClick") return;
            handleCloseModal();
          }}
        >

          <Box
            className="modal-content"
            sx={{
              padding: 3,
              backgroundColor: "white",
              borderRadius: "8px",
              margin: "auto",
              textAlign: "center",
            }}
          >
            {userData?.isSellerCreated ? (
              <>
                <Box><RxCross2 /></Box>
                <Typography variant="h6">
                  {t("PLEASE_WAIT_FOR_ADMIN_APPROVAL")}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                  {t("YOUR_ACCOUNT_IS_NOT_APPROVED_AS_SELLER_YET")}
                </Typography>
              </>
            ) : userData?.isSeller ? (
              <>
                <Box><RxCross2 /></Box>
                <Typography variant="h6">
                  {t("YOUR_ACCOUNT_IS_UNDER_REVIEW")}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                  {t("PLEASE_WAIT_FOR_FINAL_APPROVAL")}
                </Typography>
              </>
            ) : (
              <>
                <Box style={{ display: "flex", justifyContent: "end", fontSize: "1.5 rem" }} onClick={() => setOpenModal(false)}><RxCross2 /></Box>
                <Typography variant="h6">
                  {t("SELLER_REGISTRATION_PENDING")}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                  {t("PLEASE_COMPLETE_YOUR_APPLICATION")}
                </Typography>
              </>
            )}
            <Button
              onClick={handleCloseModal}
              sx={{ marginTop: 3 }}
              variant="contained"
            >
              {t("OK")}
            </Button>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Header;
