import mark from "../assets/images/mark.jpg";
import icons from "../assets/images/svg/icons";
import { ReactComponent as YSL } from "../assets/svg/YSL.svg";

const {
  GucciLogo,
  LV,
  Prada,
  Hermes,
  Bulgari,
  Dior,
  Cartier,
  Rolex,
  ProductBox,
  BagIcon,
  ShoesIcon,
  AccessoriesIcon,
  JewelleryIcon,
  WatchIcon,
} = icons;

export const brandOptions = [
  { value: "gucci", label: "Gucci", icon: <GucciLogo /> },
  { value: "lv", label: "LV", icon: <LV /> },
  { value: "prada", label: "Prada", icon: <Prada /> },
  { value: "hermes ", label: "Hermes", icon: <Hermes /> },
  { value: "dior", label: "Dior", icon: <Dior /> },
  { value: "bulgari", label: "Bulgari", icon: <Bulgari /> },
  { value: "cartier", label: "Cartier", icon: <Cartier /> },
  { value: "rolex", label: "Rolex", icon: <Rolex /> },
  { value: "marc", label: "Marc Jacobs", icon: <img src={mark} alt="" /> },
  { value: "ysl", label: "YSL", icon: <YSL /> },
];

export const getCategoryOptions = (t) => [
  { value: "all", label: t("SUB_CATEGORY.ALL"), icon: <ProductBox /> },
  { value: "watches", label: t("SUB_CATEGORY.WATCHES"), icon: <WatchIcon /> },
  {
    value: "jewelry",
    label: t("SUB_CATEGORY.JEWELRY"),
    icon: <JewelleryIcon />,
  },
  { value: "bags", label: t("SUB_CATEGORY.BAGS"), icon: <BagIcon /> },
  { value: "shoes", label: t("SUB_CATEGORY.SHOES"), icon: <ShoesIcon /> },
  {
    value: "accessories",
    label: t("SUB_CATEGORY.ACCESSORIES"),
    icon: <AccessoriesIcon />,
  },
];
