import { Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { ReactComponent as DismissCircle } from "../../assets/svg/DismissCircle.svg";
import { ReactComponent as UploadIcon } from "../../assets/svg/UploadIcon.svg";
import { brandOptions } from "../../constraints/Brand";
import { categoryOptions } from "../../constraints/Category";
import { genderOption } from "../../constraints/Gender";
import { updateProductById } from "../../redux/slices/productSlice";
import "./EditProductModal.scss";

const EditProductModal = ({
  isOpen,
  onClose,
  product,
  refreshProducts,
  setGenderValue,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    title: product?.title || "",
    description: product?.description || "",
    price: product?.price || 0,
    brand: product?.brand || "",
    gender: product?.gender || "",
    category: product?.category || "",
    productImage: product?.productImage || [],
    document: "",
  });
  let fileInputRef = useRef(null);

  const [errors, setErrors] = useState({
    title: "",
    description: "",
    price: "",
    brand: "",
    gender: "",
    category: "",
    productImage: "",
  });

  const handleDocumentChange = (e) => {
    const { files } = e.target;
    if (files.length === 0) {
      setErrors({ ...errors, document: t("NO_DOCUMENT_SELECTED") });
      return;
    }

    const file = files[0]; // Get the first file
    const validTypes = ["application/pdf"];
    if (!validTypes.includes(file.type)) {
      setErrors({ ...errors, document: t("ONLY_PDF_ALLOWED") });
      return;
    }

    setFormData((prev) => ({
      ...prev,
      document: file,
    }));
    setErrors({ ...errors, document: "" });
  };

  useEffect(() => {
    if (isOpen && product) {
      setFormData({
        title: product.title,
        description: product.description,
        price: product.price,
        brand: product.brand,
        gender: product.gender,
        category: product.category,
        productImage: product.productImage || [],
        document: "",
      });
      setErrors({
        title: "",
        description: "",
        price: "",
        brand: "",
        gender: "",
        category: "",
        productImage: "",
      });
    }
  }, [isOpen, product]);

  const validateField = (name, value) => {
    switch (name) {
      case "title":
        return !value ? t("PLEASE_ENTER_A_TITLE") : "";
      case "description":
        return !value ? t("PLEASE_ENTER_A_DESCRIPTION") : "";
      case "price":
        return !value
          ? t("PLEASE_ENTER_A_PRICE")
          : isNaN(value)
          ? t("PLEASE_ENTER_A_VALID_PRICE")
          : value < 1000
          ? t("PLEASE_ENTER_A_PRICE_GREATER_THAN_1000")
          : "";
      case "brand":
        return !value ? t("PLEASE_SELECT_A_BRAND") : "";
      case "gender":
        return !value ? t("PLEASE_SELECT_A_GENDER") : "";
      case "category":
        return !value ? t("PLEASE_SELECT_A_CATEGORY") : "";
      case "productImage":
        return !value.length
          ? t("PLEASE_UPLOAD_AT_LEAST_ONE_IMAGE")
          : value.length !== 6
          ? t("EXACTLY_6_IMAGES_REQUIRED")
          : "";
      default:
        return "";
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: validateField(name, value) });
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    if (!files || files.length === 0) {
      setErrors({ ...errors, productImage: t("NO_FILES_SELECTED") });
      return;
    }

    const validTypes = ["image/jpeg", "image/png", "image/gif"];
    const validImages = Array.from(files).filter((file) =>
      validTypes.includes(file.type)
    );

    const newImages = [...formData.productImage, ...validImages];
    if (newImages.length > 6) {
      setErrors({ ...errors, productImage: t("MAXIMUM_6_IMAGES_ALLOWED") });
      return;
    }

    setFormData((prev) => ({
      ...prev,
      productImage: newImages,
    }));
    setErrors({
      ...errors,
      productImage: validateField("productImage", newImages),
    });
  };

  const handleRemoveImage = (index) => {
    const newImages = formData.productImage.filter((_, i) => i !== index);
    setFormData((prev) => ({
      ...prev,
      productImage: newImages,
    }));
    setErrors({
      ...errors,
      productImage: validateField("productImage", newImages),
    });
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      newErrors[key] = validateField(key, formData[key]);
    });
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => !error);
  };

  const handleEditProduct = async () => {
    if (!validateForm()) return;

    try {
      const EditedData = new FormData();
      formData.productImage.forEach((ele) => {
        if (typeof ele === "string" && ele.startsWith("http")) {
          EditedData.append("oldImage", ele);
        }
      });

      EditedData.append("title", formData.title);
      EditedData.append("description", formData.description);
      EditedData.append("price", formData.price);
      EditedData.append("brand", formData.brand);
      EditedData.append("gender", formData.gender);
      EditedData.append("category", formData.category);

      formData.productImage.forEach((file) => {
        EditedData.append("productImage", file);
      });

      const response = await dispatch(
        updateProductById({
          productId: product.id,
          productData: EditedData,
        })
      );

      if (response?.payload?.statusCode === 200) {
        setGenderValue(formData?.gender);
        refreshProducts();
        onClose();
      }
    } catch (error) {
      setErrors({ ...errors, submit: t("ERROR_IN_EDIT_PRODUCT") });
    }
  };

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "unset";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay EditProductModal" onClick={onClose}>
      <div className="modalcontent" onClick={(e) => e.stopPropagation()}>
        <h2>{t("EDIT_PRODUCT")}</h2>

        <div className="form-row">
          <div className="form-group">
            <label>{t("TITLE")}</label>
            <input
              type="text"
              name="title"
              placeholder={t("ENTER_TITLE")}
              value={formData.title}
              onChange={handleInputChange}
              className={errors.title ? "error" : ""}
            />
            {errors.title && (
              <span style={{ color: "red" }} className="error-message">
                {errors.title}
              </span>
            )}
          </div>

          <div className="form-group">
            <label>{t("PRICE")}</label>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleInputChange}
              className={errors.price ? "error" : ""}
            />
            {errors.price && (
              <span style={{ color: "red" }} className="error-message">
                {errors.price}
              </span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label>{t("DESCRIPTION")}</label>
          <textarea
            name="description"
            placeholder={t("ENTER_DESCRIPTION")}
            value={formData.description}
            onChange={handleInputChange}
            className={errors.description ? "error" : ""}
          />
          {errors.description && (
            <span style={{ color: "red" }} className="error-message">
              {errors.description}
            </span>
          )}
        </div>

        <div className="form-row-three">
          <div className="form-group">
            <label>{t("BRAND")}</label>
            <Select
              className={`customSelect ${errors.brand ? "error" : ""}`}
              name="brand"
              options={brandOptions}
              value={brandOptions.find(
                (option) => option.value === formData.brand
              )}
              onChange={(selectedOption) => {
                setFormData({ ...formData, brand: selectedOption.value });
                setErrors({ ...errors, brand: "" });
              }}
              placeholder={t("SELECT_A_BRAND")}
            />
            {errors.brand && (
              <span style={{ color: "red" }} className="error-message">
                {errors.brand}
              </span>
            )}
          </div>

          <div className="form-group">
            <label>{t("CATEGORY")}</label>
            <Select
              className={`customSelect ${errors.category ? "error" : ""}`}
              name="category"
              options={categoryOptions.map((option) => ({
                value: t(option.value),
                label: t(option.label),
              }))}
              value={{
                value: t(formData.category),
                label: t(
                  categoryOptions.find(
                    (option) => option.value === formData.category
                  )?.label
                ),
              }}
              onChange={(selectedOption) => {
                setFormData({ ...formData, category: selectedOption.value });
                setErrors({ ...errors, category: "" });
              }}
              placeholder={t("SELECT_A_CATEGORY")}
            />
            {errors.category && (
              <span style={{ color: "red" }} className="error-message">
                {errors.category}
              </span>
            )}
          </div>

          <div className="form-group">
            <label>{t("GENDER")}</label>
            <Select
              className={`customSelect ${errors.gender ? "error" : ""}`}
              name="gender"
              options={genderOption.map((option) => ({
                value: option.value,
                label: t(option.label),
              }))}
              value={{
                value: formData.gender,
                label: t(
                  genderOption.find(
                    (option) => option.value === formData.gender
                  )?.label
                ),
              }}
              onChange={(selectedOption) => {
                setFormData({ ...formData, gender: selectedOption.value });
                setErrors({ ...errors, gender: "" });
              }}
              placeholder={t("SELECT_A_GENDER")}
            />
            {errors.gender && (
              <span style={{ color: "red" }} className="error-message">
                {errors.gender}
              </span>
            )}
          </div>
        </div>

        <div className="ImageUpload file-preview">
          <div className="inputUpload">
            <input
              type="file"
              name="document"
              accept="application/pdf"
              onChange={handleDocumentChange}
              className={errors.document ? "error" : ""}
              ref={(ref) => (fileInputRef = ref)}
            />
            <UploadIcon />
            <p>{t("PHOTOS_OF_AUTHENTICATION_DOCUMENTS")} (.pdf)</p>
          </div>
          {errors.document && (
            <span style={{ color: "red" }} className="error-message">
              {errors.document}
            </span>
          )}
          {formData.document && (
            <div className="imgPreview">
              <div className="imgPreview__inner">
                <embed
                  src={URL.createObjectURL(formData.document)}
                  type="application/pdf"
                  style={{ width: "100%", height: "400px" }}
                />
                <button
                  className="remove-image"
                  onClick={() => {
                    setFormData({ ...formData, document: null });
                    if (fileInputRef.current) fileInputRef.current.value = "";
                  }}
                >
                  <DismissCircle />
                </button>
              </div>
              <div className="imgPreview__content">
                <p>{t("DOCUMENT_PREVIEW")}</p>
              </div>
            </div>
          )}
        </div>
        <div className="ImageUpload file-preview">
          <div className="inputUpload">
            <input
              type="file"
              name="images"
              multiple
              accept="image/*"
              onChange={handleFileChange}
              className={errors.productImage ? "error" : ""}
            />
            <UploadIcon />
            <p>{t("PHOTOS_OF_PRODUCT")} (Max 6)</p>
          </div>
          {errors.productImage && (
            <span style={{ color: "red" }} className="error-message">
              {errors.productImage}
            </span>
          )}
          <div className="uploadedImg">
            {formData?.productImage?.map((fileUrl, index) => (
              <div className="imgPreview" key={index}>
                <div className="imgPreview__inner">
                  <img
                    src={
                      typeof fileUrl === "string"
                        ? fileUrl
                        : URL.createObjectURL(fileUrl)
                    }
                    alt={t("UPLOADED_IMAGE")}
                  />
                  <button
                    className="remove-image"
                    onClick={() => handleRemoveImage(index)}
                  >
                    <DismissCircle />
                  </button>
                </div>
                <div className="imgPreview__content">
                  <p>{`Image ${index + 1}`}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {errors.submit && (
          <div className="error-message submit-error">{errors.submit}</div>
        )}

        <div className="modal-actions btnPart">
          <Button className="btn_dark" onClick={handleEditProduct}>
            {t("SAVE")}
          </Button>
          <Button
            className="btn_dark"
            onClick={() => {
              onClose();
              refreshProducts();
            }}
          >
            {t("CLOSE")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditProductModal;
