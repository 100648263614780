import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { offerThunk } from "../../../redux/slices/offerSlice";
import "./OfferResponse.scss";

const AcceptOffer = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const endpoint = "offer-accept";

  useEffect(() => {
    const handleOffer = async () => {
      try {
        setLoading(true);
        const response = await dispatch(
          offerThunk({ endpoint, token })
        ).unwrap();

        setMessage(t(response.message) || t("OFFER_ACCEPT_SUCCESS"));
        toast.success(t(response.message) || t("OFFER_ACCEPT_SUCCESS"));
        setLoading(false);

        setTimeout(() => {
          navigate("/chat");
        }, 1000);
      } catch (error) {
        setMessage(t(error) || t("OFFER_ACCEPT_FAILED"));
        toast.error(t(error) || t("OFFER_ACCEPT_FAILED"));
        setLoading(false);
        setTimeout(() => {
          navigate("/chat");
        }, 1000);
      }
    };

    handleOffer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, navigate, dispatch]);

  return (
    <div className="offer-response">
      {loading ? (
        <div className="loading-container">
          <div className="loading-message">
            <span className="loading-title">{t("ACCEPTING_OFFER")}</span>
            <div className="loading-dots">
              <span className="loading">.</span>
              <span className="loading">.</span>
              <span className="loading">.</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="response-container">
          <p className="response-message">{message}</p>
          <p className="redirect-message">{t("REDIRECT_TO_HOME_PAGE")}</p>
        </div>
      )}
    </div>
  );
};

export default AcceptOffer;
