import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export function authHeader() {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
}

// GET ALL PRODUCT
async function getAllProduct(params = {}) {
  try {
    const { data } = await axios.get(`${baseURL}/products`, {
      headers: {
        ...authHeader(),
        "Content-Type": "application/json",
      },
      params,
    });

    if (!data.status) {
      throw new Error(data.message || "Failed to fetch product");
    }
    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
        e.response?.data?.Error ||
        e.message ||
        "An error occurred while fetching product"
    );
  }
}

// GET SOLD PRODUCT
async function getSoldProduct() {
  try {
    const { data } = await axios.get(`${baseURL}/products`, {
      headers: {
        ...authHeader(),
        "Content-Type": "application/json",
      },
    });

    if (!data.status) {
      throw new Error(data.message || "Failed to fetch product");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
        e.response?.data?.Error ||
        e.message ||
        "An error occurred while fetching product"
    );
  }
}

// GET PRODUCT BY ID
async function getProductById(productId) {
  try {
    const { data } = await axios.get(`${baseURL}/products/${productId}`, {
      headers: {
        ...authHeader(),
        "Content-Type": "multipart/form-data",
      },
    });

    if (!data.status) {
      throw new Error(data.message || "Failed to fetch product");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
        e.response?.data?.Error ||
        e.message ||
        "An error occurred while fetching product"
    );
  }
}

// CREATE PRODUCT
async function createProduct(productData) {
  try {
    const { data } = await axios.post(`${baseURL}/products`, productData, {
      headers: {
        ...authHeader(),
        "Content-Type": "multipart/form-data",
      },
    });

    if (!data.status) {
      throw new Error(data.message || "Failed to create product");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
        e.response?.data?.Error ||
        e.message ||
        "An error occurred while creating product"
    );
  }
}

// EDIT PRODUCT BY ID
async function editProductById(productId, productData) {
  try {
    const { data } = await axios.put(
      `${baseURL}/products/${productId}`,
      productData,
      {
        headers: {
          ...authHeader(),
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (!data.status) {
      throw new Error(data.message || "Failed to update product");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
        e.response?.data?.Error ||
        e.message ||
        "An error occurred while updating product"
    );
  }
}

// DELETE PRODUCT BY ID
async function deleteProductById(productId) {
  try {
    const { data } = await axios.delete(`${baseURL}/products/${productId}`, {
      headers: authHeader(),
    });

    if (!data.status) {
      throw new Error(data.message || "Failed to delete product");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
        e.response?.data?.Error ||
        e.message ||
        "An error occurred while deleting product"
    );
  }
}

// VERIFY PRODUCT BY ID
async function verifyProductById(productId) {
  try {
    const { data } = await axios.get(
      `${baseURL}/product/verify-product/${productId}`,
      {
        headers: authHeader(),
      }
    );

    if (!data.status) {
      throw new Error(data.message || "Product verification failed");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
        e.response?.data?.Error ||
        e.message ||
        "An error occurred during product verification"
    );
  }
}

// GET TIME SLOTS BY PRODUCT ID
async function getTimeSlotsByProductId(productId) {
  try {
    const { data } = await axios.get(
      `${baseURL}/product-timeslot/${productId}`,
      {
        headers: authHeader(),
      }
    );

    if (!data.status) {
      throw new Error(data.message || "Failed to fetch product time slots");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
        e.response?.data?.Error ||
        e.message ||
        "An error occurred while fetching product time slots"
    );
  }
}

const rejectProductStatus = async (data) => {
  try {
    const response = await axios.post(
      `${baseURL}/product/change-status`,
      data,
      {
        headers: {
          ...authHeader(),
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error(response.data.message || "Failed to reject product");
    }

    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message ||
        error.response?.data?.Error ||
        error.message ||
        "An error occurred while rejecting the product"
    );
  }
};

// EXPORT PRODUCT SERVICE
const ProductService = {
  getAllProduct,
  createProduct,
  getProductById,
  getSoldProduct,
  editProductById,
  deleteProductById,
  verifyProductById,
  rejectProductStatus,
  getTimeSlotsByProductId,
};

export default ProductService;
