import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./SoldProductList.scss";
import ProductDetailsModel from "../ProductDetails/ProductDetailsModel";

const SoldProductsList = ({ soldProducts, t, soldProductLoading }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCloseModal = () => setIsModalOpen(false);
  const [currentProduct, setCurrentProduct] = useState(null)

  return (
    <div className="sold-products-container">
      {soldProducts && soldProducts.length > 0 ? (
        <div className="product-grid">
          {soldProductLoading
            ? "Loading..."
            : soldProducts
              ?.filter((ele) => ele?.status)
              .map((item) => (
                <div key={item.id} className="sold-product-card">
                  <Link
                    style={{ textDecoration: "none" }}
                    to={
                      !item.paymentStatus
                        ? `/payment/${item?.productId}`
                        : undefined
                    }
                  >
                    <div onClick={() => { setCurrentProduct(item); setIsModalOpen(true); }} className="product-header">
                      <div className="product-image">
                        {/* <span
                            className={`payment-status ${
                              item.paymentStatus ? "paid" : "pending"
                            }`}
                          >
                            {item.paymentStatus ? t("PAID") : t("PENDING")}
                          </span> */}
                        <img
                          src={item.product?.productImage?.[0]}
                          alt={item.product?.title}
                        />
                      </div>
                      <div className="product-details">
                        <p style={{ color: "black" }} className="total-price">
                          {item.amountCalculated?.total.toFixed(2)} MAD
                        </p>
                        <h3>{item.product?.title}</h3>
                        <p style={{ color: "black" }}>
                          {item.product?.brand} | {item.product?.category}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
        </div>
      ) : (
        <p>{t("NO_PURCHASE_HISTORY")}</p>
      )}

      <ProductDetailsModel isOpen={isModalOpen}
        onClose={handleCloseModal}
        currentProductSellerBuyerDetails={currentProduct} />
    </div>
  );
};

export default SoldProductsList;
