import axios from "axios";

const baseURL = process.env.REACT_APP_BACKEND_URL;
//REACT_APP_FILE_URL=http://localhost:5000
const fileURL = process.env.REACT_APP_FILE_URL;

export function authHeader() {
  const token = localStorage.getItem("token");
  return { authorization: token || "" };
}

async function updateSeenStatus(senderId, productId) {
  try {
    const { data } = await axios.put(
      `${baseURL}/messages/update-seen-status`,
      {
        senderId,
        productId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          ...authHeader(),
        },
      }
    );

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
        e.response?.data?.Error ||
        e.message ||
        "An error occurred while updating seen status"
    );
  }
}

async function getUserOfferList(senderId) {
  try {
    const { data } = await axios.get(
      `${baseURL}/get-user-offer-list/${senderId}`,
      {
        headers: {
          "Content-Type": "application/json",
          ...authHeader(),
        },
      }
    );

    if (!data.status) {
      throw new Error(data.message || "Failed to fetch user offers");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
        e.response?.data?.Error ||
        e.message ||
        "An error occurred while fetching the offer list"
    );
  }
}

async function isChatInitiated(userId, productId) {
  try {
    const response = await axios.get(`${baseURL}/messages/status`, {
      params: {
        userId,
        productId,
      },
      // headers: {
      //   "Content-Type": "application/json",
      //   ...authHeader(),
      // }
    });

    return response?.data?.status;
  } catch (error) {
    throw new Error(
      error.response?.data?.message ||
        error.response?.data?.Error ||
        error.message ||
        "An error occurred while checking chat status"
    );
  }
}

async function getUnseenCount(receiverId, productId, senderId) {
  try {
    const { data } = await axios.get(`${baseURL}/messages/unseen-count`, {
      params: {
        receiverId,
        productId,
        senderId,
      },
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
        e.response?.data?.Error ||
        e.message ||
        "An error occurred while fetching unseen count"
    );
  }
}

async function uploadFile(formData) {
  try {
    const { data } = await axios.post(`${fileURL}/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    });

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
        e.response?.data?.Error ||
        e.message ||
        "An error occurred while uploading the file"
    );
  }
}

// EXPORT CART SERVICE
const CartService = {
  updateSeenStatus,
  getUserOfferList,
  getUnseenCount,
  isChatInitiated,
  uploadFile,
};

export default CartService;
