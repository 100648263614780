import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  declineUserAccount,
  verifyEmail,
} from "../../../redux/slices/authSlice";
import "./AuthResponse.scss";

// Email Verification Component
const VerifyEmail = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const verifyUserEmail = async () => {
      try {
        setLoading(true);
        // eslint-disable-next-line no-unused-vars
        const response = await dispatch(verifyEmail(token)).unwrap();
        setMessage(t("EMAIL_VERIFIED_SUCCESSFULLY"));
        toast.success(t("EMAIL_VERIFIED_SUCCESSFULLY"));
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } catch (error) {
        setMessage(t("VERIFICATION_FAILED"));
        toast.error(t("VERIFICATION_FAILED"));
        setLoading(false);
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      }
    };
    verifyUserEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, navigate, dispatch]);

  return (
    <div className="auth-response">
      {loading ? (
        <div className="loading-container">
          <div className="loading-message">
            <span className="loading-title">{t("VERIFY_ACCOUNT")}</span>
            <div className="loading-dots">
              <span className="loading">.</span>
              <span className="loading">.</span>
              <span className="loading">.</span>
            </div>
          </div>
        </div>
      ) : (
        <p className="response-message">{message}</p>
      )}
    </div>
  );
};

// Account Decline Component
const DeclineAccount = () => {
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const declineUserAccounts = async () => {
      try {
        setLoading(true);
        const response = await dispatch(
          declineUserAccount({ endpoint: "decline", token })
        ).unwrap();
        setMessage(response.msg || "Account decline successful");
        toast.success(response.msg || "Account decline successful");
        setLoading(false);
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } catch (error) {
        setMessage(error || "Decline process failed");
        toast.error(error || "Decline process failed");
        setLoading(false);
      }
    };
    declineUserAccounts();
  }, [token, navigate, dispatch]);

  return (
    <div className="auth-response">
      {loading ? <p>Loading...</p> : <p>{message}</p>}
    </div>
  );
};

export { DeclineAccount, VerifyEmail };
