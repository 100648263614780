import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ReactComponent as Send } from "../../../assets/svg/Send.svg";
import { useSensitivePatterns } from "../Constants";

const validateMessage = (message, patterns) => {
  if (!message?.trim()) return { isValid: true };
  
  const normalizedMessage = `\u200e${message}\u200e`;
  
  // Handle both original and normalized message
  for (const { pattern, message: errorMessage } of patterns) {
    try {
      if (pattern.global) pattern.lastIndex = 0;
      
      // Test both original and normalized versions
      if (pattern.test(normalizedMessage) || pattern.test(message)) {
        return {
          isValid: false,
          message: errorMessage
        };
      }
    } catch (error) {
      console.error('Pattern matching error:', error);
    }
  }

  return { isValid: true };
};
const ChatInput = ({ input, setInput, receiverId }) => {
  const { t } = useTranslation();

  return (
    <input
      type="text"
      value={input}
      onChange={(e) => setInput(e.target.value)}
      placeholder={t("PLEASE_TYPE_HERE")}
      className="message-input"
      disabled={!receiverId}
    />
  );
};

const SubmitButton = ({ input, selectedFiles, receiverId }) => (
  <button
    type="submit"
    className={`btn \${
      ((!input?.trim() && !selectedFiles?.length) || !receiverId) &&
      "disable-btn"
    }`}
    disabled={(!input?.trim() && !selectedFiles?.length) || !receiverId}
  >
    <Send />
  </button>
);

const ChatForm = ({
  input,
  setInput,
  handleSubmit,
  selectedFiles,
  receiverId,
}) => {
  const sensitivePatterns = useSensitivePatterns();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const validation = validateMessage(input, sensitivePatterns);

    if (!validation.isValid) {
      toast.error(validation.message);
      return;
    }
    handleSubmit(e);
  };

  return (
    <form className="ChatInputContainer" onSubmit={handleFormSubmit}>
      <ChatInput input={input} setInput={setInput} receiverId={receiverId} />
      <Box className="ChatInputBtnContainer">
        <SubmitButton
          input={input}
          selectedFiles={selectedFiles}
          receiverId={receiverId}
        />
      </Box>
    </form>
  );
};

export default ChatForm;