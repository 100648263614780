import { GrView } from "react-icons/gr";
import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ViewProductImageModal from "./ViewProductImageModal";
import ProductVerificationModal from "./ProductVerificationModal";
import {
  fetchAllProduct,
  rejectProduct,
  verifyProductByIdThunk,
} from "../../../redux/slices/productSlice";
import { useDispatch } from "react-redux";
import "./ProductTable.scss";
import "./Products.scss"
import { MdDelete } from "react-icons/md";
import axios from "axios";
import UserDetails from "../UserDetails/UserDetails";


const Products = ({ activeView }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentProductId, setCurrentProductId] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [currentSelectedProduct, setCurrentSelectedProduct] = useState({})
  const [loading, setLoading] = useState(false)
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  // Fetch products
  const getProducts = useCallback(async () => {
    try {
      setLoading(true)
      const response = await dispatch(fetchAllProduct());
      const productsList = response?.payload?.data || [];
      setProducts(productsList.filter((product) => product?.id));
    } catch (error) {
      console.error("Failed to fetch products:", error);
      toast.error(t("Failed to load products"));
    } finally {
      setLoading(false)
    }
  }, [dispatch, t]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  // Handle product verification actions
  const handleVerificationAction = async (actionType) => {
    const thunkAction =
      actionType === "accept"
        ? verifyProductByIdThunk(currentProductId)
        : rejectProduct({ productId: currentProductId, status: "reject" });

    try {
      setLoading(true)
      const response = await dispatch(thunkAction);
      if (response?.payload?.statusCode === 200) {
        toast.success(
          t(
            actionType === "accept"
              ? "PRODUCT_VERIFIED"
              : "PRODUCT_REJECTED_SUCCESS"
          )
        );
        getProducts();
      } else {
        throw new Error();
      }
    } catch {
      toast.error(
        t(
          actionType === "accept"
            ? "PRODUCT_VERIFICATION_FAILED"
            : "PRODUCT_REJECTED_FAILED"
        )
      );
    } finally {
      setLoading(false)
      setModalOpen(false);
    }
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setIsImageModalOpen(true);
  };

  const formatDateTime = (isoString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    return new Date(isoString).toLocaleString("en-US", options);
  };

  const handleProductDelete = async (productId) => {
    try {
      setLoading(true)
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/products/${productId}`
      );

      if (response?.data?.status) {
        getProducts();
        toast.success(t("PRODUCT_DELETED"))
      }
      console.log("response", response)
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }

  console.log("products", products)

  return (
    <div className="users-table">
      <h2>{t("PRODUCT_LIST")}</h2>
      {!showUserDetails && (
        <>
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>{t("NAME")}</th>

                  <th>{t("CATEGORY")}</th>
                  <th>{t("BRAND")}</th>
                  <th>{t("GENDER")}</th>
                  <th>{t("PRICE")}</th>
                  <th>{t("DOCUMENT")}</th>
                  <th>{t("PRODUCT_IMAGE")}</th>
                  <th>{t("STATUS")}</th>
                  <th>{t("CREATED_AT")}</th>
                  <th>{t("ACTION")}</th>
                </tr>
              </thead>
              <tbody>
                {products?.length > 0 ? (
                  products.map((product, index) => (
                    <tr key={product.id}>
                      <td>{product.id}</td>
                      <td className="hoverContent" onClick={() => {
                        setShowUserDetails(true);
                        setSelectedId(product.id);
                      }} style={{ cursor: "pointer" }}>
                        {product.title}
                      </td>

                      <td>{product.category}</td>
                      <td>{product.brand}</td>
                      <td>{product.gender}</td>
                      <td>{product.price}</td>
                      <td className="document-cell">
                        <a
                          href={product.document}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="document-link"
                        >
                          {t("VIEW_DOCUMENT")}
                        </a>
                      </td>
                      <td>
                        <div
                          className="image-preview"
                          onClick={() => handleImageClick(index)}
                        >
                          <GrView />
                          <span>{t("VIEW_IMAGE")}</span>
                          {product.productImage[0] && (
                            <div className="hover-preview">
                              <img src={product.productImage[0]} alt="Preview" />
                            </div>
                          )}
                        </div>
                      </td>
                      <td
                        className={
                          product.isVerified ? "active_product" : "inactive_product"
                        }
                      >
                        {product.isVerified ? t("VERIFIED") : t("NEED_TO_VERIFY")}
                      </td>
                      <td>{formatDateTime(product?.createdAt)}</td>
                      <td>
                        <div className="productActionContainer">
                          {(
                            <div className="product_action">
                              <GrView
                                className={`common_product ${product.isVerified
                                  ? "verified_product"
                                  : "not_verified_product"
                                  }`}
                                onClick={() => {
                                  setCurrentSelectedProduct(product);
                                  setCurrentProductId(product.id);
                                  setModalOpen(true);
                                }}
                              />
                            </div>
                          )}

                          <div className="productActionContainer_Delete" onClick={() => handleProductDelete(product?.id)}>
                            <MdDelete size={25} />
                          </div>

                        </div>

                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10">{t("NO_PRODUCTS_FOUND")}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {
            loading && (
              <>
                <div className="loader-wrapper">
                  <div className="loader"></div>
                  <p>Loading...</p>
                </div>
              </>
            )
          }
        </>
      )}

      <ProductVerificationModal
        isOpen={isModalOpen}
        onAccept={() => handleVerificationAction("accept")}
        onReject={() => handleVerificationAction("reject")}
        onCancel={() => setModalOpen(false)}
        currentSelectedProduct={currentSelectedProduct}
      />
      <ViewProductImageModal
        isOpen={isImageModalOpen}
        onClose={() => setIsImageModalOpen(false)}
        images={products[selectedImageIndex]?.productImage}
        selectedImageIndex={selectedImageIndex}
      />

      {
        showUserDetails && (
          <UserDetails
            activeView={activeView}
            data={products}
            selectedId={selectedId}
            setShowUserDetails={setShowUserDetails}
          />
        )
      }
    </div >
  );
};

export default Products;
