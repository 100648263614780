import { createSlice } from '@reduxjs/toolkit';

const filterDataSlice = createSlice({
 name: 'filterData',
 initialState: {
  sortBy: 'createdAt',
  sortOrder: 'DESC',
  priceRange: {
   min: '',
   max: ''
  },
  category: '',
  condition: '',
  brand: []
 },
 reducers: {
  updateFilters: (state, action) => {
   Object.keys(action.payload).forEach(key => {
    state[key] = action.payload[key];
   });
  },
  resetFilters: (state) => {
   state.sortBy = 'createdAt';
   state.sortOrder = 'DESC';
   state.priceRange = { min: '', max: '' };
   state.category = '';
   state.condition = '';
   state.brand = [];
  }
 }
});

export const { updateFilters, resetFilters } = filterDataSlice.actions;
export default filterDataSlice.reducer;

