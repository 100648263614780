import i18n from "i18next";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { ReactComponent as Accessories } from "../../../assets/svg/accessories.svg";
import { ReactComponent as Bag } from "../../../assets/svg/bag.svg";
import { ReactComponent as Jewelry } from "../../../assets/svg/jewelry.svg";
import { ReactComponent as Shoes } from "../../../assets/svg/shoes.svg";
import { ReactComponent as Watch } from "../../../assets/svg/watch.svg";

import { ReactComponent as Dior } from "../../../assets/svg/dior.svg";
import { ReactComponent as Enfants } from "../../../assets/svg/Enfants.svg";
import { ReactComponent as Female } from "../../../assets/svg/Female.svg";
import { ReactComponent as Gucci } from "../../../assets/svg/gucci.svg";
import { ReactComponent as Hermes } from "../../../assets/svg/hermes.svg";
import { ReactComponent as LV } from "../../../assets/svg/lv.svg";
import { ReactComponent as Male } from "../../../assets/svg/Male.svg";
import { ReactComponent as Prada } from "../../../assets/svg/prada.svg";
import { ReactComponent as YSL } from "../../../assets/svg/YSL.svg";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as DismissCircle } from "../../../assets/svg/DismissCircle.svg";
import { ReactComponent as UploadIcon } from "../../../assets/svg/UploadIcon.svg";
import "./AddProduct.scss";

import { Box, Button } from "@mui/material";

import { useDispatch } from "react-redux";
import ImageUpload from "../../../component/ImageUploader/ImageUploader";
import InvoiceBreakdown from "../../../component/InvoiceBreakdown/InvoiceBreakdown";
import SectionHeader from "../../../component/SectionHeader/SectionHeader";
import { createProduct } from "../../../redux/slices/productSlice";
import { calculateFees } from "../PurchaseProduct/utils/FeeCalculator";

import enReferenceImg from "../../../assets/images/png/reference_en.png";
import frReferenceImg from "../../../assets/images/png/reference_fr.png";

const AddProduct = () => {
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    category: null,
    brand: null,
    title: "",
    price: "",
    description: "",
    pictureOfItem: [],
    document: "",
    serialNumber: `SN-${Date.now()}`,
    gender: null,
  });

  const handleButtonClick = () => {
    setIsButtonClicked(true);
  };

  const currentLanguage = i18n.language;
  const [authImage, setAuthImage] = useState(false);
  // const [fileItems, setFileItems] = useState([]);
  // const [showFileItems, setShowFileItems] = useState(false);
  const [multipleImage, setMultipleImage] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  // const [imageUpload, setImageUpload] = useState(false);
  let fileInputRef = useRef(null);

  const categoryOptions = [
    { value: "watches", label: t("Watches"), icon: <Watch /> },
    { value: "jewelry", label: t("Jewelry"), icon: <Jewelry /> },
    { value: "bags", label: t("Bags"), icon: <Bag /> },
    { value: "shoes ", label: t("Shoes"), icon: <Shoes /> },
    { value: "accessories", label: t("Accessories"), icon: <Accessories /> },
  ];

  const brandOptions = [
    { value: "gucci", label: "Gucci", icon: <Gucci /> },
    { value: "lv", label: "LV", icon: <LV /> },
    { value: "prada", label: "Prada", icon: <Prada /> },
    { value: "hermes ", label: "Hermes", icon: <Hermes /> },
    { value: "dior", label: "Dior", icon: <Dior /> },
    { value: "bulgari", label: "Bulgari", icon: <Dior /> },
    { value: "cartier", label: "Cartier", icon: <Dior /> },
    { value: "rolex", label: "Rolex", icon: <Dior /> },
    { value: "marcJacobs", label: "Marc Jacobs", icon: <Dior /> },
    { value: "ysl", label: "YSL", icon: <YSL /> },
  ];
  const genderOption = [
    { value: "female", label: t("Female"), icon: <Female /> },
    { value: "male", label: t("Male"), icon: <Male /> },
    { value: "kids", label: t("Kids"), icon: <Enfants /> },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (selectedOption) => {
    setFormData({
      ...formData,
      category: selectedOption,
    });
    setErrors({ ...errors, category: "" });
  };

  const validateForm = (isImageUpload = false) => {
    let newErrors = {};

    // if (isImageUpload) {
    //   setImageUpload(true);
    // }

    if (!formData.category) {
      newErrors.category = t("PLEASE_SELECT_A_CATEGORY");
    }
    if (!formData.brand) {
      newErrors.brand = t("PLEASE_SELECT_A_BRAND");
    }
    if (!formData.gender) {
      newErrors.gender = t("PLEASE_SELECT_A_GENDER");
    }
    if (!formData.title) {
      newErrors.title = t("PLEASE_ENTER_A_TITLE");
    }

    if (!formData.price) {
      newErrors.price = t("PLEASE_ENTER_A_VALID_PRICE");
    } else {
      const numericPrice = parseFloat(formData.price);
      if (isNaN(numericPrice)) {
        newErrors.price = t("PLEASE_ENTER_A_VALID_PRICE");
      } else if (numericPrice > 500000) {
        newErrors.price = t("PLEASE_ENTER_A_PRICE_LESSER_THAN_100000");
      } else if (numericPrice < 1000) {
        newErrors.price = t("PLEASE_ENTER_A_PRICE_GREATER_THAN_1000");
      }
    }

    if (!formData.description) {
      newErrors.description = t("PLEASE_ENTER_A_DESCRIPTION");
    }
    if (formData.pictureOfItem?.length === 0) {
      if (!isImageUpload) {
        newErrors.pictureOfItem = t("PLEASE_UPLOAD_A_PRODUCT_IMAGE");
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChangeBrand = (selectedOption) => {
    setFormData({
      ...formData,
      brand: selectedOption,
    });
    setErrors({ ...errors, brand: "" });
  };
  const handleChangeGender = (selectedOption) => {
    setFormData({
      ...formData,
      gender: selectedOption,
    });
    setErrors({ ...errors, gender: "" });
  };

  const handleInputChange = async (e) => {
    e.preventDefault();

    validateForm();
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // setErrors({ ...errors, [name]: "" });
  };

  const handlePriceInput = (e) => {
    const { value } = e.target;

    if (value === "") {
      setFormData({ ...formData, price: "" });
      setErrors({ ...errors, price: t("PLEASE_ENTER_A_VALID_PRICE") });
      return;
    }

    if (/^\d*$/.test(value)) {
      const numericValue = Number(value);
      setFormData({ ...formData, price: value });

      if (numericValue < 1000) {
        setErrors({
          ...errors,
          price: t("PLEASE_ENTER_A_PRICE_GREATER_THAN_1000"),
        });
      } else if (numericValue > 100000) {
        setErrors({
          ...errors,
          price: t("PLEASE_ENTER_A_PRICE_LESSER_THAN_100000"),
        });
      } else {
        setErrors({ ...errors, price: "" });
      }
    }
  };


  const handleDocumentUpload = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      const maxSize = 5 * 1024 * 1024; // 5MB max size
      const minSize = 10 * 1024; // 10KB min size

      if (file.size < minSize) {
        setErrors({ document: t("FILE_SIZE_MIN_ERROR") });
        return;
      } else if (file.size > maxSize) {
        setErrors({ document: t("FILE_SIZE_MAX_ERROR") });
        return;
      }

      if (file.type !== "application/pdf") {
        setErrors({ document: t("FILE_VALID_ERROR") });
        return;
      }

      setFormData({ ...formData, document: file });
      setAuthImage(true);

      setErrors({ ...errors, document: "" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleButtonClick();
    validateForm();

    if (formData?.pictureOfItem?.length < 6) {
      return;
    }

    if (validateForm()) {
      const formDatas = new FormData();

      formDatas.append("category", formData.category?.value || "");
      formDatas.append("brand", formData.brand?.value || "");
      formDatas.append("title", formData.title || "");
      formDatas.append("price", formData.price || 0);
      formDatas.append("description", formData.description || "");
      formDatas.append("serialNumber", formData.serialNumber || "");
      formDatas.append("gender", formData.gender?.value || "");

      if (formData?.document) {
        formDatas?.append("document", formData.document);
      }

      formData?.pictureOfItem?.forEach((image) => {
        formDatas?.append(`productImage`, image);
      });

      try {
        setIsLoading(true);
        const response = await dispatch(createProduct(formDatas));

        if (response?.payload?.statusCode === 200) {
          toast.success(t("PRODUCT_ADD_SUCCESS"));

          setFormData({
            category: null,
            brand: null,
            title: "",
            price: "",
            description: "",
            pictureOfItem: [],
            document: "",
            serialNumber: `SN-${Date.now()}`,
            gender: "",
          });
          // setFileItems([]);
          setAuthImage(false);
          // setShowFileItems(false);
          navigate("/");
        }
      } catch (error) {
        console.error(error);
        toast.error(t("PRODUCT_ADD_FAIL"));
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isButtonClicked) {
      if (currentLanguage === i18n.language) {
        validateForm();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, isButtonClicked]);

  const [sellerPriceDetails, setSellerPriceDetails] = useState(null);
  const isPremiumListing = false;

  useEffect(() => {
    if (formData?.price) {
      const details = calculateFees(
        Number(formData?.price),
        isPremiumListing,
        true
      );
      setSellerPriceDetails(details);
    }
  }, [formData?.price, isPremiumListing]);

  return (
    <>
      <main className="AddProductMainWrapper">
        <Box className="container">
          <SectionHeader sectionTitle={t("ADD_A_PRODUCT_FORM")} />
          <div className="add-product-container">
            <form onSubmit={handleSubmit} className="productForm">
              <div className="productForm_container">
                <div className="productForm__inner seller-add-payment">
                  <div className="form-group">
                    <Select
                      className="customSelect"
                      name="category"
                      options={categoryOptions}
                      value={formData.category}
                      onChange={handleChange}
                      placeholder={t("SELECT_A_CATEGORY")}
                    />
                    {errors.category && (
                      <p className="error">{errors.category}</p>
                    )}
                  </div>

                  <div className="form-group">
                    <Select
                      className="customSelect"
                      name="brand"
                      options={brandOptions}
                      value={formData.brand}
                      onChange={handleChangeBrand}
                      placeholder={t("SELECT_A_BRAND")}
                    />
                    {errors.brand && <p className="error">{errors.brand}</p>}
                  </div>

                  <div className="form-group">
                    <Select
                      className="customSelect"
                      name="gender"
                      options={genderOption}
                      value={formData.gender}
                      onChange={handleChangeGender}
                      placeholder={t("SELECT_A_GENDER")}
                    />
                    {errors.gender && <p className="error">{errors.gender}</p>}
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      name="title"
                      placeholder={t("ENTER_TITLE")}
                      value={formData.title}
                      onChange={handleInputChange}
                    />
                    {errors.title && <p className="error">{errors.title}</p>}
                  </div>

                  <div className="form-group add-product-description">
                    <textarea
                      name="description"
                      placeholder={t("ENTER_DESCRIPTION")}
                      value={formData.description}
                      onChange={handleInputChange}
                    />
                    {errors.description && (
                      <div className="error-container">
                        <p className="error">{errors.description}</p>
                      </div>
                    )}

                    <div className="ImageUpload">
                      <div className="inputUpload">
                        <input
                          type="file"
                          name="image"
                          accept=".pdf,.doc,.docx"
                          onChange={handleDocumentUpload}
                          ref={(input) => (fileInputRef = input)}
                        />
                        <UploadIcon />
                        <p>{t("PHOTOS_OF_AUTHENTICATION_DOCUMENTS")}</p>
                        {errors.document && (
                          <p className="error">{errors.document}</p>
                        )}
                      </div>

                      {authImage && (
                        <div className="imgPreview">
                          <div className="imgPreview__inner">
                            <embed
                              style={{ maxHeight: "fit-ontent" }}
                              src={URL.createObjectURL(formData.document)}
                              type="application/pdf"
                            />
                            <button
                              className="remove-image"
                              onClick={() => {
                                setFormData({ ...formData, document: "" });
                                setAuthImage(false);

                                if (fileInputRef) {
                                  fileInputRef.value = "";
                                }
                              }}
                            >
                              <DismissCircle />
                            </button>
                          </div>
                          <div className="imgPreview__content">
                            <p>{t("PHOTOS_OF_AUTHENTICATION_DOCUMENTS")}</p>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="ImageUpload">
                      <div
                        className="inputUpload"
                        onClick={() => setMultipleImage(!multipleImage)}
                      >
                        <UploadIcon />
                        <p>{t("PHOTOS_OF_PRODUCT")}</p>
                      </div>

                      <div>
                        {multipleImage ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "left",
                                marginBottom: "10px",
                              }}
                            >
                              <h4>{t("Reference_Image")}</h4>
                              {i18n.language === "en" ? (
                                <img src={enReferenceImg} alt="reference-img" />
                              ) : (
                                <img src={frReferenceImg} alt="reference-img" />
                              )}
                            </div>

                            <div>
                              <ImageUpload
                                t={t}
                                formData={formData}
                                setFormData={setFormData}
                                errors={errors}
                                validateForm={validateForm}
                              />
                            </div>
                          </>
                        ) : null}
                        {(errors.pictureOfItem ||
                          formData?.pictureOfItem?.length > 0) && (
                            <p
                              style={{ color: "red", fontSize: "14px" }}
                              className="imageUploadError"
                            >
                              {errors.pictureOfItem}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group add-product-form-group">
                  <div className="innerInputBox">
                    <input
                      type="text"
                      name="price"
                      placeholder={t("ENTER_PRICE")}
                      value={formData.price}
                      onChange={handlePriceInput}
                    />
                    <div className="inputRight">
                      <p>MAD</p>
                      <input
                        type="text"
                        name="price"
                        placeholder={t("ENTER_PRICE")}
                        value={"1000"}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="error-container-price">
                    {errors.price && <p className="error">{errors.price}</p>}
                  </div>

                  <div>
                    {
                      <InvoiceBreakdown
                        sellerPriceDetails={sellerPriceDetails}
                      />
                    }
                  </div>
                </div>
              </div>
              <div className="btnPart">
                <Button type="submit" className="btn_dark">
                  {isLoading ? "Loading..." : t("ADD_PRODUCT")}
                </Button>
              </div>
            </form>
          </div>
        </Box>
      </main>
    </>
  );
};

export default AddProduct;
