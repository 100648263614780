import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import icons from "../../../assets/images/svg/icons";
import { ReactComponent as Trendies } from "../../../assets/images/svg/signIn/HeaderLogo.svg";
import { forgotPasswordSendEmail } from "../../../redux/slices/authSlice";
import "./ForgotPassword.scss";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { MailIcon } = icons;

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await dispatch(forgotPasswordSendEmail(email)).unwrap();
      if (response.statusCode === 200) {
        toast.success(t(response.message));
      }
    } catch (error) {
      console.error("Forgot password error:", error);
      toast.error(t(error) || "Failed to send reset link.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ForgotPassword">
      <div className="container">
        <div className="ForgotPassword__inner ">
          <a href="/" className="themeBrand">
            <Trendies />
          </a>
          <h2 className="ForgotPassword__title">{t("FORGOT_PASSWORD")}</h2>

          <form onSubmit={handleSubmit} className="ForgotPassword__form">
            <div className="form-group">
              <MailIcon />
              <input
                type="email"
                name="email"
                value={email}
                onChange={handleChange}
                required
                placeholder="Enter your email"
                className="input-with-icon"
              />
            </div>

            <div className="ForgotPassword__btn login-btn">
              <button type="submit" className="btn w-100" disabled={loading}>
                {loading ? "Sending..." : t("SEND_RESET_LINK")}
              </button>
            </div>
            <div className="ForgotPassword__footer">
              <p>
                {t("REMEMBER_YOUR_PASSWORD")}{" "}
                <Link to="/login">{t("LOGIN_HERE")}</Link>
              </p>
            </div>
          </form>
        </div>

      </div>
    </div>
  );
};

export default ForgotPassword;
