import React from "react";
import { ReactComponent as CloseBtn } from "../../assets/svg/CloseBtn.svg";
import { ReactComponent as UserSidebar } from "../../assets/svg/UserSidebar.svg";
import { ReactComponent as HeartSidebar } from "../../assets/svg/HeartSidebar.svg";
import { ReactComponent as ShareSidebar } from "../../assets/svg/ShareSidebar.svg";
import { ReactComponent as ChatSidebar } from "../../assets/svg/ChatSidebar.svg";
import { ReactComponent as SettingsSidebar } from "../../assets/svg/SettingsSidebar.svg";
import { ReactComponent as SignOutSidebar } from "../../assets/svg/SignOutSidebar.svg";
import sliderImage1 from "../../assets/images/sliderImage1.jpg";

import { Drawer, IconButton } from "@mui/material";

import { useNavigate } from "react-router-dom";

const Sidebar = ({ isOpen, toggleDrawer }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const userData = JSON.parse(localStorage.getItem("userData"));

  return (
    <Drawer
      className="sidebar"
      anchor="right" // Set drawer to open from the right side
      open={isOpen}
      onClose={toggleDrawer(false)} // Close drawer on outside click
    >
      <div
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <div className="SidebarProfile">
          <IconButton className="closeBtn" onClick={toggleDrawer(false)}>
            <CloseBtn />
          </IconButton>
          <img src={sliderImage1} alt="Uploaded" />
          <h3>Good Morning, {userData?.username}</h3>
          <p>{userData?.email}</p>
        </div>
        <ul className="menuList">
          <li>
            <a href="/profile">
              <UserSidebar /> Profile
            </a>
          </li>
          <li>
            <a href="/favorites">
              <HeartSidebar /> Wishlist
            </a>
          </li>
          <li>
            <a href="/share">
              <ShareSidebar /> Share
            </a>
          </li>
          <li>
            <a href="/feedback">
              <ChatSidebar /> Feedback
            </a>
          </li>
          <li>
            <a href="/feedback">
              <SettingsSidebar /> Feedback
            </a>
          </li>
          <li onClick={() => handleLogout()}>
            <a href="/logout">
              <SignOutSidebar /> Logout
            </a>
          </li>
        </ul>
      </div>
    </Drawer>
  );
};

export default Sidebar;
