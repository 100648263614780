import React, { useCallback, useState } from "react";
import { useFormik } from "formik";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
import { ReactComponent as Trendies } from "../../../assets/images/svg/signIn/HeaderLogo.svg";
import { SignupSchema } from "../../../Validation/validationSchemas";
import icons from "../../../assets/images/svg/icons";
import { IoIosEyeOff } from "react-icons/io";
import { IoEyeOutline } from "react-icons/io5";

import {
  customeGoogleLogin,
  signupSocialAccount,
  socialSignUp,
} from "../../../redux/slices/authSlice";
import "./SignUp.scss";

const Signup = () => {
  const { t } = useTranslation();
  const { MailIcon, LockIcon, UserIcon, FacebookIcon, GoogleIcon } = icons;

  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      username: "",
      nickname: "",
      email: "",
      password: "",
    },
    validationSchema: SignupSchema(t),
    onSubmit: (values) => handleSignup(values),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSocialLoading, setIsSocialLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nickNameValidation, setNickNameValidation] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);

  const handleSignup = async (values) => {
    try {
      setLoading(true);
      const result = await dispatch(socialSignUp(values));
      if (result?.payload?.statusCode === 200) {
        toast.success(t("SIGN_UP_SUCCESS"));

        setTimeout(() => {
          navigate("/login");
        }, 1000);
      } else {
        if (
          result?.payload ===
          "Nickname is already assigned. Please use a different one."
        )
          setNickNameValidation(t("NICKNAME_ALREADY"));
        // toast.error(result?.payload);

        if (
          result?.payload ===
          "A user with this email is already registered with both Google Auth and manual creation."
        ) {
          setEmailValidation(t("EMAIL_ALREADY"));
        }
      }
    } catch (error) {
      toast.error(t("SIGN_UP_FAILED"));
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleGoogleLogin = async (data) => {
    try {
      setIsSocialLoading(true);
      const token = data.access_token;
      localStorage.setItem("token", token);
      const userInfoResponse = await dispatch(customeGoogleLogin(token));
      if (userInfoResponse.meta?.requestStatus !== "fulfilled") {
        toast.error(t("FAILED_TO_FETCH_USER_INFO"));
        return;
      }

      const userInfo = await userInfoResponse?.payload;
      if (!userInfo.email) {
        toast.error(t("EMAIL_NOT_AVAILABLE_FOR_GOOGLE_LOGIN"));
        return;
      }
      const userData = {
        name: userInfo.name,
        email: userInfo.email,
        image: userInfo.picture,
      };
      handleSocialLogin(userData);
    } catch (error) {
      console.error("GOOGLE LOGIN ERROR:", error);
      toast.error(t("GOOGLE_LOGIN_ERROR"));
    } finally {
      setIsSocialLoading(false);
    }
  };

  const onLoginStart = useCallback(() => {}, []);

  const onLogoutSuccess = useCallback(() => {}, []);

  const handleSocialLogin = async (data) => {
    localStorage.setItem("loginViaSocial", true);
    const userData = {
      username: data.name,
      nickname: data.name,
      email: data.email,
      image: data.image,
    };

    try {
      setIsSocialLoading(true);
      const result = await dispatch(signupSocialAccount(userData));

      if (result.meta.requestStatus !== "fulfilled") {
        toast.error(t("ALREADY_REGISTERED_SOCIAL"));
        return;
      }

      if (result?.payload?.status === true) {
        toast.success(t("SIGN_UP_SUCCESS_WITH_SOCIAL"));

        localStorage.setItem("token", result?.payload?.data?.token);
        localStorage.setItem(
          "userData",
          JSON?.stringify(result?.payload.data?.authData)
        );

        navigate("/");
      }
    } catch (error) {
      console.log("error.message", error.message);
      console.error("SOCIAL LOGIN ERROR:", error);
      toast.error(error.message || t("SOCIAL_LOGIN_ERROR"));
    } finally {
      setIsSocialLoading(false);
    }
  };

  return (
    <main className="Login SignUpOuterWrapper">
      <div className="container">
        <div className="Login__inner SignUpWrapper">
          <a href="/" className="themeBrand">
            <Trendies />
          </a>
          <h2 className="Login__title">{t("SIGN_UP")}</h2>

          <form onSubmit={formik.handleSubmit} className="Login__form">
            <Box className="FormGroupOuter">
              <div className="form-group">
                <UserIcon />
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={t("USER_NAME")}
                  className="input-with-icon"
                />
              </div>
              {formik.touched.username && formik.errors.username ? (
                <div className="error-message">{formik.errors.username}</div>
              ) : null}
            </Box>
            <Box className="FormGroupOuter">
              <div className="form-group">
                <UserIcon />
                <input
                  type="text"
                  id="nickname"
                  name="nickname"
                  value={formik.values.nickname}
                  onChange={(e) => {
                    formik.handleChange(e);
                    if (nickNameValidation) {
                      setNickNameValidation("");
                    }
                    if (formik.errors.nickname) {
                      formik.setFieldError("nickname", "");
                    }
                  }}
                  onBlur={formik.handleBlur}
                  placeholder={t("NICK_NAME")}
                  className="input-with-icon"
                />
              </div>
              {formik.touched.nickname && formik.errors.nickname ? (
                <div className="error-message">{formik.errors.nickname}</div>
              ) : null}
              {nickNameValidation ? (
                <div className="error-message">{nickNameValidation}</div>
              ) : null}
            </Box>
            <Box className="FormGroupOuter">
              <div className="form-group">
                <MailIcon />
                <input
                  // type="email"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={(e) => {
                    formik.handleChange(e);
                    if (emailValidation) {
                      setEmailValidation("");
                    }
                    if (formik.errors.nickname) {
                      formik.setFieldError("email", "");
                    }
                  }}
                  onBlur={formik.handleBlur}
                  placeholder={t("ENTER_EMAIL")}
                  className="input-with-icon"
                />
              </div>
              {formik.touched.email && formik.errors.email ? (
                <div className="error-message">{formik.errors.email}</div>
              ) : null}
              {emailValidation ? (
                <div className="error-message">{emailValidation}</div>
              ) : null}
            </Box>
            <Box className="FormGroupOuter">
              <div className="form-group">
                <LockIcon />
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={t("ENTER_PASSWORD")}
                  className="input-with-icon"
                />
                <span className="EyeIcon" onClick={togglePasswordVisibility}>
                  {showPassword ? (
                    <IoEyeOutline size={"1.5rem"} />
                  ) : (
                    <IoIosEyeOff size={"1.5rem"} />
                  )}
                </span>
              </div>
              {formik.touched.password && formik.errors.password ? (
                <div className="error-message">{formik.errors.password}</div>
              ) : null}
            </Box>
            <div className="login-btn">
              <button type="submit" className="btn">
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <div>Loading</div>
                    <div class="loading-dots">
                      <span class="loading">.</span>
                      <span class="loading">.</span>
                      <span class="loading">.</span>
                    </div>
                  </div>
                ) : (
                  t("CREATE_AN_ACCOUNT")
                )}
              </button>
            </div>
          </form>
          <div className="content-text">
            {t("BY_LOGGING_YOU_AGREE_TO")}
            <span> {t("PRIVACY_POLICY")} </span> {t("AND")} <br />
            <span> {t("TERMS_OF_USE")}</span>
          </div>

          <div class="continue-text">
            <p class="separatorText">
              {" "}
              <span>{t("OR_CONTINUE_WITH")}</span>
            </p>
          </div>

          <div className="social-icons">
            <LoginSocialGoogle
              isOnlyGetToken
              client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
              scope="profile email"
              onLoginStart={() => {}}
              onResolve={({ data }) => handleGoogleLogin(data)}
              onReject={(err) => console.error(err)}
            >
              <button className="social-button">
                <GoogleIcon />
              </button>
            </LoginSocialGoogle>
            <LoginSocialFacebook
              appId={process.env.REACT_APP_FB_APP_ID || ""}
              fieldsProfile={
                "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
              }
              onLoginStart={onLoginStart}
              onLogoutSuccess={onLogoutSuccess}
              redirect_uri={process.env.REACT_APP_FACEBOOK_REDIRECT_URL}
              onResolve={({ provider, data }) => {
                data.image = data.picture?.data?.url;
                handleSocialLogin(data);
              }}
              onReject={(err) => {
                console.error(err);
              }}
            >
              <FacebookIcon />
            </LoginSocialFacebook>
          </div>

          <p className="signup-link">
            {t("HAVE_AN_ACCOUNT")} ?{" "}
            <Link to="/login">
              {t("SIGN_IN")} {t("HERE")}
            </Link>
          </p>
        </div>
        {isSocialLoading && (
          <div className="loader-wrapper">
            <div className="loader"></div>
            <p>Loading...</p>
          </div>
        )}
      </div>
    </main>
  );
};

export default Signup;
