import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

// AUTH HEADER FUNCTION
export function authHeader() {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
}

// SOLD PRODUCT SERVICE FUNCTIONS
async function createSoldProduct(sendData) {
  try {
    const { data } = await axios.post(
      `${baseURL}/create-sold-product`,
      sendData,
      {
        headers: authHeader(),
      }
    );

    if (!data.success) {
      throw new Error(data.message || "Failed to create sold product");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
        e.message ||
        "An error occurred while creating sold product"
    );
  }
}

async function updateSoldProductByProductWithSellerWithUserId(
  productId,
  userId,
  sellerId,
  sendData
) {
  try {
    const { data } = await axios.post(
      `${baseURL}/update-sold-product/${productId}/${userId}/${sellerId}`,
      sendData,
      {
        headers: authHeader(),
      }
    );

    if (!data.success) {
      throw new Error(data.message || "Failed to update sold product");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
        e.message ||
        "An error occurred while updating sold product"
    );
  }
}

async function getSoldProductByProductWithUserId(productId, userId) {
  try {
    const { data } = await axios.get(
      `${baseURL}/get-sold-product/${productId}/${userId}`,
      {
        headers: authHeader(),
      }
    );

    if (!data.success) {
      throw new Error(
        data.message || "Failed to get sold product by product and user ID"
      );
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
        e.message ||
        "An error occurred while fetching sold product"
    );
  }
}

async function getSoldProductByUserId(userId) {
  try {
    const { data } = await axios.get(`${baseURL}/sold-product/${userId}`, {
      headers: authHeader(),
    });

    if (!data.success) {
      throw new Error(data.message || "Failed to get sold products by user ID");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
        e.message ||
        "An error occurred while fetching sold products"
    );
  }
}

async function getSoldProductByUserIdAndProductId(productId, userId) {
  try {
    const { data } = await axios.get(
      `${baseURL}/get-sold-product/${productId}/${userId}`,
      {
        headers: authHeader(),
      }
    );

    if (!data.success) {
      throw new Error(
        data.message || "Failed to get sold product by user ID and product ID"
      );
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
        e.message ||
        "An error occurred while fetching sold product"
    );
  }
}

// EXPORT SOLD PRODUCT SERVICE
const SoldProductService = {
  createSoldProduct,
  getSoldProductByUserId,
  getSoldProductByProductWithUserId,
  getSoldProductByUserIdAndProductId,
  updateSoldProductByProductWithSellerWithUserId,
};

export default SoldProductService;
