import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { offerThunk } from "../../../redux/slices/offerSlice";

const RejectOffer = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const endpoint = "offer-reject";
  const dispatch = useDispatch();

  useEffect(() => {
    const handleOffer = async () => {
      try {
        setLoading(true);
        const response = await dispatch(
          offerThunk({ endpoint, token })
        ).unwrap();
        setMessage(response.message || `Offer ${endpoint} successful`);
        toast.success(response.message || t("OFFER_REJECT_SUCCESS"));
        setLoading(false);

        // Redirect to home after 3 seconds
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } catch (error) {
        setMessage(error || `Offer ${endpoint} failed`);
        toast.error(error || t("OFFER_REJECT_FAILED"));
        setLoading(false);
      }
    };

    handleOffer();
  }, [token, navigate, dispatch, t]);

  return (
    <div className="offer-response">
      {loading ? (
        <div className="loading-container">
          <div className="loading-message">
            <span className="loading-title">{t("REJECTING_OFFER")}</span>
            <div className="loading-dots">
              <span className="loading">.</span>
              <span className="loading">.</span>
              <span className="loading">.</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="response-container">
          <p className="response-message">{message}</p>
          <p className="redirect-message">
            {t("REDIRECTING_TO_HOME_PAGE_IN_FEW_SECONDS")}
          </p>
        </div>
      )}
    </div>
  );
};

export default RejectOffer;
