import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export function authHeader() {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
}

// GET USER BY ID
async function getUserById() {
  try {
    const { data } = await axios.get(`${baseURL}/get-user`, {
      headers: {
        ...authHeader(),
        "Content-Type": "multipart/form-data",
      },
    });

    if (!data.status) {
      throw new Error(data.message || "Failed to get user");
    }

    return data;
  } catch (e) {
    if (e.response?.data?.statusCode !== 200) {
      // localStorage.removeItem("token");
      // localStorage.removeItem("userData");
      // window.location.assign("/login");
      throw new Error("Invalid JWT token detected.");
    } else {
      throw new Error(
        e.response?.data?.message ||
        e.response?.data?.Error ||
        e.message ||
        "An error occurred while fetching user data"
      );
    }
  }
}

// GET CART COUNT BY USER ID
async function getCartCountByUserId(userId) {
  try {
    const { data } = await axios.get(`${baseURL}/get-cart/${userId}`, {
      headers: {
        ...authHeader(),
        "Content-Type": "multipart/form-data",
      },
    });

    if (!data.success) {
      throw new Error(data.message || "Failed to fetch cart count");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
      e.response?.data?.Error ||
      e.message ||
      "An error occurred while fetching cart count"
    );
  }
}

// FETCH FAVORITE PRODUCTS BY USER ID
async function fetchFavoriteProductByUserId(userId) {
  try {
    const { data } = await axios.get(
      `${baseURL}/products/userFavorites/${userId}`,
      {
        headers: authHeader(),
      }
    );

    if (!data.status) {
      throw new Error(data.message || "Failed to fetch favorite products");
    }

    return data;
  } catch (e) {
    if (e.response?.data?.statusCode !== 200) {
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
      window.location.reload();
      throw new Error("Invalid JWT token detected.");
    } else {
      throw new Error(e.message || "An error occurred.");
    }
  }
}

// UPDATE USER
async function updateUser(updatedValue) {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/update-profile`,
      updatedValue,
      {
        headers: {
          "Content-Type": "application/json",
          ...authHeader(),
        },
      }
    );

    if (!data.status) {
      throw new Error(data.message || "Failed to update user");
    }

    return data;
  } catch (e) {
    if (e.response?.data?.statusCode !== 200) {
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
      window.location.assign("/login");
      throw new Error("Invalid JWT token detected.");
    } else {
      throw new Error(
        e.response?.data?.message ||
        e.response?.data?.Error ||
        e.message ||
        "An error occurred while updating user data"
      );
    }
  }
}

// EXPORT SERVICE
const UserService = {
  updateUser,
  getUserById,
  getCartCountByUserId,
  fetchFavoriteProductByUserId,
};

export default UserService;
