import { Button } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "./ConfirmDeleteModal.scss";
import { removeProductById } from "../../redux/slices/productSlice";
import { useDispatch } from "react-redux";

const ConfirmDeleteModal = ({ open, setOpen, getProducts }) => {
  const dispatch = useDispatch()
  const modalRef = useRef(null);
  const { t } = useTranslation();

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setOpen("");
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  if (!open) return null;

  const handleDeleteProduct = async () => {

    try {
      if (open !== "") {

        const deleteProductResponse = await dispatch(removeProductById(open))
        if (deleteProductResponse?.payload?.statusCode === 200) {
          toast.success(t("DELETE_PRODUCT_SUCCESS"));
          getProducts();
          setOpen("");
        }
      }
    } catch (error) {
      toast.error(t("ERROR_IN_DELETE_PRODUCT"));
      console.error(error);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="delete-modal-content" ref={modalRef}>
        <h2>{t("DELETE_CONFIRMATION")}</h2>
        <div className="modal-actions btnPart">
          <Button
            className="btn_dark"
            onClick={() => {
              handleDeleteProduct();
              setOpen("");
            }}
          >
            {t("YES")}
          </Button>
          <Button className="btn_dark" onClick={() => setOpen("")}>
            {t("NO")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteModal;
