import { useTranslation } from "react-i18next";
import { ReactComponent as Cross } from "../../assets/svg/cross.svg";
import "./ProductDetailsModel.scss";
import { useEffect, useState } from "react";

const ProductDetailsModel = ({
  isOpen,
  onClose,
  currentProductSellerBuyerDetails,
}) => {
  const { t } = useTranslation();
  const [mainImage, setMainImage] = useState(null);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  useEffect(() => {
    if (currentProductSellerBuyerDetails?.product?.productImage?.length > 0) {
      // Set the first image as the main image initially
      setMainImage(currentProductSellerBuyerDetails.product.productImage[0]);
    }
  }, [currentProductSellerBuyerDetails]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modalContent" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          <Cross />
        </button>

        <div className="property-container productDetail-model">
          <div className="productDetail__inner">
            <div className="productDetail__img">
              {/* Main Image */}
              <div className="main-image">
                {mainImage && <img src={mainImage} alt="Main product view" />}
              </div>

              {/* Image Gallery */}
              <div className="productImg productImg-gallery">
                {currentProductSellerBuyerDetails?.product?.productImage?.map(
                  (image, index) => (
                    <div
                      key={index}
                      className={`thumbnail ${
                        mainImage === image ? "active" : ""
                      }`}
                      onClick={() => setMainImage(image)}
                    >
                      <img src={image} alt={`Thumbnail ${index}`} />
                    </div>
                  )
                )}
              </div>
            </div>

            <div className="product-info-section">
              <h2 className="product-title">
                {currentProductSellerBuyerDetails?.product?.title}
              </h2>
              <div className="product-info-list">
                <p className="product-category-brand">
                  <span>{t("CATEGORY")}:</span>{" "}
                  {currentProductSellerBuyerDetails?.product?.category}
                </p>
                <p className="product-category-brand">
                  <span>{t("BRAND")}:</span>
                  {currentProductSellerBuyerDetails?.product?.brand}
                </p>
                <p className="product-category-brand">
                  <span>{t("DESCRIPTION")}:</span>{" "}
                  {currentProductSellerBuyerDetails?.product?.description}
                </p>
                <p className="product-category-brand">
                  <span>{t("PRICE")}:</span>{" "}
                  {currentProductSellerBuyerDetails?.product?.price}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsModel;
