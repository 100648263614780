import React from "react";
import "./ProductVerificationModal.scss";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

const ProductVerificationModal = ({ isOpen, onAccept, onReject, onCancel, currentSelectedProduct }) => {
  const { t } = useTranslation();
  if (!isOpen) return null;

  return (
    <div className="product-verification-modal-overlay">
      <div className="product-verification-modal">
        <h3>{t("VERIFY_PRODUCT")}</h3>
        <p>{t("ARE_YOU_SURE_WANT_TO_VERIFY_PRODUCT")}</p>
        <div className="product-verification-modal-actions">
          <Button className="modal-accept-button" disabled={currentSelectedProduct?.isVerified} onClick={onAccept}>
            {t("ACCEPT")}
          </Button>
          <Button className="modal-reject-button" disabled={!currentSelectedProduct?.isVerified} onClick={onReject}>
            {t("REJECT")}
          </Button>
          <Button className="modal-cancel-button" onClick={onCancel}>
            {t("CANCEL")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProductVerificationModal;
