import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../services/AuthService";

// LOGIN ASYNC THUNK
export const customLogin = createAsyncThunk(
  "auth/customLogin",
  async (loginData, thunkAPI) => {
    try {
      const response = await AuthService.login(loginData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// GOOGLE LOGIN ASYNC THUNK
export const customeGoogleLogin = createAsyncThunk(
  "auth/customeGoogleLogin",
  async (token, thunkAPI) => {
    try {
      const response = await AuthService.googleLogin(token);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// SIGNUP USER WITH SOCIAL ACCOUNT ASYNC THUNK
export const signupSocialAccount = createAsyncThunk(
  "auth/signupSocialAccount",
  async (userData, thunkAPI) => {
    try {
      const response = await AuthService.SignupSocialAccount(userData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// LOGOUT ASYNC THUNK
export const apiLogout = createAsyncThunk(
  "auth/logout",
  async (_, thunkAPI) => {
    try {
      const response = await AuthService.apiLogout();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// SOCIAL SIGNUP ASYNC THUNK
export const socialSignUp = createAsyncThunk(
  "auth/socialSignUp",
  async (signUpData, thunkAPI) => {
    try {
      const response = await AuthService.SocialSignUp(signUpData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// REFRESH TOKEN THUNK
export const refreshTokenThunk = createAsyncThunk(
  "auth/refreshToken",
  async (_, thunkAPI) => {
    try {
      const newToken = await AuthService.refreshToken();
      return newToken;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// CHANGE PASSWORD ASYNC THUNK
export const changePassword = createAsyncThunk(
  "user/changePassword",
  async ({ userId, password }, thunkAPI) => {
    try {
      const response = await AuthService.changeUserPassword(userId, password);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// FORGOT PASSWORD 
export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async ({ token, password }, thunkAPI) => {
    try {
      const response = await AuthService.forgotUserPassword(token, password);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const forgotPasswordSendEmail = createAsyncThunk(
  "user/forgotPasswordSendEmail",
  async (email, thunkAPI) => {
    try {
      const response = await AuthService.forgotPasswordSendEmail(email);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async (token, thunkAPI) => {
    try {
      const response = await AuthService.verifyEmail(token);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const declineUserAccount = createAsyncThunk(
  "offer/declineUserAccount",
  async (token, thunkAPI) => {
    try {
      const response = await AuthService.declineUserAccount(token);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// AUTH SLICE
const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    error: null,
    login: null,
    userId: null,
    loginUser: null,
    socialLogin: null,
    forgotPasswordSendEmail: null,
    changePassword: null,
    forgotPassword: null,
    verifyEmail: null,
    declineUserAccount: null,
    isAuthenticated: false,
    signUp: null,
  },
  reducers: {
    logout: (state) => {
      AuthService.logout();
      state.login = null;
      state.isAuthenticated = false;
    },
    resetAuthStates: (state) => {
      state.signUp = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      //CUSTOME LOGIN CASES
      .addCase(customLogin.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.isRole = null;
        state.error = action.payload || "Login failed";
      })
      .addCase(customLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.isRole = null;
        state.isAuthenticated = false;
      })
      .addCase(customLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = action?.payload?.authData?.isVerified;
        state.userId = action?.payload?.authData?.id;
        state.login = action?.payload?.authData;
      });
    // GOOGLE LOGIN CASES
    builder
      .addCase(customeGoogleLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.isAuthenticated = false;
      })
      .addCase(customeGoogleLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = action.payload?.email_verified;
        state.socialLogin = action.payload;
      })
      .addCase(customeGoogleLogin.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.error = action.payload || "Google login failed";
      });

    // LOGOUT CASES
    builder
      .addCase(apiLogout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(apiLogout.fulfilled, (state) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.login = null;
      })
      .addCase(apiLogout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Logout failed";
      });

    // SIGN UP SOCIAL ACCOUNT
    builder
      .addCase(signupSocialAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.signUp = false;
      })
      .addCase(signupSocialAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.login = action?.payload?.data?.authData;
      })
      .addCase(signupSocialAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Sign up failed ";
      });

    // FORGOT PASSWORD CASES
    builder
      .addCase(socialSignUp.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.signUp = false;
      })
      .addCase(socialSignUp.fulfilled, (state, action) => {
        state.loading = false;
        state.signUp = action?.payload;
      })
      .addCase(socialSignUp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Sign up failed ";
      });

    // REFRESH TOKEN CASES
    builder
      .addCase(refreshTokenThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(refreshTokenThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload;
        localStorage.setItem("token", action.payload.accessToken);
      })
      .addCase(refreshTokenThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.changePassword = action.payload;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to change password";
      });

    builder
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.forgotPassword = action.payload;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to reset password";
      });

    builder
      .addCase(forgotPasswordSendEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(forgotPasswordSendEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.forgotPasswordSendEmail = action?.payload;
      })
      .addCase(forgotPasswordSendEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to send forgot password email";
      });
    builder
      .addCase(verifyEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.verifyEmail = action?.payload;
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Email verification failed";
      });
    builder
      .addCase(declineUserAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(declineUserAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.accountDeclineSuccess = action?.payload;
      })
      .addCase(declineUserAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Account decline failed";
      });
  },
});

// EXPORT ACTIONS
export const { logout, resetAuthStates } = authSlice.actions;

// EXPORT REDUCER
export default authSlice.reducer;
