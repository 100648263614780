import React, { useState, useEffect, useRef } from 'react';
import './TermAndConditions.scss';
import { useTranslation } from 'react-i18next';

const TermsAndConditionsPopUp = () => {
    const [showBackToTop, setShowBackToTop] = useState(true);
    const { t } = useTranslation();
    const tocRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            setShowBackToTop(window.scrollY > 300);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        tocRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Define sections content
    const sections = [
        {
            id: "introduction",
            title: "introduction.title", // Translation key
            content: (
                <div className="section-content">
                    <p>{t("introduction.content.companyInfo")}</p>
                    <div className="contact-box">
                        <h4>{t("introduction.contact.title")}</h4>
                        <ul>
                            <li>{t("introduction.contact.phone")}</li>
                            <li>{t("introduction.contact.email")}</li>
                            <li>{t("introduction.contact.service")}</li>
                        </ul>
                    </div>
                    <p>{t("introduction.heading_two")}</p>
                </div>
            ),
        },

        {
            id: 'terms-declarations',
            title: t('termsDeclarations.title'),
            content: (
                <div className="section-content">
                    <div className="subsection">
                        <h4>{t('termsDeclarations.subsection1.title')}</h4>
                        <p>{t('termsDeclarations.subsection1.content')}</p>
                        <ul>
                            <li>{t('termsDeclarations.subsection1.point1')}</li>
                            <li>{t('termsDeclarations.subsection1.point2')}</li>
                            <li>{t('termsDeclarations.subsection1.point3')}</li>
                        </ul>
                        <p>{t('termsDeclarations.subsection1.content_two')}</p>
                    </div>

                    <div className="subsection">
                        <h4>{t('termsDeclarations.subsection2.title')}</h4>
                        <p>{t('termsDeclarations.subsection2.content')}</p>
                    </div>

                    <div className="subsection">
                        <h4>{t('termsDeclarations.subsection3.title')}</h4>
                        <p>{t('termsDeclarations.subsection3.content')}</p>
                    </div>

                    <div className="subsection">
                        <h4>{t('termsDeclarations.subsection4.title')}</h4>
                        <p>{t('termsDeclarations.subsection4.content')}</p>
                    </div>
                </div>
            )
        },


        {
            id: "account-open-and-close",
            title: t("accountOpenAndClose.title"),
            content: (
                <div className="section-content">
                    <div className="subsection">
                        <h4>{t("accountOpenAndClose.subsection1.title")}</h4>
                        <p>{t("accountOpenAndClose.subsection1.content")}</p>
                        <ul>
                            <li>{t("accountOpenAndClose.subsection1.point1")}</li>
                            <li>{t("accountOpenAndClose.subsection1.point2")}</li>
                            <li>{t("accountOpenAndClose.subsection1.point3")}</li>
                            <li>{t("accountOpenAndClose.subsection1.point4")}</li>
                        </ul>
                        <p>{t("accountOpenAndClose.subsection1.process")}</p>
                        <ul>
                            <li>{t("accountOpenAndClose.subsection1.processPoint1")}</li>
                            <li>{t("accountOpenAndClose.subsection1.processPoint2")}</li>
                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>{t("accountOpenAndClose.subsection2.title")}</h4>
                        <ul>
                            <li>{t("accountOpenAndClose.subsection2.point1")}</li>
                            <li>{t("accountOpenAndClose.subsection2.point2")}</li>
                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>{t("accountOpenAndClose.subsection3.title")}</h4>
                        <ul>
                            <li>{t("accountOpenAndClose.subsection3.point1")}</li>
                            <li>{t("accountOpenAndClose.subsection3.point2")}</li>
                            <li>{t("accountOpenAndClose.subsection3.point3")}</li>
                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>{t("accountOpenAndClose.subsection4.title")}</h4>
                        <p>{t("accountOpenAndClose.subsection4.content")}</p>
                    </div>
                </div>
            )
        },


        {
            id: 'Validation-of-Offers',
            title: t('Validation-of-Offers.title'),
            content: (
                <div className="section-content">
                    <div className="subsection">
                        <h4>{t('Validation-of-Offers.subsection1.title')}</h4>
                        <p>{t('Validation-of-Offers.subsection1.content')}</p>
                        <ul>
                            <li>{t('Validation-of-Offers.subsection1.point1')}</li>
                            <li>{t('Validation-of-Offers.subsection1.point2')}</li>
                            <li>{t('Validation-of-Offers.subsection1.point3')}</li>
                        </ul>
                        <p>{t('Validation-of-Offers.subsection1.process')}</p>

                    </div>

                    <div className="subsection">
                        <h4>{t('Validation-of-Offers.subsection2.title')}</h4>
                        <p>{t('Validation-of-Offers.subsection2.heading')}</p>
                        <ul>
                            <li>{t('Validation-of-Offers.subsection2.point1')}</li>
                            <li>{t('Validation-of-Offers.subsection2.point2')}</li>
                            <li>{t('Validation-of-Offers.subsection2.point3')}</li>
                        </ul>
                        <p>{t('Validation-of-Offers.subsection2.heading_two')}</p>
                    </div>

                    <div className="subsection">
                        <h4>{t('Validation-of-Offers.subsection3.title')}</h4>
                        <ul>
                            <li>{t('Validation-of-Offers.subsection3.point1')}</li>
                            <li>{t('Validation-of-Offers.subsection3.point2')}</li>
                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>{t('Validation-of-Offers.subsection4.title')}</h4>
                        <p>{t('Validation-of-Offers.subsection4.heading')}</p>
                        <ul>
                            <li>{t('Validation-of-Offers.subsection4.point1')}</li>
                            <li>{t('Validation-of-Offers.subsection4.point2')}</li>
                            <li>{t('Validation-of-Offers.subsection4.point3')}</li>
                        </ul>
                    </div>
                </div>
            )
        },


        {
            id: 'Product-Verification',
            title: t('Product-Verification.title'),
            content: (
                <div className="section-content">
                    <div className="subsection">
                        <h4>{t('Product-Verification.subsection1.title')}</h4>
                        <p>{t('Product-Verification.subsection1.content')}</p>
                        <ul>
                            <li>{t('Product-Verification.subsection1.point1')}</li>
                            <li>{t('Product-Verification.subsection1.point2')}</li>
                            <li>{t('Product-Verification.subsection1.point3')}</li>
                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>{t('Product-Verification.subsection2.title')}</h4>
                        <p>{t('Product-Verification.subsection2.content')}</p>
                        <ul>
                            <li>{t('Product-Verification.subsection2.point1')}</li>
                            <li>{t('Product-Verification.subsection2.point2')}</li>
                            <li>{t('Product-Verification.subsection2.point3')}</li>
                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>{t('Product-Verification.subsection3.title')}</h4>
                        <ul>
                            <li>{t('Product-Verification.subsection3.point1')}</li>
                            <div>
                                <p>{t('Product-Verification.subsection3.point2')}</p>
                                <ul>
                                    <li>{t('Product-Verification.subsection3.subPoint1')}</li>
                                    <li>{t('Product-Verification.subsection3.subPoint2')}</li>
                                </ul>
                            </div>
                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>{t('Product-Verification.subsection4.title')}</h4>
                        <p>{t('Product-Verification.subsection4.content')}</p>
                    </div>
                </div>
            )
        },


        {
            id: 'Orders',
            title: t('Orders'),
            content: (
                <div className="section-content">
                    <div className="subsection">
                        <h4>{t('5.1. Order Process')}</h4>
                        <ul>
                            <li>{t('The Buyer selects a Product and adds it to their cart.')}</li>
                            <li>{t('An order summary is displayed, including:')}</li>
                            <div>
                                <ul>
                                    <li>{t('Details of the Product.')}</li>
                                    <li>{t('Delivery fees.')}</li>
                                    <li>{t('The total amount to be paid.')}</li>
                                </ul>
                            </div>
                            <li>{t('The Buyer confirms the order by making the payment as outlined in Article 6.')}</li>
                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>{t('5.2. Order Confirmation')}</h4>
                        <p>{t('Once payment is confirmed, TRENDIES sends a confirmation email to the Buyer, which includes:')}</p>
                        <ul>
                            <li>{t('The order number.')}</li>
                            <li>{t('Order details.')}</li>
                            <li>{t('An estimated delivery timeframe.')}</li>
                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>{t('5.3. Buyer Commitments')}</h4>
                        <p>{t('The Buyer commits to finalizing the order within the indicated deadlines and providing accurate delivery information. Any errors in the provided information may result in additional charges.')}</p>
                    </div>

                    <div className="subsection">
                        <h4>{t('5.4. Product Availability')}</h4>
                        <p>{t('TRENDIES strives to ensure the availability of Products listed on the platform. However, if a Product becomes unavailable after the order is placed:')}</p>
                        <ul>
                            <li>{t('The Buyer will be informed as soon as possible.')}</li>
                            <li>{t('The amount paid will be refunded within 7 business days.')}</li>
                        </ul>
                    </div>
                </div>
            )
        },


        {
            id: 'Payment',
            title: t('Payment.title'),
            content: (
                <div className="section-content">

                    <div className="subsection">
                        <h4>{t("Payment.subsection1.title")}
                        </h4>
                        <p>{t("Payment.subsection1.content")}</p>
                        <ul>
                            <li>{t("Payment.subsection1.point1")}
                            </li>

                            <li>{t("Payment.subsection1.point2")}</li>
                        </ul>
                        <p>{t("Payment.subsection1.note")}</p>
                    </div>

                    <div className="subsection">
                        <h4>{t("Payment.subsection2.title")}
                        </h4>
                        <p>{t("Payment.subsection2.content_one")}</p>
                        <p>{t("Payment.subsection2.content")}</p>
                        <ul>
                            <li>{t("Payment.subsection2.point1")}</li>
                            <li>{t("Payment.subsection2.point2")}</li>
                            <li>{t("Payment.subsection2.point3")}</li>
                            <li>{t("Payment.subsection2.point4")}</li>
                            <li>{t("Payment.subsection2.point5")}</li>
                        </ul>
                        <p>{t("Payment.subsection2.additionalFees")}</p>
                        <ul>
                            <li>{t("Payment.subsection2.fee1")}</li>
                            <li>{t("Payment.subsection2.fee2")}</li>
                            <li>{t("Payment.subsection2.fee3")}</li>
                            <li>{t("Payment.subsection2.fee4")}</li>
                        </ul>

                    </div>

                    <div className="subsection">
                        <h4>{t("Payment.subsection3.title")} </h4>
                        <p>{t("Payment.subsection3.content")}</p>
                        <ul>
                            <li>{t("Payment.subsection3.point1")}</li>
                            <li>{t("Payment.subsection3.point2")}</li>
                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>{t("Payment.subsection4.title")}</h4>
                        <p>{t("Payment.subsection4.content")}</p>
                        <p>{t("Payment.subsection4.transferOptions")}</p>
                        <ul>
                            <li>{t("Payment.subsection4.option1")}</li>
                            <li>{t("Payment.subsection4.option2")}</li>
                            <li>{t("Payment.subsection4.option3")}</li>
                        </ul>
                    </div>
                </div>
            )
        },

        {
            id: 'TRENDIES-Commission',
            title: t("trendies_commission.title"),
            content: (
                <div className="section-content">

                    <div className="subsection">
                        <h4>7.1. {t("trendies_commission.comission_principles.title")}
                        </h4>
                        <p>{t("trendies_commission.comission_principles.heading")}</p>
                        <ul>
                            <li>{t("trendies_commission.comission_principles.list.content_one")}
                            </li>

                            <li>{t("trendies_commission.comission_principles.list.content_two")}</li>
                            <li>{t("trendies_commission.comission_principles.list.content_three")}
                            </li>
                            <li>{t("trendies_commission.comission_principles.list.content_four")}</li>

                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>7.2. {t("trendies_commission.comission_rates.title")}
                        </h4>
                        <p>{t("trendies_commission.comission_rates.heading")}</p>
                        <ul>
                            <li>{t("trendies_commission.comission_rates.list.content_one")}</li>
                            <li>{t("trendies_commission.comission_rates.list.content_two")}</li>
                            <li>{t("trendies_commission.comission_rates.list.content_three")}</li>
                            <li>{t("trendies_commission.comission_rates.list.content_four")}</li>
                            <li>{t("trendies_commission.comission_rates.list.content_five")}</li>
                        </ul>
                        <p>{t("trendies_commission.comission_rates.heading_two")}</p>
                    </div>

                    <div className="subsection">
                        <h4>7.3. {t("optional_paid_services.title")}
                        </h4>
                        <p>{t("optional_paid_services.heading")}
                        </p>
                        <h5>{t("optional_paid_services.list_one.title")}</h5>
                        <ul>
                            <li>{t("optional_paid_services.list_one.content_one")}</li>
                            <li>{t("optional_paid_services.list_one.content_two")}</li>
                        </ul>

                        <h5>{t("optional_paid_services.list_two.title")}</h5>
                        <ul>
                            <li>{t("optional_paid_services.list_two.content_one")}</li>
                            <li>{t("optional_paid_services.list_two.content_two")}</li>
                            <li>{t("optional_paid_services.list_two.content_three")}</li>
                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>7.4. {t("commission_payment.title")}
                        </h4>
                        <ul>
                            <li>{t("commission_payment.list.content_one")}</li>
                            <li>{t("commission_payment.list.content_two")}</li>
                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>7.5. {t("Exemptions.title")}
                        </h4>
                        <p>{t("Exemptions.heading")}</p>
                        <ul>
                            <li>{t("Exemptions.list.content_one")}</li>
                            <div>
                                <p> {t("Exemptions.list.content_two")}</p>
                                <ul>
                                    <li>{t("Exemptions.list.content_three")}</li>
                                </ul>
                            </div>
                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>7.6. {t("commission_adjustments.title")}
                        </h4>
                        <p>{t("commission_adjustments.heading")}</p>
                    </div>

                    <div className="subsection">
                        <h4>7.7. {t("seller_commitments.title")}
                        </h4>
                        <p>{t("seller_commitments.heading")}</p>
                    </div>
                </div>
            )
        },

        {
            id: 'Product Delivery',
            title: t("product_delivery.title"),
            content: (
                <div className="section-content">

                    <div className="subsection">
                        <h4>8.1. {t("product_delivery.delivery_methods.title")}
                        </h4>
                        <p> {t("product_delivery.delivery_methods.heading")}
                        </p>
                        <ul>
                            <li>{t("product_delivery.delivery_methods.sections.content_one")}
                            </li>

                        </ul>
                        <p>{t("product_delivery.delivery_methods.sections.heading")}</p>
                    </div>

                    <div className="subsection">
                        <h4>8.2. {t("product_delivery.delivery_timeframe.title")}
                        </h4>
                        <p>{t("product_delivery.delivery_timeframe.heading")}</p>
                        <ul>
                            <li>{t("product_delivery.delivery_timeframe.list.content_one")}
                            </li>
                        </ul>
                        <p>{t("product_delivery.delivery_timeframe.heading_two")}
                        </p>
                    </div>

                    <div className="subsection">
                        <h4>8.3. {t("product_delivery.delivery_areas.title")}
                        </h4>
                        <p>{t("product_delivery.delivery_areas.heading")}
                        </p>

                    </div>

                    <div className="subsection">
                        <h4>8.4. {t("product_delivery.delivery_failures.title")}
                        </h4>
                        <p>{t("product_delivery.delivery_failures.heading")}</p>
                        <ul>
                            <li>{t("product_delivery.delivery_failures.list_one.content_one")}</li>
                            <li>{t("product_delivery.delivery_failures.list_one.content_two")}</li>
                            <li>{t("product_delivery.delivery_failures.list_one.content_three")}</li>
                        </ul>
                        <div>
                            <p>{t("product_delivery.delivery_failures.list_two.title")}</p>
                            <ul>
                                <li>{t("product_delivery.delivery_failures.list_two.content_one")}</li>
                                <li>{t("product_delivery.delivery_failures.list_two.content_two")}</li>
                            </ul>
                        </div>
                    </div>

                    <div className="subsection">
                        <h4>8.5. {t("product_delivery.confirmation_receipt.title")}
                        </h4>
                        <p>{t("product_delivery.confirmation_receipt.heading")}
                        </p>
                    </div>

                    <div className="subsection">
                        <h4>8.6. {t("product_delivery.deposit_advance_policy.title")}
                        </h4>
                        <ul>
                            <li>
                                {t("product_delivery.deposit_advance_policy.list.content_one")}
                            </li> <li>
                                {t("product_delivery.deposit_advance_policy.list.content_two")}
                            </li>
                        </ul>
                    </div>
                </div>
            )
        },

        {
            id: 'Product-Returns-and-Refunds',
            title: t("returns_title"),
            content: (
                <div className="section-content">

                    <div className="subsection">
                        <h4>9.1. {t("sections.acceptedReturns.title")}
                        </h4>
                        <p>{t("sections.acceptedReturns.description")}
                        </p>
                        <ul>
                            <li>{t("sections.acceptedReturns.cases.case_one")}
                            </li> <li>{t("sections.acceptedReturns.cases.case_two")}
                            </li>
                            <li>{t("sections.acceptedReturns.reportingInstructions")}
                            </li>
                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>9.2. {t("sections.claimProcess.title")}
                        </h4>
                        <p>1.	Step 1: {t("sections.claimProcess.steps.step1.title")}</p>
                        <div>
                            <h5>{t("sections.claimProcess.steps.step1.heading")}</h5>
                            <ul>
                                <li>{t("sections.claimProcess.steps.step1.requiredDocuments.title1")}</li>
                                <li>{t("sections.claimProcess.steps.step1.requiredDocuments.title2")}</li>
                                <li>{t("sections.claimProcess.steps.step1.requiredDocuments.title3")}</li>
                            </ul>
                        </div>

                        <p>2.	Step 2: {t("sections.claimProcess.steps.step2.title")}</p>
                        <div>
                            <h5>{t("sections.claimProcess.steps.step2.heading")}</h5>
                            <ul>
                                <li>{t("sections.claimProcess.steps.step2.confirmationDetails.content_one")}</li>
                                <li>{t("sections.claimProcess.steps.step2.confirmationDetails.content_two")}</li>
                            </ul>
                        </div>
                    </div>

                    <div className="subsection">
                        <h4>9.3. {t('sections.returns.title')}
                        </h4>
                        <p>1. {t('sections.returns.heading')}

                        </p>
                        <ul>
                            <li>{t("sections.returns.returnGuidelines.content_one")}</li>
                            <li>{t("sections.returns.returnGuidelines.content_two")}</li>
                            <li>{t("sections.returns.returnGuidelines.content_three")}</li>
                        </ul>

                        <p>2. {t("sections.returns.refusalCases.heading")}

                        </p>
                        <ul>
                            <li>{t("sections.returns.refusalCases.content_one")}</li>
                            <li>{t("sections.returns.refusalCases.content_two")}</li>
                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>9.4. {t("sections.refunds.title")}
                        </h4>
                        <ul>
                            <li> {t("sections.refunds.refundTerms.content_one")}</li>
                            <li> {t("sections.refunds.refundTerms.content_two")}</li>
                            <li> {t("sections.refunds.refundTerms.content_three")}</li>
                            {/* <li> {t("sections.refunds.refundTerms.content_four")}</li> */}
                        </ul>

                        <div className="subsection">
                            <h4>9.5. {t("sections.returnExclusions.title")}
                            </h4>
                            <p> {t("sections.returnExclusions.heading")}</p>
                            <ul>
                                <li>{t("sections.returnExclusions.excludedItems.content_one")}</li>
                                <li>{t("sections.returnExclusions.excludedItems.content_two")}</li>
                                <li>{t("sections.returnExclusions.excludedItems.content_three")}
                                </li>
                            </ul>
                        </div>

                        <div className="subsection">
                            <h4>9.6. {t("sections.sellerConsequences.title")}
                            </h4>
                            <p>{t("sections.sellerConsequences.terms")}
                            </p>

                        </div>
                    </div>
                </div>
            )
        },

        {
            id: 'Limitations of Liability',
            title: t("LimitationsOfLiability.title"),
            content: (
                <div className="section-content">
                    <div className="subsection">
                        <h4>10.1. {t('LimitationsOfLiability.10.1.title')}</h4>
                        <p>{t('LimitationsOfLiability.10.1.paragraph')}</p>
                        <ul>
                            <li>{t('LimitationsOfLiability.10.1.list.0')}</li>
                            <li>
                                <p>{t('LimitationsOfLiability.10.1.responsibility')}</p>
                                <ul>
                                    <li>{t('LimitationsOfLiability.10.1.responsibilityList.0')}</li>
                                    <li>{t('LimitationsOfLiability.10.1.responsibilityList.1')}</li>
                                    <li>{t('LimitationsOfLiability.10.1.responsibilityList.2')}</li>
                                    <li>{t('LimitationsOfLiability.10.1.responsibilityList.3')}</li>
                                </ul>

                            </li>

                        </ul>

                    </div>

                    <div className="subsection">
                        <h4>10.2. {t('LimitationsOfLiability.10.2.title')}</h4>
                        <p>{t('LimitationsOfLiability.10.2.paragraph')}</p>
                        <ul>
                            <li>{t('LimitationsOfLiability.10.2.list.0')}</li>
                            <li>{t('LimitationsOfLiability.10.2.list.1')}</li>
                            <li>{t('LimitationsOfLiability.10.2.list.2')}</li>
                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>10.3. {t('LimitationsOfLiability.10.3.title')}</h4>
                        <p>{t('LimitationsOfLiability.10.3.paragraph')}</p>
                        <ul>
                            <li>{t('LimitationsOfLiability.10.3.list.0')}</li>
                            <ul>
                                <li>{t('LimitationsOfLiability.10.3.sublist.0')}</li>
                                <li>{t('LimitationsOfLiability.10.3.sublist.1')}</li>
                                <li>{t('LimitationsOfLiability.10.3.sublist.2')}</li>
                            </ul>
                            <li>{t('LimitationsOfLiability.10.3.list.1')}</li>
                            <li>{t('LimitationsOfLiability.10.3.list.2')}</li>
                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>10.4. {t('LimitationsOfLiability.10.4.title')}</h4>
                        <p>{t('LimitationsOfLiability.10.4.paragraph')}</p>
                    </div>
                </div>
            )
        },

        {
            id: 'User-Obligations',
            title: t('UserObligations.title'),
            content: (
                <div className="section-content">
                    <div className="subsection">
                        <h4>11.1. {t('UserObligations.11.1.title')}</h4>
                        <p>{t('UserObligations.11.1.paragraph')}</p>
                        <ul>
                            <li>{t('UserObligations.11.1.list.0')}</li>
                            <li>{t('UserObligations.11.1.list.1')}</li>
                            <li>{t('UserObligations.11.1.list.2')}</li>
                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>11.2. {t('UserObligations.11.2.title')}</h4>
                        <p>{t('UserObligations.11.2.paragraph')}</p>
                        <ul>
                            <li>{t('UserObligations.11.2.list.0')}</li>
                            <li>{t('UserObligations.11.2.list.1')}</li>
                            <li>{t('UserObligations.11.2.list.2')}</li>
                            <li>{t('UserObligations.11.2.list.3')}</li>
                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>11.3. {t('UserObligations.11.3.title')}</h4>
                        <p>{t('UserObligations.11.3.paragraph')}</p>
                        <ul>
                            <li>{t('UserObligations.11.3.list.0')}</li>
                            <li>{t('UserObligations.11.3.list.1')}</li>
                            <li>{t('UserObligations.11.3.list.2')}</li>
                        </ul>
                    </div>
                </div>
            )
        },

        {
            id: 'Personal Data',
            title: t("personal_data_title"),
            content: (
                <div className="section-content">
                    <div className="subsection">
                        <h4>{t('personal_data_section1_title')}</h4>
                        <p>{t('personal_data_section1_description')}</p>
                        <ul>
                            <li>{t('personal_data_section1_list_item1')}</li>
                            <li>{t('personal_data_section1_list_item2')}</li>
                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>{t('personal_data_section2_title')}</h4>
                        <p>{t('personal_data_section2_description')}</p>
                        <ul>
                            <li>{t('personal_data_section2_list_item1')}</li>
                            <li>{t('personal_data_section2_list_item2')}</li>
                            <li>{t('personal_data_section2_list_item3')}</li>
                            <li>{t('personal_data_section2_list_item4')}</li>
                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>{t('personal_data_section3_title')}</h4>
                        <p>{t('personal_data_section3_description')}</p>
                        <ul>
                            <li>{t('personal_data_section3_list_item1')}</li>
                            <li>{t('personal_data_section3_list_item2')}</li>
                            <li>{t('personal_data_section3_list_item3')}</li>
                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>{t('personal_data_section4_title')}</h4>
                        <p>{t('personal_data_section4_description')}</p>
                        <ul>
                            <li>{t('personal_data_section4_list_item1')}</li>
                            <li>{t('personal_data_section4_list_item2')}</li>
                            <li>{t('personal_data_section4_list_item3')}</li>
                        </ul>
                        <p>{t('personal_data_section4_contact_description')}</p>
                        <ul>
                            <li>{t('personal_data_section4_contact_list_item1')}</li>
                            <li>{t('personal_data_section4_contact_list_item2')}</li>
                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>{t('personal_data_section5_title')}</h4>
                        <p>{t('personal_data_section5_description')}</p>
                    </div>
                </div>
            )
        },


        {
            id: 'Intellectual-Property',
            title: t("intellectual_property_title"),
            content: (
                <div className="section-content">

                    <div className="subsection">
                        <h4>{t('intellectual_property_section1_title')}</h4>
                        <p>{t('intellectual_property_section1_description')}</p>
                        <ul>
                            <li>{t('intellectual_property_section1_list_item1')}</li>
                            <li>{t('intellectual_property_section1_list_item2')}</li>
                            <li>{t('intellectual_property_section1_list_item3')}</li>
                        </ul>
                        <p>{t('intellectual_property_section1_conclusion')}</p>
                    </div>

                    <div className="subsection">
                        <h4>{t('intellectual_property_section2_title')}</h4>
                        <p>{t('intellectual_property_section2_description')}</p>
                        <ul>
                            <li>{t('intellectual_property_section2_list_item1')}</li>
                            <li>{t('intellectual_property_section2_list_item2')}</li>
                        </ul>
                    </div>

                    <div className="subsection">
                        <h4>{t('intellectual_property_section3_title')}</h4>
                        <p>{t('intellectual_property_section3_description')}</p>
                        <ul>
                            <li>{t('intellectual_property_section3_list_item1')}</li>
                            <li>{t('intellectual_property_section3_list_item2')}</li>
                        </ul>
                    </div>

                </div>
            )
        },

        {
            id: 'Miscellaneous-Provisions',
            title: t("mis_title"),
            content: (
                <div className="section-content">
                    <div className="subsection">
                        <h4>{t('section1.title')}</h4>
                        <p>{t('section1.description')}</p>
                    </div>

                    <div className="subsection">
                        <h4>{t('section2.title')}</h4>
                        <p>{t('section2.description')}</p>
                    </div>

                    <div className="subsection">
                        <h4>{t('section3.title')}</h4>
                        <p>{t('section3.description')}</p>
                    </div>

                    <div className="subsection">
                        <h4>{t('section4.title')}</h4>
                        <p>{t('section4.description')}</p>
                    </div>

                    <div className="subsection">
                        <h4>{t('section5.title')}</h4>
                        <p>{t('section5.description')}</p>
                    </div>
                </div>
            )
        },


        {
            id: 'title',
            title: t('title'),
            content: (
                <div className="section-content">
                    <div className="subsection">
                        <h4>{t("governingLaw.header")}
                        </h4>
                        <p>{t("governingLaw.content")}
                        </p>
                    </div>

                    <div className="subsection">
                        <h4>{t("competentJurisdiction.header")}
                        </h4>
                        <p>{t("competentJurisdiction.content")}
                        </p>
                    </div>

                    <div className="subsection">
                        <h4>{t("disputeResolution.header")}
                        </h4>
                        <p>{t("disputeResolution.content")}
                        </p>
                        <ul>
                            <li>{t("disputeResolution.contact.Phone")}</li>
                            <li>{t("disputeResolution.contact.Email")}</li>
                        </ul>
                    </div>
                </div >
            )
        },

    ];

    return (
        <div className="terms-container" style={{ padding: "0px" }}>

            <div className="terms-sections" style={{ padding: "0px" }} >
                {sections.map((section, index) => (
                    <section
                        key={section.id}
                        id={section.id}
                        className="terms-section"
                        style={{ padding: "20px" }}
                    >
                        <div className="section-header">
                            <h3>{t(section.title)}</h3>
                        </div>

                        <div className="section-body">
                            {section.content}
                        </div>

                    </section>
                ))}
            </div>
            {showBackToTop && (
                <button
                    className="back-to-top"
                    onClick={scrollToTop}
                    aria-label="Back to top"
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path d="M18 15l-6-6-6 6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            )}

        </div>
    );
};

export default TermsAndConditionsPopUp;