import { Box, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ModelClose } from "../../../assets/svg/ModelClose.svg";
import Success from "../../../assets/svg/Success.svg";

const style = {
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function BasicModal({
  open = true,
  onClose,
  title,
  description,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 2000);
  });

  return (
    <>
      {/* <button>Open modal</button> */}
      <Modal
        className="confirmationModel"
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style} className="confirmationModel__inner">
            <Button onClick={onClose} className="close-btn">
              <ModelClose />
            </Button>
            <img src={Success} alt="" />
            <h4 id="modal-modal-title">{title}</h4>
            <p id="modal-modal-description">{description}</p>
          </Box>
        </>
      </Modal>
    </>
  );
}
