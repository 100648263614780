import React from "react";
import "./PriceItem.scss"

const PriceItem = ({ label, amount, isPercentage = false, content }) => (
  <div className="row-payment">
    <span className="label-payment">{label}</span>
    {!!content ? (
      <>
        <span className="tooltip-payment">?</span>
        <div className="tooltip-text-payment">{content}</div>
      </>
    ) : null}

    <div>
      <span className="price-item-amount">
        {`${amount?.toFixed(0)}${isPercentage ? "%" : ""}`} MAD
      </span>
      {/* <h5 className="price-item-label">{`(${label})`}</h5> */}
    </div>
  </div>
);

export default PriceItem;
