import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export function authHeader() {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
}

async function search(query, params = {}) {
  try {
    const { data } = await axios.get(`${baseURL}/products/search`, {
      headers: {
        ...authHeader(),
        "Content-Type": "application/json",
      },

      params: {
        search: query,
        page: params.page || 1,
        limit: params.limit || 10,
        sortBy: params.sortBy || "createdAt",
        sortOrder: params.sortOrder || "DESC",
      },
    });

    if (!data.status) {
      throw new Error(data.message || "Search failed");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
        e.message ||
        "An error occurred while performing the search"
    );
  }
}

const SearchService = {
  search,
};

export default SearchService;
