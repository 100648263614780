import { TabPanel } from "@mui/lab";
import { Avatar, DialogContent, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ReactComponent as Save } from "../../../assets/svg/save.svg";
import ScheduleTime from "./ScheduleTime";

import "./NotificationPanel.scss";

const NotificationPanel = ({
  notifications,
  // getNotificationByUserId,
  setIsNotification,
}) => {
  const [loading, setLoading] = useState(false);
  const [isTimeSlotModal, setIsTimeSlotModal] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const { t } = useTranslation();
  const userData = JSON?.parse(localStorage?.getItem("userData")) || null;

  useEffect(() => {
    setLoading(true);
    if (notifications?.length > 0) {
      setLoading(false);
    }
  }, [notifications]);

  async function markNotificationAsRead(notificationId) {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/notification/${notificationId}/read`
      );
      if (response.status === 200) {
        toast.success(t("NOTIFICATION_MARK_AS_READ_SUCCESS"));
        return
      }
    } catch (error) {
      toast.error(t("NOTIFICATION_MARK_AS_READ_ERROR"));
      console.error("Error:", error);
    }
  }

  const handleAction = (action, notificationId) => {
    switch (action) {
      case "markAsRead":
        console.log("markAsRead");
        markNotificationAsRead(notificationId);
        break;
      case "viewData":
        console.log("Viewing data");
        break;
      // Additional action handlers...
      default:
        console.log("No action defined");
    }
  };

  const handleSetTimeSlot = async (notification) => {
    setCurrentData(notification?.actions);
    setIsTimeSlotModal(true);
  };

  const handleSaveTimeSlot = async (timeslot) => {
    const sendTimeSlotData = {
      productId: currentData?.productId,
      timeSlot: timeslot,
      status: true,
      sellerId: currentData?.sellerId,
      receiverId: currentData?.receiverId && currentData?.receiverId,
    };
    try {
      const timeSlotResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/verify-product-timeslot`,
        sendTimeSlotData
      );
      const responseStatus = timeSlotResponse?.data?.status;
      if (responseStatus) {
        toast.success(t("TIME_SLOT_ALLOTED_FOR_THIS_PRODUCT"));
      }
    } catch (error) {
      toast.error("Error in time slot allotment!");
      console.log(error);
    }
    setIsTimeSlotModal(false);
  };

  const handleClose = () => {
    setIsTimeSlotModal(false);
    setIsNotification(false);
  };

  return (
    <TabPanel value="1" className="Tabbing__details">
      <DialogContent className="dialogContent notificationModel">
        {notifications?.length > 0 ? (
          loading ? (
            <p>{t("LOADING_NOTIFICATION")}</p>
          ) : (
            notifications?.map((notification, index) => (
              <div key={notification.id} className="content-item-container">
                <Avatar
                  className="item-avatar"
                  alt={notification.title}
                  src={
                    userData?.image ||
                    "https://wac-cdn.atlassian.com/dam/jcr:ba03a215-2f45-40f5-8540-b2015223c918/Max-R_Headshot%20(1).jpg?cdnVersion=2378"
                  }
                />
                <div style={{ flex: 1 }}>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    variant="body2"
                  >
                    <strong>{notification?.title}</strong>
                    <strong
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "24px",
                        height: "24px",
                        borderRadius: "50%",
                        backgroundColor: "transparent",
                        border: "1px solid black",
                        transition: "background-color 0.3s ease",
                        "&:hover": {
                          backgroundColor: "gray",
                          color: "white",
                        },
                      }}
                    >
                      {index + 1}
                    </strong>
                  </Typography>
                  <p className="item-data">
                    {notification?.message}
                    {notification?.actions?.productId && (
                      <button
                        onClick={() => handleSetTimeSlot(notification)}
                        className="update-time-slot-btn"
                      >
                        {t("PLEASE_UPDATE_TIME_SLOT")}
                      </button>
                    )}
                  </p>
                  <div className="item-data-time">
                    <p>{new Date(notification?.timestamp)?.toLocaleString()}</p>
                    {!notification?.read && (
                      <Save
                        className="mark-as-read"
                        onClick={() =>
                          handleAction("markAsRead", notification?.id)
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            ))
          )
        ) : (
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginTop: "20px" }}
          >
            {t("NO_NOTIFICATION")}
          </Typography>
        )}
      </DialogContent>
      <ScheduleTime
        isOpen={isTimeSlotModal}
        onClose={handleClose}
        onSave={handleSaveTimeSlot}
      />
    </TabPanel>
  );
};

export default NotificationPanel;
