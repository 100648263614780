import { Button } from "@mui/material";

const SideBarButton = ({ label, isActive, onClick, unseenCount }) => (
  <Button
    onClick={onClick}
    className={`sidebar_header_options_common ${
      isActive ? "sidebar_active btn_dark" : ""
    }`}
  >
    {label}
    {unseenCount > 0 && <p>{unseenCount}</p>}
  </Button>
);

export default SideBarButton;
