import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SellerService from "../services/SellerService";

// Async Thunk for decrypting seller data
export const decryptSellerDataThunk = createAsyncThunk(
  "seller/decryptSellerData",
  async (userId, thunkAPI) => {
    try {
      const response = await SellerService.decryptSellerData(userId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Async Thunk for creating a seller
export const createSellerThunk = createAsyncThunk(
  "seller/createSeller",
  async (formData, thunkAPI) => {
    try {
      const response = await SellerService.createSeller(formData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const sellerSlice = createSlice({
  name: "seller",
  initialState: {
    decryptedSellerData: null,
    createdSellerData: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetSellerState: (state) => {
      state.decryptedSellerData = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(decryptSellerDataThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(decryptSellerDataThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.decryptedSellerData = action.payload.data;
      })
      .addCase(decryptSellerDataThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to decrypt seller data";
      })
      .addCase(createSellerThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSellerThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.createdSellerData = action.payload.data;
      })
      .addCase(createSellerThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to create seller";
      });
  },
});

export const { resetSellerState } = sellerSlice.actions;
export default sellerSlice.reducer;
