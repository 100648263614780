import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CartService from "../services/CartService";

// ADD CART ITEM ASYNC THUNK
export const addCartItem = createAsyncThunk(
  "cart/addCartItem",
  async (cartData, thunkAPI) => {
    try {
      return await CartService.createCartItem(cartData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// FETCH ALL CART ITEM ASYNC THUNK
export const fetchAllCartItems = createAsyncThunk(
  "cart/fetchAllCartItems",
  async (_, thunkAPI) => {
    try {
      return await CartService.getAllCartItemsByUserId();
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// DELETE CART ITEM ASYNC THUNK
export const removeCartItem = createAsyncThunk(
  "cart/removeCartItem",
  async (cartItemId, thunkAPI) => {
    try {
      return await CartService.deleteCartItemById(cartItemId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// UPDATE CART ITEM ASYNC THUNK
export const updateCartItem = createAsyncThunk(
  "cart/updateCartItem",
  async ({ cartItemId, cartData }, thunkAPI) => {
    try {
      return await CartService.updateCartItemById(cartItemId, cartData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// SLICE
const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetCartState: (state) => {
      state.cartItems = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCartItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(addCartItem.fulfilled, (state, action) => {
        if (Array.isArray(state.cartItems)) {
          state.cartItems.push(action.payload.data);
        } else {
          state.cartItems = [action.payload.data]; 
        }
      })
      .addCase(addCartItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchAllCartItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllCartItems.fulfilled, (state, action) => {
        state.loading = false;
        state.cartItems = action.payload.data;
      })
      .addCase(fetchAllCartItems.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(removeCartItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeCartItem.fulfilled, (state, action) => {
        state.loading = false;
        state.cartItems = state.cartItems.filter(
          (item) => item.id !== action.meta.arg
        );
      })
      .addCase(removeCartItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(updateCartItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCartItem.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.cartItems.findIndex(
          (item) => item.id === action.meta.arg.cartItemId
        );
        if (index !== -1) {
          state.cartItems[index] = {
            ...state.cartItems[index],
            ...action.payload.data,
          };
        }
      })
      .addCase(updateCartItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// EXPORT ACTIONS
export const { resetCartState } = cartSlice.actions;

// EXPORT REDUCER
export default cartSlice.reducer;
