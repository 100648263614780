import { Box, Typography } from "@mui/material";
import icons from "../../../assets/images/svg/icons";

const ChatList = ({
  users,
  activeChat,
  handleUserClick,
  setCurrentProductSellerBuyerDetails,
  userType,
  searchQuery,
}) => {
  const { User } = icons;

  const formatTime = (timestamp) => {
    if (!timestamp) return null;
    const date = new Date(timestamp);

    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = date.getHours() >= 12 ? "PM" : "AM";

    return `${hours}:${minutes} ${ampm}`;
  };

  return (
    <>
      {users && users.length > 0 ? (
        <ul>
          {users
            .filter((user) => {
              const username =
                userType === "seller"
                  ? user?.receiver?.username?.toUpperCase()
                  : user?.seller?.username?.toUpperCase() ||
                  user?.seller?.nickname?.toUpperCase();

              return (
                !searchQuery || username?.includes(searchQuery.toUpperCase())
              );
            })
            .map((user) => {
              const isActive =
                activeChat?.id ===
                (userType === "seller" ? user.receiverId : user.sellerId) &&
                activeChat?.productId === user.productId;

              const username =
                userType === "seller"
                  ? user?.receiver?.username?.toUpperCase()
                  : user?.seller?.username?.toUpperCase() ||
                  user?.seller?.nickname?.toUpperCase();

              const userId =
                userType === "seller" ? user.receiverId : user.sellerId;

              return (
                <li
                  key={`${user.productId}-${userId}`}
                  className={`userItem ${isActive ? "active" : ""} ${!user?.latestMessage ? "no-message" : ""
                    } `}

                  style={{
                    border: user?.product?.isSold ? "0.1px dotted #e3919152" : "none",
                  }}
                  onClick={() => {
                    handleUserClick(userId, user.productId);
                    setCurrentProductSellerBuyerDetails(user);
                  }}
                >
                  {user?.product?.productImage[0] !== undefined ? (
                    <div style={{
                      color: "red",
                    }} className="userItem__product">
                      {/* <h5 style={{
                        color: "red",
                        fontSize: "10px"
                      }} className="userItem__sold">
                        {user?.product?.isSold ? "Sold Out" : null}
                      </h5> */}
                      <img
                        className="userItem__img"
                        src={user?.product?.productImage[0]}
                        alt="Product"
                      />
                    </div>
                  ) : (
                    <Box className="userItem__icon">
                      <User />
                    </Box>
                  )}

                  <div className="userItem__info">
                    <div className="userItem__infoContainer">
                      <Box>
                        <h6 className="userItem__name">{username}</h6>
                        <p className="userItem__text">{user?.product?.title}</p>
                      </Box>
                      {userType !== "seller" && (
                        <p>
                          {formatTime(user?.latestMessage?.timestamp)
                            ? formatTime(user?.latestMessage?.timestamp)
                            : formatTime(Date.now())}
                        </p>
                      )}
                    </div>
                    <div className="userItem__chatInfoContainer">
                      <h6 className="userItem__name">
                        {user?.latestMessage?.content}
                      </h6>
                      {user?.unseenCount > 0 && (
                        <p className="userItem__msgCount">
                          {user?.unseenCount}
                        </p>
                      )}
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      ) : (
        <Typography variant="h6" className="ErrorBlank">
          NO CHAT FOUND
        </Typography>
      )}
    </>
  );
};

export default ChatList;
