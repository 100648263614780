import React, { useEffect } from "react";
import { FaRegClock } from "react-icons/fa";
import { CiMail } from "react-icons/ci";
import { CiPhone } from "react-icons/ci";
import { FiMapPin } from "react-icons/fi";

import "./ContactUs.scss";
import { useTranslation } from "react-i18next";

const ContactPage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="contact-page">
      <div className="contact-container">
        <h1 className="contact-title">{t("ContactUs")}</h1>
        <p className="contact-subtitle">{t("contact_us_help")}</p>

        <div className="contact-info">
          <div className="contact-item">
            <CiPhone className="contact-icon" />
            <span>06 63 06 30 75</span>
          </div>

          <div className="contact-item">
            <CiMail className="contact-icon" />
            <a href="mailto:contact@trendiesmaroc.com">
              contact@trendiesmaroc.com
            </a>
          </div>

          <div className="contact-item">
            <FiMapPin className="contact-icon" />
            <span>MAARIF, CASABLANCA</span>
          </div>

          <div className="contact-item">
            <FaRegClock className="contact-icon" />
            <span>Monday to Friday, 9 AM to 6 PM</span>
          </div>
        </div>

        {/* <div className="contact-form">
          <input
            type="text"
            placeholder={t("your_name")}
            className="form-input"
          />
          <input
            type="email"
            placeholder={t("ENTER_EMAIL")}
            className="form-input"
          />
          <textarea
            placeholder={t("your_message")}
            className="form-textarea"
          ></textarea>
          <button className="form-submit">
            {t("Send_Message")}
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default ContactPage;
