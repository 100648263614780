import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export function authHeader() {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
}

// CREATE NOTIFICATION
async function createNotification(notificationData) {
  try {
    const { data } = await axios.post(
      `${baseURL}/notifications`,
      notificationData,
      {
        headers: {
          ...authHeader(),
          "Content-Type": "application/json",
        },
      }
    );

    if (!data.status) {
      throw new Error(data.message || "Failed to create notification");
    }

    return data;
  } catch (e) {
    if (e.response?.data?.statusCode !== 200) {
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
      window.location.assign("/login");
      throw new Error("Invalid JWT token detected.");
    } else {
      throw new Error(
        e.response?.data?.message ||
          e.response?.data?.Error ||
          e.message ||
          "An error occurred while creating notification"
      );
    }
  }
}

// GET ALL NOTIFICATIONS BY USER ID
async function getAllNotificationsByUserId(userId) {
  try {
    const { data } = await axios.get(`${baseURL}/notifications/${userId}`, {
      headers: authHeader(),
    });

    if (!data.status) {
      throw new Error(data.message || "Failed to fetch notifications");
    }

    return data;
  } catch (e) {
    if (e.response?.data?.statusCode !== 200) {
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
      window.location.assign("/login");
      throw new Error("Invalid JWT token detected.");
    } else {
      throw new Error(
        e.response?.data?.message ||
          e.response?.data?.Error ||
          e.message ||
          "An error occurred while fetching notifications"
      );
    }
  }
}

// DELETE NOTIFICATION BY ID
async function deleteNotificationById(notificationId) {
  try {
    const { data } = await axios.delete(
      `${baseURL}/notifications/${notificationId}`,
      {
        headers: authHeader(),
      }
    );

    if (!data.status) {
      throw new Error(data.message || "Failed to delete notification");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
      e.response?.data?.Error ||
      e.message ||
      "An error occurred while deleting notification"
    );
  }
}

// UPDATE NOTIFICATION BY ID
async function updateNotificationById(notificationId, notificationData) {
  try {
    const { data } = await axios.put(
      `${baseURL}/notifications/${notificationId}`,
      notificationData,
      {
        headers: {
          ...authHeader(),
          "Content-Type": "application/json",
        },
      }
    );

    if (!data.status) {
      throw new Error(data.message || "Failed to update notification");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
      e.response?.data?.Error ||
      e.message ||
      "An error occurred while updating notification"
    );
  }
}

// MARK NOTIFICATION AS READ
async function markNotificationAsRead(notificationId) {
  try {
    const { data } = await axios.put(
      `${baseURL}/notifications/${notificationId}/read`,
      {},
      {
        headers: authHeader(),
      }
    );

    if (!data.status) {
      throw new Error(data.message || "Failed to mark notification as read");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
      e.response?.data?.Error ||
      e.message ||
      "An error occurred while marking notification as read"
    );
  }
}

// EXPORT NOTIFICATION SERVICE
const NotificationService = {
  createNotification,
  getAllNotificationsByUserId,
  deleteNotificationById,
  updateNotificationById,
  markNotificationAsRead,
};

export default NotificationService;
