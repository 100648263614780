import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchUserById } from "../../../redux/slices/userSlice";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { IoMdArrowRoundBack } from "react-icons/io";
import "./UserDetails.scss";
import axios from "axios";

const UserDetails = ({ data, selectedId, setShowUserDetails, activeView }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sellerDetails, setSellerDetails] = useState({});
  const [isSeller, setIsSeller] = useState(false);
  const selectedProduct = data.find((product) => product.id === selectedId);

  const getSellerDetails = async (userId) => {
    try {
      //   setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/seller/userId/${userId}`
      );
      if (response?.status === 200) {
        const sellerDetails = response?.data?.data;
        setSellerDetails(sellerDetails);
      }
    } catch (error) {
      console.log("Error fetching seller details:", error);
    } finally {
      //   setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(fetchUserById());
  }, [dispatch]);

  const { user } = selectedProduct;

  const userData = activeView === "products" ? user : selectedProduct;

  useEffect(() => {
    if (userData.isSeller) {
      setIsSeller(true);
      getSellerDetails(userData.id);
    }
  }, [userData]);

  return (
    <div className="userDetails">
      <div className="userDetails__head">
        <Button
          className="btn btn-primary"
          onClick={() => setShowUserDetails(false)}
        >
          <IoMdArrowRoundBack /> {t("BACK")}
        </Button>
        <h3 className="userDetails__title">User Details</h3>
      </div>
      <div className="userDetails__card">
        <div className="userDetails__card-head">
          <img
            src={userData?.image}
            alt="Profile"
            className="userDetails__img"
          />
          <div className="userDetails__card-info">
            <h5 className="userDetails__card-name">{userData?.username}</h5>
            <p className="userDetails__card-mail">{userData?.email}</p>
          </div>
        </div>
        <div className="userDetails__items">
          <div className="userDetails__item">
            <h6>{t("USER_ID")}</h6>
            <p>{userData?.id}</p>
          </div>
          <div className="userDetails__item">
            <h6>{t("USER_NAME")}</h6>
            <p>{userData?.username}</p>
          </div>
          <div className="userDetails__item">
            <h6>{t("NICK_NAME")}</h6>
            <p>{userData?.nickname ? userData?.nickname : "___"}</p>
          </div>
          <div className="userDetails__item">
            <h6>{t("EMAIL")}</h6>
            <p>{userData?.email}</p>
          </div>
          <div className="userDetails__item">
            <h6>Verified:</h6>
            <p>{userData?.isVerified ? t("YES") : t("NO")}</p>
          </div>
          <div className="userDetails__item">
            <h6>{t("STATUS")}</h6>
            <p> {userData?.isPauseByAdmin ? t("DE_ACTIVE") : t("ACTIVE")}</p>
          </div>
          <div className="userDetails__item">
            <h6>{t("SELLER")}</h6>
            <p>
              {userData?.isSellerCreated && userData?.isSeller
                ? t("YES")
                : t("NO")}
            </p>
          </div>
          {isSeller && (
            <>
              <div className="userDetails__item">
                <h6>{t("PHONE_NUMBER")}</h6>
                <p>{sellerDetails?.phoneNumber}</p>
              </div>
              <div className="userDetails__item">
                <h6>{t("BIRTH_DATE")}</h6>
                <p>{sellerDetails?.dateOfBirth}</p>
              </div>
              <div className="userDetails__item">
                <h6>{t("SELLER_TYPE")}</h6>
                <p>{sellerDetails?.sellerType}</p>
              </div>
              <div className="userDetails__item">
                <h6>{t("ACCOUNT_HOLDER_NAME")}</h6>
                <p>{sellerDetails?.accountHolderName}</p>
              </div>
              <div className="userDetails__item">
                <h6>{t("BANK_NAME")}</h6>
                <p>{sellerDetails?.bankName}</p>
              </div>
              <div className="userDetails__item">
                <h6>{t("IBAN")}</h6>
                <p>{sellerDetails?.IBAN}</p>
              </div>
              <div className="userDetails__item">
                <h6>{t("TAX_IDENTIFICATION_NUMBER")}</h6>
                <p>
                  {sellerDetails?.taxIdentificationNumber
                    ? sellerDetails?.taxIdentificationNumber
                    : "___"}
                </p>
              </div>
              <div className="userDetails__item">
                <h6>{t("COMPANY_NAME")}</h6>
                <p>
                  {sellerDetails?.companyName
                    ? sellerDetails?.companyName
                    : "___"}
                </p>
              </div>
              <div className="userDetails__item">
                <h6>{t("RESIDENTIAL_ADDRESS")}</h6>
                <p>{sellerDetails?.residentialAddress}</p>
              </div>
              <div className="userDetails__item">
                <h6>{t("CITY")}</h6>
                <p>{sellerDetails?.residentialStreet}</p>
              </div>
              <div className="userDetails__item">
                <h6>{t("ZIP_CODE")}</h6>
                <p>{sellerDetails?.residentialZip}</p>
              </div>
              <div className="userDetails__item">
                <h6>{t("SHOW_ROOM_ADDRESS")}</h6>
                <p>
                  {sellerDetails?.showroomAddress
                    ? sellerDetails?.showroomAddress
                    : "___"}
                </p>
              </div>
              {/* <div className="userDetails__item">
                <h6>Website Or Social Media</h6>
                <img
                  src={sellerDetails?.websiteOrSocialMedia}
                  alt="websiteOrSocialMedia"
                  className=""
                />
              </div> */}
              <div className="userDetails__item">
                <h6>{t("COMPANY_NAME")}</h6>
                <p>
                  {sellerDetails?.companyName
                    ? sellerDetails?.companyName
                    : "___"}
                </p>
              </div>
              <div className="userDetails__item">
                <h6>{t("PRODUCT_CATEGORIES")}</h6>
                <p>
                  {sellerDetails?.productCategories
                    ?.map((item) => item)
                    .join(", ")}
                </p>
              </div>
              <div className="userDetails__item">
                <h6>{t("PREVIOUS_SALES_EXPERIENCE")}</h6>
                <p>
                  {sellerDetails?.previousSalesExperience ? t("YES") : t("NO")}
                </p>
              </div>
              {sellerDetails?.governmentId !== "null" && (
                <div className="userDetails__item">
                  <h6>{t("IDENTIFICATION_PROOF")}</h6>
                  <img
                    src={sellerDetails?.governmentId}
                    alt="governmentId"
                    className=""
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
