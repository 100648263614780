import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ProductService from "../services/ProductService";

// ASYNC THUNKS

// GET ALL PRODUCT BY ID ASYNC THUNK
export const fetchAllProduct = createAsyncThunk(
  "product/fetchAllProduct",
  async (params, thunkAPI) => {
    try {
      return await ProductService.getAllProduct(params); 
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);


// GET ALL PRODUCT BY ID ASYNC THUNK
export const fetchSoldProduct = createAsyncThunk(
  "/product/fetchSoldProduct",
  async (userId, thunkAPI) => {
    try {
      return await ProductService.getAllProduct(userId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// GET PRODUCT BY ID ASYNC THUNK
export const fetchProductById = createAsyncThunk(
  "product/fetchProductById",
  async (productId, thunkAPI) => {
    try {
      return await ProductService.getProductById(productId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// CREATE PRODUCT ASYNC THUNK
export const createProduct = createAsyncThunk(
  "product/createProduct",
  async (productData, thunkAPI) => {
    try {
      return await ProductService.createProduct(productData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// EDIT PRODUCT BY ID
export const updateProductById = createAsyncThunk(
  "product/updateProductById",
  async ({ productId, productData }, thunkAPI) => {
    try {
      return await ProductService.editProductById(productId, productData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// DELETE PRODUCT BY ID
export const removeProductById = createAsyncThunk(
  "product/removeProductById",
  async (productId, thunkAPI) => {
    try {
      return await ProductService.deleteProductById(productId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// VERIFY PRODUCT BY ID ASYNC THUNK
export const verifyProductByIdThunk = createAsyncThunk(
  "product/verifyProductById",
  async (productId, thunkAPI) => {
    try {
      const response = await ProductService.verifyProductById(productId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// GET TIME SLOTS BY PRODUCT ID ASYNC THUNK
export const getTimeSlotsByProductIdThunk = createAsyncThunk(
  "product/getTimeSlotsByProductId",
  async (productId, thunkAPI) => {
    try {
      const response = await ProductService.getTimeSlotsByProductId(productId);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const rejectProduct = createAsyncThunk(
  "product/rejectProduct",
  async (data, thunkAPI) => {
    try {
      const response = await ProductService.rejectProductStatus(data);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// SLICE
const productSlice = createSlice({
  name: "product",
  initialState: {
    allProductData: [],
    productData: [],
    productDetails: {},
    productsUpdated: null,
    verfication: null,
    soldProduct: [],
    timeSlots: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetProductState: (state) => {
      state.productData = [];
      state.error = null;
      state.allProductData = [];
      state.soldProduct = [];
      state.productDetails = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.allProductData = action.payload.data;
      })
      .addCase(fetchAllProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchProductById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProductById.fulfilled, (state, action) => {
        state.loading = false;
        state.productDetails = action.payload.data;
      })
      .addCase(fetchProductById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(createProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.productData = action.payload.data;
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(updateProductById.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProductById.fulfilled, (state, action) => {
        state.loading = false;
        state.productData = action.payload.data;
      })
      .addCase(updateProductById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(removeProductById.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeProductById.fulfilled, (state) => {
        state.loading = false;
        state.productData = null;
      })
      .addCase(removeProductById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // VERIFY PRODUCT BY ID
      .addCase(verifyProductByIdThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyProductByIdThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.verfication = action?.payload;
      })
      .addCase(verifyProductByIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Product verification failed";
      })

      .addCase(getTimeSlotsByProductIdThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTimeSlotsByProductIdThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.timeSlots = action.payload.data;
      })
      .addCase(getTimeSlotsByProductIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch product time slots";
      })
      .addCase(rejectProduct.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = null;
      })
      .addCase(rejectProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.productsUpdated = action?.payload;
      })
      .addCase(rejectProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage =
          action.payload || "Failed to reject product status";
      });
  },
});

// EXPORT ACTIONS
export const { resetProductState } = productSlice.actions;

// EXPORT REDUCER
export default productSlice.reducer;
