import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export function authHeader() {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
}

// CREATE CART ITEM
async function createCartItem(cartData) {
  try {
    const { data } = await axios.post(`${baseURL}/add-cart`, cartData, {
      headers: {
        ...authHeader(),
        "Content-Type": "multipart/form-data",
      },
    });

    if (!data.status) {
      throw new Error(data.message || "Failed to add item to cart");
    }

    return data;
  } catch (e) {
    if (e.response?.data?.statusCode !== 200) {
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
      window.location.assign("/login");
      throw new Error("Invalid JWT token detected.");
    } else {
      throw new Error(
        e.response?.data?.message ||
          e.response?.data?.Error ||
          e.message ||
          "An error occurred while adding item to cart"
      );
    }
  }
}

// GET ALL CART ITEMS BY USER ID
async function getAllCartItemsByUserId() {
  try {
    const { data } = await axios.get(`${baseURL}/get-cart`, {
      headers: authHeader(),
    });

    if (!data.status) {
      throw new Error(data.message || "Failed to fetch cart items");
    }

    return data;
  } catch (e) {
    if (e.response?.data?.statusCode !== 200) {
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
      window.location.reload();
      throw new Error("Invalid JWT token detected.");
    } else {
      throw new Error(e.message || "An error occurred.");
    }
  }
}

// DELETE CART ITEM BY ID
async function deleteCartItemById(cartItemId) {
  try {
    const { data } = await axios.delete(`${baseURL}/cart/${cartItemId}`, {
      headers: authHeader(),
    });

    if (!data.status) {
      throw new Error(data.message || "Failed to delete cart item");
    }

    return data;
  } catch (e) {
    if (e.response?.data?.statusCode !== 200) {
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
      window.location.assign("/login");
      throw new Error("Invalid JWT token detected.");
    } else {
      throw new Error(
        e.response?.data?.message ||
          e.response?.data?.Error ||
          e.message ||
          "An error occurred while deleting cart item"
      );
    }
  }
}

// UPDATE CART ITEM BY ID
async function updateCartItemById(cartItemId, cartData) {
  try {
    const { data } = await axios.put(
      `${baseURL}/cart/${cartItemId}`,
      cartData,
      {
        headers: {
          ...authHeader(),
          "Content-Type": "application/json",
        },
      }
    );

    if (!data.status) {
      throw new Error(data.message || "Failed to update cart item");
    }

    return data;
  } catch (e) {
    if (e.response?.data?.statusCode !== 200) {
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
      window.location.assign("/login");
      throw new Error("Invalid JWT token detected.");
    } else {
      throw new Error(
        e.response?.data?.message ||
          e.response?.data?.Error ||
          e.message ||
          "An error occurred while updating cart item"
      );
    }
  }
}

// EXPORT CARTSERVICE
const CartService = {
  createCartItem,
  getAllCartItemsByUserId,
  deleteCartItemById,
  updateCartItemById,
};

export default CartService;
