import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./TermsAndConditions.scss";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TermsAndConditionsPopUp from "../../pages/public/TermAndConditions/TermsAndConditionsPopUp";

const TermsAndConditionsModal = ({
  show,
  onHide,
  onProceed,
  termsAnsConditionLoading,
}) => {
  const modalRef = useRef(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isAccepted, setIsAccepted] = useState(false);

  const handleCheckboxChange = () => {
    setIsAccepted(!isAccepted);
  };

  const handleProceed = () => {

    if (isAccepted) {
      onProceed();
      // onHide();
      setIsAccepted(false);
      navigate("/")
      return;
    }
    toast.error(t("TERMS_AND_CONDITION_ERROR"));
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onHide();
    }
  };

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (show) {
      // Prevent background scroll when modal opens
      document.body.style.overflow = 'hidden';
    } else {
      // Re-enable background scroll when modal closes
      document.body.style.overflow = 'unset';
    }

    return () => {
      // Cleanup: re-enable scroll when component unmounts
      document.body.style.overflow = 'unset';
    };
  }, [show]);

  if (!show) return null;

  return (
    <div className="tnd-modal-overlay" ref={modalRef}>
      <div className="tnd-modal-content" >
        <div className="tnd-modal-header">
          <h2>{t("TERMS_AND_CONDITIONS")}</h2>
          <button
            className="tnd-close-button"
            onClick={() => {
              onHide();
              setIsAccepted(false);
            }}
          >
            &times;
          </button>
        </div>
        <div className="tnd-modal-body">
          <TermsAndConditionsPopUp/>
          <div className="tnd-modal-checkbox">
            <label>
              <input
                type="checkbox"
                checked={isAccepted}
                onChange={handleCheckboxChange}
              />
              {t("ACCEPT_TERMS_AND_CONDITION")}
            </label>
          </div>
        </div>
        <div className=" btnPart tnd-modal-footer">
          <Button
            className="btn_dark"
            onClick={() => {
              onHide();
              setIsAccepted(false);
            }}
          >
            {t("CLOSE")}
          </Button>
          <Button className="btn_dark" onClick={handleProceed}>
            {termsAnsConditionLoading ? "Loading..." : t("PROCEED")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsModal;
