import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export function authHeader() {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
}

// ADD FAVORITE ITEM
async function addFavoriteItem(productId) {
  try {
    const { data } = await axios.post(
      `${baseURL}/products/${productId}/favorite`,
      {},
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    if (!data.status) {
      throw new Error(data.message || "Failed to add favorite item");
    }

    return data;
  } catch (e) {
    if (e.response?.data?.statusCode !== 200) {
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
      window.location.assign("/login");
      throw new Error("Invalid JWT token detected.");
    } else {
      throw new Error(
        e.response?.data?.message ||
          e.response?.data?.Error ||
          e.message ||
          "An error occurred while adding favorite item"
      );
    }
  }
}


// DELETE FAVORITE ITEM BY ID
async function deleteFavoriteItemById(favoriteItemId) {
  try {
    const { data } = await axios.delete(
      `${baseURL}/favorites/${favoriteItemId}`,
      {
        headers: authHeader(),
      }
    );

    if (!data.status) {
      throw new Error(data.message || "Failed to delete favorite item");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
        e.response?.data?.Error ||
        e.message ||
        "An error occurred while deleting favorite item"
    );
  }
}

const removeFavoriteByProductId = async (productId) => {
  try {
    const response = await axios.post(
      `${baseURL}/products/${productId}/favorite`,
      {},
      {
        headers: authHeader(),
      }
    );

    if (response.status !== 200) {
      throw new Error(response.data.message || "Failed to remove favorite");
    }

    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message ||
        error.response?.data?.Error ||
        error.message ||
        "An error occurred while removing from favorites"
    );
  }
};

// EXPORT FAVORITESERVICE
const FavoriteService = {
  addFavoriteItem,
  removeFavoriteByProductId,
  deleteFavoriteItemById,
};

export default FavoriteService;
