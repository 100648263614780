import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosSearch } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import noImageFound from "../../../assets/images/noProductFound.png";
import FilterPopup from "../../../component/FilterPopup/FilterPopup";
import SectionHeader from "../../../component/SectionHeader/SectionHeader";
import {
  clearFavorite,
  renderFavorite,
} from "../../../store/actions/favoritesAction";

import icons from "../../../assets/images/svg/icons";
import "./FavoriteProducts.scss";
// import { deleteOffersWithoutChatByUserId } from "./../../../redux/slices/offerSlice";
import { Box, Button } from "@mui/material";
import { removeFavoriteByProductId } from "../../../redux/slices/favoriteSlice";
import { fetchFavoriteProduct } from "../../../redux/slices/userSlice";

const FavoriteProducts = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [searchTerm] = useState("");
  const [favoriteProducts, setFavoriteProducts] = useState([]);
  const [originalFavoriteProducts, setOriginalFavoriteProducts] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [loading, setLoading] = useState(false);


  const handleCloseBrandFilter = () => {
    setShowFilterPopup(!showFilterPopup);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1100);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 1100);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchFavoriteProducts = async () => {
    try {
      setLoading(true);
      const userId = parseInt(userData.id);
      const response = await dispatch(fetchFavoriteProduct(userId));

      if (response?.payload?.data) {
        const data = response.payload?.data;
        setOriginalFavoriteProducts(data);
        setFavoriteProducts(data);
        dispatch(renderFavorite(data?.length));
      } else {
        setOriginalFavoriteProducts([]);
        setFavoriteProducts([]);
      }
    } catch (error) {
      toast.error(t("ERROR_FETCHING_FAVORITE_PRODUCTS"));
      console.error("Error fetching favorite products:", error);
      setOriginalFavoriteProducts([]);
      setFavoriteProducts([]);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveFavorite = async (productId) => {
    try {
      const response = await dispatch(removeFavoriteByProductId(productId));
      if (response.statusCode === 200) {
        toast.success(t(response.data.message));
        dispatch(clearFavorite(1));
      }
      fetchFavoriteProducts();
    } catch (error) {
      toast.error(t("ERROR_IN_REMOVING_FROM_FAVORITES"));
      console.error("Error removing favorite:", error);
    }
  };

  const applyFilters = () => {
    if (!Array.isArray(favoriteProducts)) return;

    let filteredResults = [...originalFavoriteProducts];

    if (selectedBrands.length > 0) {
      filteredResults = filteredResults.filter((item) =>
        selectedBrands.includes(item.product.brand)
      );
    }

    if (searchTerm !== "") {
      const searchTermLower = searchTerm.toLowerCase();
      filteredResults = filteredResults.filter(
        (item) =>
          item.product.title.toLowerCase().includes(searchTermLower) ||
          item.product.brand.toLowerCase().includes(searchTermLower) ||
          item.product.price.toString().includes(searchTerm)
      );
    }

    setFavoriteProducts(filteredResults);
  };

  useEffect(() => {
    fetchFavoriteProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchTerm === "" && selectedBrands.length === 0) {
      setFavoriteProducts(originalFavoriteProducts);
    } else {
      applyFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrands, searchTerm]);

  const { HeartFill } = icons;

  return (
    <>
      <main className="SearchResultsWrapper">
        <Box className="container">
          <SectionHeader sectionTitle="Favorite Products" />

          {isMobile && (
            <div className="search-container">
              <div className="search-span-icon">
                <IoIosSearch className="icon" />
              </div>
              <input type="text" placeholder={t("Cherche ici...")} />
            </div>
          )}

          <div className="FavouriteProductsContainer">
            <div className="searchFilterBlock">
              {selectedBrands.length > 0 ? (
                <span className="filter-count">{selectedBrands.length}</span>
              ) : (
                ""
              )}

              <FilterPopup
                isOpen={showFilterPopup}
                onClose={handleCloseBrandFilter}
                selectedBrands={selectedBrands}
                setSelectedBrands={setSelectedBrands}
              />
            </div>

            {favoriteProducts?.length > 0 ? (
              <div className="product-list">
                {favoriteProducts.map(({ product }) => (
                  <div className="productItem" key={product.id}>
                    <div className="productItem__img">
                      <img
                        src={product.productImage?.[0]}
                        alt={product.title}
                        className="product-image"
                        onClick={() => navigate(`/product/${product.id}`)}
                      />
                    </div>
                    <div className="productItem__img-btn">
                      <HeartFill
                        className="icon"
                        onClick={() => handleRemoveFavorite(product.id)}
                      />
                    </div>
                    <div className="titlePrice">
                      <h4>{product.title}</h4>
                      <h4>{product.price} MAD</h4>
                    </div>
                    <Button
                      className="btn_dark view_details_btn"
                      onClick={() => navigate(`/product/${product.id}`)}
                    >
                      {t("VIEW_DETAILS")}
                    </Button>
                  </div>
                ))}
              </div>
            ) : (
              !loading && (
                <div className="noDataBlock">
                  <img
                    src={noImageFound}
                    alt={t("NoResultFound")}
                    className="no-image-placeholder"
                  />
                  <p className="noDataBlock">{t("NO_PRODUCTS_FOUND")}</p>
                </div>
              )
            )}
          </div>
          {loading && (
            <div className="loader-wrapper">
              <div className="loader"></div>
              <p>Loading...</p>
            </div>
          )}
        </Box>
      </main>
    </>
  );
};

export default FavoriteProducts;
