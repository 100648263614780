import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./About.scss";

function About() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="AboutMainContainer">
      <div className="container">
        <h2>{t("ABOUT_US")}</h2>
        <div>
          <h3>{t("WHO_ARE_WE")}</h3>
          <p>{t("WHO_ARE_WE_DESCRIPTION")}</p>
          <p>{t("WHO_ARE_WE_DESCRIPTION_2")}</p>
          <p>{t("WHO_ARE_WE_DESCRIPTION_3")}</p>
        </div>
        <div className="about-block">
          <h3>{t("OUR_COMMITMENT")}</h3>
          <p>{t("OUR_COMMITMENT_DESCRIPTION")}</p>
          <ul>
            <li>
              <p>
                <strong>{t("COMMITMENT_1")}</strong>
                {t("COMMITMENT_1_DESCRIPTION")}
              </p>
            </li>
            <li>
              <p>
                <strong>{t("COMMITMENT_2")}</strong>
                {t("COMMITMENT_2_DESCRIPTION")}
              </p>
            </li>
          </ul>
        </div>
        <p className="description">{t("WHO_ARE_WE_DESCRIPTION_4")}</p>
        {/* <div>
          <h3>{t("TRENDIES_BENEFIT")}</h3>
          <ul>
            <li>
              <p>
                <strong>{t("TRENDIES_BENEFIT_1")}</strong>
                {t("TRENDIES_BENEFIT_1_DESC")}
              </p>
            </li>
            <li>
              <p>
                <strong>{t("TRENDIES_BENEFIT_2")}</strong>
                {t("TRENDIES_BENEFIT_2_DESC")}
              </p>
            </li>
            <li>
              <p>
                <strong>{t("TRENDIES_BENEFIT_3")}</strong>
                {t("TRENDIES_BENEFIT_3_DESC")}
              </p>
            </li>
            <li>
              <p>
                <strong>{t("TRENDIES_BENEFIT_4")}</strong>
                {t("TRENDIES_BENEFIT_4_DESC")}
              </p>
            </li>
            <li>
              <p>
                <strong>{t("TRENDIES_BENEFIT_5")}</strong>
                {t("TRENDIES_BENEFIT_5_DESC")}
              </p>
            </li>
            <li>
              <p>
                <strong>{t("TRENDIES_BENEFIT_6")}</strong>
                {t("TRENDIES_BENEFIT_6_DESC")}
              </p>
            </li>
          </ul>
        </div> */}
        {/* <div>
          <p>{t("ABOUT_LAST")}</p>
        </div> */}
        {/* <div>
          <h3>{t("Benefits.Title")}</h3>
          <p>{t("Benefits.Heading")}</p>
          <ul>
            <li>
              <h4>{t("Benefits.Advantage.Advantage_One")}</h4>
              <p>{t("Benefits.Advantage.Advantage_Content")}</p>
            </li>

            <li>
              <h4>{t("Benefits.Advantage.Advantage_Two")}</h4>
              <p>{t("Benefits.Advantage.Advantage_Two_Content")}</p>
            </li>

            <li>
              <h4>{t("Benefits.Advantage.Advantage_Three")}</h4>
              <p>{t("Benefits.Advantage.Advantage_Three_Content")}</p>
            </li>

            <li>
              <h4>{t("Benefits.Advantage.Advantage_Four")}</h4>
              <p>{t("Benefits.Advantage.Advantage_Four_Content")}</p>
            </li>

            <li>
              <h4>{t("Benefits.Advantage.Advantage_Five")}</h4>
              <p>{t("Benefits.Advantage.Advantage_Five_Content")}</p>
            </li>

            
            <li>
              <h4>{t("Benefits.Advantage.Advantage_Six")}</h4>
              <p>{t("Benefits.Advantage.Advantage_Six_Content")}</p>
            </li>
          </ul>
        
        </div>
        <p>{t("Benefits.Footer")}</p> */}
      </div>
    </main>
  );
}

export default About;
