import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Journey.scss";

const Journey = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main className="JourneyMainWrapper">
        <div className="container">
          {/* Introduction */}
          <section className="introduction">
            <h1 className="section-title">{t("journey.title")}</h1>
            <p className="intro-text">{t("journey.introduction")}</p>
          </section>

          {/* Our Journey Timeline */}
          <section className="timeline">
            <h2 className="section-title">{t("journey.milestones.title")}</h2>
            {["2018", "2019", "2021", "2023"].map((year, index) => (
              <div className="timeline-item" key={index}>
                <div className="timeline-date">{year}</div>
                <div className="timeline-content">
                  <h3>{t(`journey.milestones.${year}.title`)}</h3>
                  <p>{t(`journey.milestones.${year}.description`)}</p>
                </div>
              </div>
            ))}
          </section>

          {/* Image Gallery */}
          <section className="gallery">
            <h2 className="section-title">{t("journey.galleryTitle")}</h2>
            <div className="gallery-grid">
              {[1, 2, 3, 4].map((num) => (
                <div className="gallery-item" key={num}>
                  <img
                    src={t(`journey.galleryImages.${num}.src`)}
                    alt={t(`journey.galleryImages.${num}.alt`)}
                  />
                </div>
              ))}
            </div>
          </section>

          {/* Additional Content Section */}
          <section className="additional-content">
            <div className="quote">
              <p>{t("journey.quote")}</p>
            </div>

            <div className="team">
              <h3>{t("journey.team.title")}</h3>
              <p>{t("journey.team.description")}</p>
              <div className="team-members">
                {[1, 2, 3].map((num) => (
                  <div className="team-member" key={num}>
                    <img
                      src={t(`journey.team.members.${num}.image`)}
                      alt={t(`journey.team.members.${num}.name`)}
                    />
                    <p>{t(`journey.team.members.${num}.name`)}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/* Call to Action */}
          <section className="cta">
            <h2>{t("journey.cta.title")}</h2>
            <p>{t("journey.cta.description")}</p>
            <a href="/contact-us" className="cta-button">
              {t("journey.cta.buttonText")}
            </a>
          </section>
        </div>
      </main>
    </>
  );
};

export default Journey;
