import axios from "axios";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { decrementCartCount } from "../../../store/actions/cartAction";

import { Box, Button, Typography } from "@mui/material";
import { IoIosSearch } from "react-icons/io";
import noImageFound from "../../../assets/images/noProductFound.png";
import SectionHeader from "../../../component/SectionHeader/SectionHeader";
import { fetchAllCartItems } from "../../../redux/slices/cartSlice";

import "./CartPage.scss";

const CartPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");
  const [searchTerm] = useState("");
  const [productsInCart, setProductsInCart] = useState([]);
  const [originalProductsInCart, setOriginalProductsInCart] = useState([]);
  const [selectedBrands] = useState([]);
  // const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1100);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 1100);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchProductsInCart = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-cart`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.status === 200) {
        const data = response.data.data;
        setProductsInCart(data);
        setOriginalProductsInCart(data);
      }
    } catch (error) {
      console.error("Error fetching cart products:", error);
      if (error.response?.data?.statusCode !== 200) {
        localStorage.removeItem("token");
        localStorage.removeItem("userData");
        navigate("/login");
      } else {
        throw new Error(error.message || "An error occurred.");
      }
    } finally {
      setLoading(false);
    }
  };

  const applyFilters = () => {
    if (!Array.isArray(originalProductsInCart)) return;

    let filteredResults = originalProductsInCart;

    // Filter by selected brands
    if (selectedBrands.length > 0) {
      filteredResults = filteredResults.filter(({ product }) =>
        selectedBrands.includes(product.brand)
      );
    }

    // Filter by search term
    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase();
      filteredResults = filteredResults.filter(
        ({ product }) =>
          product.title.toLowerCase().includes(searchTermLower) ||
          product.brand.toLowerCase().includes(searchTermLower) ||
          product.price.toString().includes(searchTerm)
      );
    }

    setProductsInCart(filteredResults);
  };

  const handleRemoveFromCart = async (productId) => {
    try {
      if (productId !== "") {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/cart/${productId}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response?.status === 200) {
          // toast.success(t(response.data.message));
          dispatch(fetchAllCartItems());
          dispatch(decrementCartCount(1));
          fetchProductsInCart();
        }
      }
    } catch (error) {
      toast.error(t("ERROR_IN_REMOVING_FROM_CART"));
      console.error(error);
      if (error.response?.data?.statusCode !== 200) {
        localStorage.removeItem("token");
        localStorage.removeItem("userData");
        navigate("/login");
      } else {
        throw new Error(error.message || "An error occurred.");
      }
    }
  };

  useEffect(() => {
    fetchProductsInCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchTerm === "" && selectedBrands.length === 0) {
      fetchProductsInCart();
    } else {
      applyFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrands, searchTerm]);

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrands, searchTerm]);

  return (
    <>
      <main className="CartPageMainWrapper">
        <Box className="container">
          <SectionHeader sectionTitle="Products In Cart" />
          <div className="CartProductContainer">
            {isMobile && (
              <div className="search-container">
                <div className="search-span-icon">
                  <IoIosSearch className="icon" />
                </div>
                <input type="text" placeholder={t("Cherche ici...")} />
              </div>
            )}

            {productsInCart?.length > 0 ? (
              <div className="ProductList">
                {productsInCart.map(({ product }) => (
                  <>
                    <div className="productItem" key={product.id}>
                      <div className="productItem__img">
                        <img
                          src={product.productImage[0]}
                          alt={product.title}
                          className="product-image"
                        />
                      </div>
                      <div className="titlePrice">
                        <Typography variant="h4">{product.title}</Typography>
                        <Typography variant="body1">
                          {product.price} MAD{" "}
                        </Typography>
                      </div>
                      {/* <p style={{ fontSize: 15 }}>{product.description}</p> */}
                      <div className="CartBtnContainer">
                        <Button
                          className="btn_dark"
                          onClick={() => navigate(`/payment/${product.id}`)}
                        >
                          {t("BUY_NOW")}
                        </Button>
                        <Button
                          className="btn_dark"
                          onClick={() => handleRemoveFromCart(product.id)}
                        >
                          {t("REMOVE_FROM_CART")}
                        </Button>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            ) : (
              !loading && (
                <div className="noDataBlock">
                  <img
                    src={noImageFound}
                    alt={t("NoResultFound")}
                    className="no-image-placeholder"
                  />
                  <p className="noDataBlock">{t("NO_PRODUCTS_FOUND")}</p>
                </div>
              )
            )}
          </div>
          {loading && (
            <div className="loader-wrapper">
              <div className="loader"></div>
              <p>Loading...</p>
            </div>
          )}
        </Box>
      </main>
    </>
  );
};

export default CartPage;
