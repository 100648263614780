import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CiLogout } from "react-icons/ci";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import noImageFound from "../../../assets/images/noProductFound.png";
import { ReactComponent as Delete } from "../../../assets/svg/Delete.svg";
import { ReactComponent as Edit } from "../../../assets/svg/Edit.svg";
import { ReactComponent as LockIcon } from "../../../assets/svg/Lock.svg";
import { ReactComponent as MailSmall } from "../../../assets/svg/MailSmall.svg";
import { ReactComponent as Save } from "../../../assets/svg/save.svg";
import { ReactComponent as UserSmall } from "../../../assets/svg/UserSmall.svg";
import SellerFlow from "../../../component/SellerFlow/SellerFlow";
import SoldProductsList from "../../../component/SoldProductList/SoldProductList";
// import { deleteOffersWithoutChatByUserId } from "../../../redux/slices/offerSlice";
import { IoIosEyeOff } from "react-icons/io";
import { IoEyeOutline } from "react-icons/io5";
import BuyerFlow from "../../../component/BuyerFlow/BuyerFlow";
import { fetchAllProduct } from "../../../redux/slices/productSlice";
import { fetchUserById, updateUserById } from "../../../redux/slices/userSlice";
import ConfirmDeleteModal from "../../../utils/ConfirmDeleteProduct/ConfirmDeleteModal";
import EditProductModal from "../../../utils/EditProduct/EditProductModal";
import ResetPassword from "../../public/ResetPassword/ResetPassword";
import "./ProfilePage.scss";

const ProfilePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userData"))
  );
  const [soldProducts, setSoldProducts] = useState([]);
  const updatedUserData = useSelector((state) => state.user.userData);

  // const [genderValue, setGenderValue] = React.useState(t("female"));
  // const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [unverifiedProduct, setUnverifiedProduct] = useState([]);
  const [emailError, setEmailError] = useState(null);
  const [nickNameError, setNickNameError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [userDetails, setUserDetails] = useState({
    username: "",
    nickname: "",
    email: "",
  });
  const [isEditing, setIsEditing] = useState({
    username: false,
    nickname: false,
    email: false,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [userPassword, setUserPassword] = useState(null);
  const [currentPasswordStatus, setCurrentPasswordStatus] = useState(null);
  const [allSellerProducts, setAllSellerProducts] = useState([]);
  const [soldProduct, setSoldProduct] = useState([]);

  const [allSellerProductLoading, setAllSellerProductLoading] = useState(false);
  const [productsLoading, setProductsLoading] = useState(false);
  const [soldProductLoading, setSoldProductLoading] = useState(false);
  const [errors, setErrors] = useState({
    username: "",
    email: "",
    nickname: "",
    password: "",
  });
  // const [originalDetails, setOriginalDetails] = useState({});

  useEffect(() => {
    if (userDetails) {
      // setOriginalDetails(userDetails);
    }
  }, [userDetails]);

  const validate = (field) => {
    let errorMessage = "";

    switch (field) {
      case "username":
        if (!userDetails.username.trim()) {
          errorMessage = t("USER_NAME_REQUIRED");
        }
        break;
      case "email":
        errorMessage = !userDetails.email.trim()
          ? t("email_required")
          : !/^[a-z0-9._%+-]+@(gmail\.com|yahoo\.com|hotmail\.com|\.in)$/.test(
              userDetails.email.toLowerCase()
            )
          ? t("invalid_email_format")
          : "";
        break;
      case "nickname":
        break;
      case "password":
        if (!userPassword.trim()) {
          errorMessage = t("PASSWORD_REQUIRED");
        } else if (userPassword.length < 6) {
          errorMessage = t("PASSWORD_AT_LEAST_SIX_CHAR");
        }
        break;
      default:
        break;
    }

    setErrors((prevState) => ({
      ...prevState,
      [field]: errorMessage,
    }));

    return errorMessage;
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
    navigate("/");
  };

  const toggleEdit = async (field) => {
    const validationError = validate(field);
    if (validationError) {
      setTimeout(() => {
        setErrors({
          username: "",
          email: "",
          nickname: "",
          password: "",
        });
      }, 1500);
      return;
    }

    // If we're currently editing and trying to save
    if (isEditing[field]) {
      // Only make API call if value has actually changed
      const hasChanged = userDetails[field]?.trim() !== userData[field]?.trim();

      if (hasChanged) {
        await handleEditProfile(field);
      }
    }

    // Toggle edit state regardless
    setIsEditing((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleEditProfile = async (field) => {
    const updatedValue = { [field]: userDetails[field] };
    try {
      const response = await dispatch(updateUserById(updatedValue));
      if (
        response.payload.statusCode === 200 ||
        response.payload.statusCode === 201
      ) {
        setUserDetails({
          ...userDetails,
          userName:
            response?.payload?.data?.user?.username ||
            response?.payload?.data?.user?.isSocialName,
        });

        if (field === "email") {
          setEmailError(
            t("UPDATE_PROFILE")
              .replace("option", response?.payload?.data?.option)
              .replace("days", response?.payload?.data?.days)
          );
        }

        if (field === "nickname") {
          // if (userData?.nickname === userDetails.nickname) {
          //   return
          // }

          if (response?.payload?.data?.days) {
            setNickNameError(
              t("UPDATE_PROFILE")
                .replace("option", response?.payload?.data?.option)
                .replace("days", response?.payload?.data?.days)
            );
          }
        }
        getUserDetails();
        setTimeout(() => {
          setNickNameError(null);
          setEmailError(null);
        }, 3000);
      }

      if (response?.status === 200) {
        toast.success(t("PROFILE_UPDATED_SUCCESS"));
        const updatedUser = response.payload?.data?.user;
        localStorage.setItem("userData", JSON.stringify(updatedUser));
      }
    } catch (error) {
      toast.error("Error updating profile!");
      console.error(`Error updating ${field}:`, error);
    }
  };

  const getProducts = async () => {
    getAllSellerProducts();
    try {
      setProductsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-all-products/${userData.id}`
      );
      if (response?.status === 200) {
        // const unverifiedProducts =
        //   response?.data?.data?.length > 0 &&
        //   response?.data?.data.filter(
        //     (item) => !item.isVerified && userData.id === item?.userId
        //   );
        // setProducts(unverifiedProducts);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setProductsLoading(false);
    }
  };

  const getAllSellerProducts = async () => {
    try {
      setAllSellerProductLoading(true);
      const response = await dispatch(fetchAllProduct());

      if (response?.payload?.statusCode === 200) {
        const verifiedProducts =
          response?.payload?.data?.length > 0 &&
          response?.payload?.data.filter(
            (item) => item.isVerified && item.isSold !== true && userData.id === item?.userId
          );
        const unVerifiedProducts =
          response?.payload?.data?.length > 0 &&
          response?.payload?.data.filter(
            (item) => !item.isVerified && userData.id === item?.userId
          );

        setUnverifiedProduct(unVerifiedProducts);
        setAllSellerProducts(verifiedProducts);

        if (response?.payload?.data) {
          const userIdToFilter = userData?.id;
          const filtered = response.payload.data.filter(
            (item) => item.userId === userIdToFilter && item.isSold === true
          );

          setSoldProduct(filtered);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setAllSellerProductLoading(false);
    }
  };

  const handleEditProduct = (product) => {
    setSelectedProduct(product);
    setShowEditModal(true);
  };

  const getSoldProducts = async () => {
    try {
      setSoldProductLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/sold-product/${userData?.id}`
      );

      if (response?.status === 200) {
        const unverifiedProducts = response?.data?.data?.products;
        setSoldProducts(unverifiedProducts);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSoldProductLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
    getSoldProducts();
    getAllSellerProducts();
    getUserPassword();
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserDetails = async () => {
    try {
      setLoading(true);
      const response = await dispatch(fetchUserById());
      const responseData = response?.payload?.data;

      const updateData = {
        username: responseData?.username || responseData?.isSocialName || "",
        nickname: responseData?.nickname || "",
        email: responseData?.email || "",
      };
      console.log("updateData", updateData);
      setUserData(responseData);
      setUserDetails(updateData);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  async function getUserPassword() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/decrypt/${userData?.id}`
      );

      if (response?.status === 200) {
        const decryptedPassword = response?.data?.data?.decryptedPassword;
        setUserPassword(decryptedPassword);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <main className="ProfileMainWrapper">
        <div className="container">
          <div className="profile-section">
            {userData?.image ? (
              <img
                src={userData?.image}
                alt="Profile"
                className="profile-pic"
              />
            ) : (
              <UserSmall className="profile-pic" />
            )}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <h2>{!loading && userData?.username}</h2>
              <p>{!loading && userData?.email}</p>
            </Box>
          </div>
          <div className="profileSectionBox">
            <Box className="profileSectionBoxGrid">
              <div className="profileSectionBox__inner">
                <h3>{t("ACCOUNT_SETTINGS")}</h3>
                <div className="account-settings">
                  <div>
                    <label className="label-text">{t("USER_NAME")}</label>
                    <div className="account-detail">
                      <UserSmall />
                      <div className="account-detail_inner">
                        {isEditing?.username ? (
                          <>
                            <input
                              autoFocus={true}
                              type="text"
                              name="username"
                              value={userDetails.username.trimStart()}
                              onChange={(e) => {
                                if (userDetails.username === "") {
                                  toast.error("cant be empty");
                                }
                                setUserDetails({
                                  ...userDetails,
                                  username: e.target.value,
                                });
                                setErrors({
                                  ...errors,
                                  username: "",
                                });
                              }}
                            />
                            <Save
                              onClick={() => toggleEdit("username")}
                              className="editIcon"
                            />
                          </>
                        ) : (
                          <>
                            <span>{userDetails.username}</span>
                            <Edit
                              onClick={() => toggleEdit("username")}
                              className="editIcon"
                            />
                          </>
                        )}
                      </div>
                    </div>
                    {errors.username && (
                      <span className="error-message">{errors.username}</span>
                    )}
                  </div>

                  <div>
                    <label className="label-text">{t("EMAIL")}</label>
                    <div className="account-detail">
                      <MailSmall />
                      <div className="account-detail_inner">
                        {isEditing?.email ? (
                          <>
                            <input
                              autoFocus={true}
                              type="text"
                              name="email"
                              value={userDetails?.email.trimStart()}
                              onChange={(e) => {
                                setUserDetails({
                                  ...userDetails,
                                  email: e.target.value,
                                });
                                setErrors({
                                  ...errors,
                                  email: "",
                                });
                              }}
                            />
                            <Save
                              onClick={() => toggleEdit("email")}
                              className="editIcon"
                            />
                          </>
                        ) : (
                          <>
                            <span>{userDetails?.email}</span>
                            <Edit
                              onClick={() => toggleEdit("email")}
                              className="editIcon"
                            />
                          </>
                        )}
                      </div>
                    </div>
                    {errors.email && (
                      <span className="error-message">{errors.email}</span>
                    )}
                    {emailError && (
                      <span className="error-message">{emailError}</span>
                    )}
                  </div>

                  <div>
                    <label className="label-text">{t("NICK_NAME")}</label>
                    <div className="account-detail">
                      <UserSmall />
                      <div className="account-detail_inner">
                        {isEditing.nickname ? (
                          <>
                            <input
                              autoFocus={true}
                              type="text"
                              name="nickname"
                              placeholder={t("NICK_NAME")}
                              value={userDetails?.nickname.trimStart()}
                              onChange={(e) => {
                                setUserDetails({
                                  ...userDetails,
                                  nickname: e.target.value,
                                });
                                setErrors({
                                  ...errors,
                                  nickname: "",
                                });
                              }}
                            />
                            <Save
                              onClick={() => toggleEdit("nickname")}
                              className="editIcon"
                            />
                          </>
                        ) : (
                          <>
                            <span>
                              {userDetails?.nickname || t("nickname")}
                            </span>
                            <Edit
                              onClick={() => toggleEdit("nickname")}
                              className="editIcon"
                            />
                          </>
                        )}
                      </div>
                    </div>
                    {errors.nickname && (
                      <span className="error-message">{errors.nickname}</span>
                    )}
                    {nickNameError && (
                      <span className="error-message">{nickNameError}</span>
                    )}
                  </div>

                  <div>
                    <label className="label-text">{t("PASSWORD")}</label>
                    <div className="account-detail">
                      <LockIcon className="icon" />
                      <div className="account-detail-user-password">
                        {userPassword ? (
                          <>
                            <input
                              type={showPassword ? "text" : "password"}
                              name="confirmPassword"
                              value={userPassword}
                              required
                              placeholder="******"
                              className="input-with-icon"
                              disabled={true}
                            />
                            <span
                              className="EyeIcon"
                              onClick={togglePasswordVisibility}
                            >
                              {showPassword ? (
                                <IoEyeOutline size={"1.5rem"} />
                              ) : (
                                <IoIosEyeOff size={"1.5rem"} />
                              )}
                            </span>
                            <Edit
                              onClick={() => {
                                setCurrentPasswordStatus(
                                  userPassword
                                    ? "update_password"
                                    : "add_password"
                                );
                                setChangePassword(true);
                              }}
                              className="editIcon"
                            />
                          </>
                        ) : (
                          <div className="account-detail-user-password">
                            <p
                              onClick={() => {
                                setChangePassword(true);
                              }}
                              className="add-password"
                            >
                              <p>{t("ADD_PASSWORD")}</p>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    {errors.password && (
                      <span className="error-message">{errors.password}</span>
                    )}
                  </div>

                  <div className="btnPart seller-button">
                    {!updatedUserData?.isSeller &&
                      !updatedUserData?.isSellerCreated && (
                        <Button
                          className="btn_dark"
                          onClick={() => navigate("/seller-profile")}
                        >
                          {t("CREATE_SELLER_PROFILE")}
                        </Button>
                      )}
                    <Button className="btn_dark" onClick={() => handleLogout()}>
                      <CiLogout />
                      {t("LOGOUT")}
                    </Button>
                  </div>
                </div>
              </div>

              {userData?.isSeller !== false ? (
                <>
                  <div className="profileSectionBox__inner ProductListing">
                    <h3>{t("Listing_Pending")}</h3>
                    <Box className="AccountSettingsWrapper">
                      <div className="account-settings">
                        {productsLoading ? (
                          "Loading..."
                        ) : unverifiedProduct?.length > 0 ? (
                          unverifiedProduct?.map((product) => (
                            <div
                              className="account-detail product"
                              key={product?.id}
                            >
                              <div className="product-image-container">
                                <img
                                  src={product?.productImage[0]}
                                  alt="product_image"
                                  className="product-image"
                                />
                              </div>
                              <div className="product-detail-container">
                                <Box className="product-detail-container-inner">
                                  <Box className="product-detail-header">
                                    <Typography
                                      variant="body1"
                                      className="product-title"
                                      onClick={() =>
                                        navigate(`/product/${product.id}`)
                                      }
                                    >
                                      {product.title}
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      className="product_description"
                                    >
                                      {product.description}
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      className={`product-status ${
                                        product.status === "reject"
                                          ? "reject"
                                          : "pending"
                                      }`}
                                    >
                                      {product.status === "reject"
                                        ? t("REJECTED")
                                        : t("PENDING")}
                                    </Typography>
                                  </Box>
                                </Box>

                                <div className="product-action">
                                  <Button className="EditIconBtn">
                                    <Edit
                                      className="editIcon"
                                      onClick={() => handleEditProduct(product)}
                                    />
                                  </Button>
                                  <Button className="DeleteIconBtn">
                                    <Delete
                                      onClick={() =>
                                        setShowConfirmDelete(product.id)
                                      }
                                    />
                                  </Button>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="noDataBlock">
                            <img
                              src={noImageFound}
                              alt={t("NoResultFound")}
                              className="no-image-placeholder"
                            />
                            <p>{t("NO_PRODUCTS_FOUND")}</p>
                          </div>
                        )}
                      </div>
                    </Box>
                  </div>
                  <div className="profileSectionBox__inner ProductListing">
                    <h3>{t("Listing_Online")}</h3>
                    <Box className="AccountSettingsWrapper">
                      <div className="account-settings">
                        {allSellerProductLoading ? (
                          "Loading..."
                        ) : allSellerProducts?.length > 0 ? (
                          allSellerProducts?.map((product) => (
                            <div
                              className="account-detail product"
                              key={product?.id}
                            >
                              <div className="product-image-container">
                                <img
                                  src={product?.productImage[0]}
                                  alt="product_image"
                                  className="product-image"
                                />
                              </div>
                              <div className="product-detail-container">
                                <Typography
                                  variant="body1"
                                  className="product-title"
                                  style={{ paddingLeft: "20px" }}
                                  onClick={() =>
                                    navigate(`/product/${product.id}`)
                                  }
                                >
                                  {product.title}
                                </Typography>
                                <Typography
                                  variant="body1"
                                  className="product_description"
                                >
                                  {product.description}
                                </Typography>
                                <div className="product-action">
                                  <Button className="EditIconBtn">
                                    <Edit
                                      className="editIcon"
                                      onClick={() => handleEditProduct(product)}
                                    />
                                  </Button>
                                  <Button className="DeleteIconBtn">
                                    <Delete
                                      onClick={() =>
                                        setShowConfirmDelete(product.id)
                                      }
                                    />
                                  </Button>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="noDataBlock">
                            <img
                              src={noImageFound}
                              alt={t("NoResultFound")}
                              className="no-image-placeholder"
                            />
                            <p>{t("NO_PRODUCTS_FOUND")}</p>
                          </div>
                        )}
                      </div>
                    </Box>
                  </div>

                  {soldProduct?.length > 0 ? (
                    <div className="profileSectionBox__inner ProductListing">
                      <h3>{t("SOLD_PRODUCT")}</h3>
                      <Box className="AccountSettingsWrapper">
                        <div className="account-settings">
                          {allSellerProductLoading ? (
                            "Loading..."
                          ) : soldProduct?.length > 0 ? (
                            soldProduct?.map((product) => (
                              <div
                                className="account-detail product"
                                key={product?.id}
                              >
                                <div className="product-image-container">
                                  <img
                                    src={product?.productImage[0]}
                                    alt="product_image"
                                    className="product-image"
                                  />
                                </div>
                                <div className="product-detail-container">
                                  <Typography
                                    variant="body1"
                                    className="product-title"
                                    style={{ paddingLeft: "20px" }}
                                  >
                                    {product.title}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    className="product_description"
                                  >
                                    {product.description}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    className="product_description"
                                  >
                                    {product.price}
                                  </Typography>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="noDataBlock">
                              <img
                                src={noImageFound}
                                alt={t("NoResultFound")}
                                className="no-image-placeholder"
                              />
                              <p>{t("NO_PRODUCTS_FOUND")}</p>
                            </div>
                          )}
                        </div>
                      </Box>
                    </div>
                  ) : null}
                </>
              ) : null}
            </Box>

            <div className="profileSectionBox__inner Box__inner PurchaseHistory">
              <h3>{t("PURCHASE_HISTORY")}</h3>
              <SoldProductsList
                soldProductLoading={soldProductLoading}
                soldProducts={soldProducts}
                t={t}
              />
            </div>

            <div className="SellerFlowWrapper">
              <SellerFlow />
              <BuyerFlow />
            </div>
          </div>
        </div>
      </main>

      <ConfirmDeleteModal
        open={showConfirmDelete}
        setOpen={setShowConfirmDelete}
        getProducts={getProducts}
      />
      <EditProductModal
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
        product={selectedProduct}
        refreshProducts={getProducts}
        // setGenderValue={setGenderValue}
      />
      {changePassword && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button
              style={{ fontSize: "3rem" }}
              className="close-button"
              onClick={() => setChangePassword(false)}
            >
              &times;
            </button>
            <ResetPassword
              isChangePassword={true}
              currentPasswordStatus={currentPasswordStatus}
              userId={userData?.id}
              setChangePassword={setChangePassword}
              getUserPassword={getUserPassword}
            />
          </div>
        </div>
      )}
      {loading && (
        <>
          <div className="loader-wrapper">
            <div className="loader"></div>
            <p>Loading...</p>
          </div>
        </>
      )}
    </>
  );
};

export default ProfilePage;
