import React, { useState } from "react";
import "./CustomTooltip.scss";

const CustomTooltip = ({ children, text, position = "bottom" }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div
      className="tooltip-container"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      <div className="tooltip-trigger">
        <span> {children}</span>
      </div>
      {isVisible && <div className={`tooltip tooltip-${position}`}>{text}</div>}
    </div>
  );
};

export default CustomTooltip;
