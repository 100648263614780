import Slider from "react-slick";
import { OurPartners } from "../../constraints/Footer";

import "./Partners.scss";
import { useTranslation } from "react-i18next";

const SliderItem = ({ href, src, alt }) => (
  <div className="slider-item">
    <a href={href} target="_blank" rel="noopener noreferrer">
      <div className="PartnersSlider">
        <div className="categorySlider__img">
          <img src={src} alt={alt} />
        </div>
        <div className="categorySlider__content">
          
        </div>
      </div>
    </a>
  </div>
);

const Partners = () => {
  const { t } = useTranslation()
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <>
      <div className="partners-slider">
        <div className="container">
          <h2 className="partners_slider_title">{t("OurPartners")}</h2>
          <Slider {...settings}>
            {OurPartners?.map((slide) => (
              <SliderItem key={slide.id} {...slide} />
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Partners;
