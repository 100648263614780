import axios from "axios";

const baseURL = process.env.REACT_APP_BACKEND_URL;

export function authHeader() {
  const token = localStorage.getItem("token");
  return { authorization: token || "" };
}

// LOGIN POST REQUEST
async function login(loginData) {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/login`,

      loginData
    );

    if (!data.status) {
      throw new Error(data.message || "Login failed");
    }

    localStorage.setItem("token", data?.data?.token);
    localStorage.setItem("userData", JSON.stringify(data?.data?.authData));

    return data?.data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
      e.response?.data?.Error ||
      e.message ||
      "An error occurred"
    );
  }
}

// GOOGLE LOGIN POST REQUEST
async function googleLogin(token) {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_GOOGLE_API}?access_token=${token}`
    );

    if (!data.email) {
      throw new Error(data.message || "Google login failed");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
      e.response?.data?.Error ||
      e.message ||
      "Google login error occurred"
    );
  }
}

// SOCIAL SIGNUP ACCOUNT
async function SignupSocialAccount(userData) {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/signupwithsocial`,
      userData,
      {
        headers: {
          ...authHeader(),
          "Content-Type": "application/json",
        },
      }
    );

    if (!data.status) {
      console.log(data.status)
      throw new Error(data.message || "Forgot password failed");
    }

    localStorage.setItem("token", data?.data?.token);
    localStorage.setItem("userData", JSON.stringify(data?.data?.authData));

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
      e.response?.data?.Error ||
      e.message ||
      "An error occurred during the forgot password process"
    );
  }
}

// SOCIAL SIGNUP
async function SocialSignUp(signUpData) {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/signup`,
      signUpData
    );

    if (!data.status) {
      throw new Error(data.message || "Signup  failed");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
      e.response?.data?.Error ||
      e.message ||
      "An error occurred during the forgot password process"
    );
  }
}

// LOGOUT REQUEST TO API
async function apiLogout() {
  try {
    const { data } = await axios.post(
      `${baseURL}/api/auth/logout`,
      {},
      {
        headers: authHeader(),
      }
    );

    if (!data.status) {
      throw new Error(data.message || "Logout failed");
    }

    localStorage.removeItem("token");
    localStorage.removeItem("userData");

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
      e.response?.data?.Error ||
      e.message ||
      "Logout error occurred"
    );
  }
}

// REFRESH TOKEN
async function refreshToken() {
  const refreshToken = localStorage.getItem("refreshToken");

  if (!refreshToken) {
    throw new Error("No refresh token available");
  }

  try {
    const { data } = await axios.post(`${baseURL}/api/auth/refreshToken`, {
      refreshToken: refreshToken,
    });

    if (!data.status) {
      throw new Error(data.message || "Failed to refresh token");
    }

    // Save the new token and refreshToken
    localStorage.setItem("isRole", "");
    localStorage.setItem("token", "");
    localStorage.setItem("token", data.accessToken);
    localStorage.setItem("refreshToken", data.refreshToken);

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
      e.response?.data?.Error ||
      e.message ||
      "Failed to refresh token"
    );
  }
}

// CHANGE USER PASSWORD
async function changeUserPassword(userId, password) {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/change-user-password`,
      { changedPassword: password, userId },
      {
        headers: authHeader(),
      }
    );

    if (!data.status) {
      throw new Error(data.message || "Failed to change password");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
      e.response?.data?.Error ||
      e.message ||
      "An error occurred while changing password"
    );
  }
}

// FORGOT USER PASSWORD
async function forgotUserPassword(token, password) {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/forget-user-password`,
      { token, newPassword: password },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (!data.status) {
      throw new Error(data.message || "Failed to reset password");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
      e.response?.data?.Error ||
      e.message ||
      "An error occurred while resetting password"
    );
  }
}

// FORGOT PASSWORD SEND EMAIL
async function forgotPasswordSendEmail(email) {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/forget-user-password-email`,
      { email }
    );

    if (!data.status) {
      throw new Error(data.message || "Failed to send forgot password email");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
      e.response?.data?.Error ||
      e.message ||
      "An error occurred while sending forgot password email"
    );
  }
}

// VERIFY EMAIL
async function verifyEmail(token) {
  try {
    const { data } = await axios.get(`${baseURL}/verify/${token}`);

    if (!data.status) {
      throw new Error(data.message || "Email verification failed");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
      e.response?.data?.Error ||
      e.message ||
      "An error occurred during email verification"
    );
  }
}

async function declineUserAccount(token) {
  try {
    const { data } = await axios.get(`${baseURL}/decline/${token}`, {
      headers: authHeader(),
    });

    if (!data.status) {
      throw new Error(data.message || "Account decline failed");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
      e.response?.data?.Error ||
      e.message ||
      "An error occurred during account decline"
    );
  }
}

// EXPORT AUTHSERVICE
const AuthService = {
  login,
  apiLogout,
  verifyEmail,
  googleLogin,
  SocialSignUp,
  refreshToken,
  forgotUserPassword,
  SignupSocialAccount,
  changeUserPassword,
  declineUserAccount,
  forgotPasswordSendEmail,
};

export default AuthService;
