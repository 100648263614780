import { Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next"; 
import "./BlogPost.scss";

const BlogPost = ({ title, image, excerpt, content, link }) => {
  return (
    <div className="blog-post">
      <img src={image} alt={title} className="blog-image" />
      <div className="blog-content">
        <h2 className="blog-title">{title}</h2>
        <p className="blog-excerpt">{excerpt}</p>
        <a href={link} className="read-more-link">
          {content}
        </a>
      </div>
    </div>
  );
};

const Blog = () => {
  const { t } = useTranslation(); 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const blogPosts = [
    {
      title: t("blog.post1.title"),
      image:
        "https://images.freeimages.com/vhq/images/previews/cf1/t-shirt-mockup-psd-397125.jpg?fmt=webp&w=500",
      excerpt: t("blog.post1.excerpt"),
      content: t("blog.post1.content"),
      link: "#",
    },
    {
      title: t("blog.post2.title"),
      image:
        "https://images.freeimages.com/vhq/images/previews/e89/identity-branding-mockup-vol-3-397171.jpg?fmt=webp&w=500",
      excerpt: t("blog.post2.excerpt"),
      content: t("blog.post2.content"),
      link: "#",
    },
    {
      title: t("blog.post3.title"),
      image:
        "https://images.freeimages.com/vhq/images/previews/227/vintage-business-card-mockup-394680.jpg?fmt=webp&w=500",
      excerpt: t("blog.post3.excerpt"),
      content: t("blog.post3.content"),
      link: "#",
    },
  ];

  return (
    <>
      <main className="BlogMainWrapper">
        <div className="container">
          <Typography variant="h3">{t("blog.title")}</Typography>
          <div className="BlogList">
            {blogPosts.map((post, index) => (
              <BlogPost
                key={index}
                title={post.title}
                image={post.image}
                excerpt={post.excerpt}
                content={post.content}
                link={post.link}
              />
            ))}
          </div>
        </div>
      </main>
    </>
  );
};

export default Blog;
