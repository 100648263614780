import { Box, Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CreditDebitModal from "./utils/CreditDebitModal";
import { calculateFees } from "./utils/FeeCalculator";
import PriceItem from "./utils/PriceItem";
import Spinner from "./utils/Spinner";

import BasicModal from "../../../component/Modals/BasicModal/BasicModal";
import ScheduleModal from "../../../component/Modals/ScheduleTime/ScheduleModal";
import { fetchProductById } from "../../../redux/slices/productSlice";
import { updateSoldProductThunk } from "../../../redux/slices/soldProductSlice";
import "./Payment.scss";
import axios from "axios";
import { decrementCartCount } from "../../../store/actions/cartAction";
import { fetchAllCartItems } from "../../../redux/slices/cartSlice";

const Payment = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const dropdownRef = useRef(null);

  const userData = JSON.parse(localStorage.getItem("userData"));
  const token = localStorage.getItem("token");
  const userId = userData?.id;

  const { productId } = useParams();
  const [product, setProduct] = useState({});
  const [isPremiumListing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sellerId, setSellerId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [priceDetails, setPriceDetails] = useState(null);
  const [editTimeSlot, setEditTimeSlot] = useState(false);
  const [showCustomModel, setShowCustomModel] = useState(false);
  const [isOpenSubCategory, setIsOpenSubCategory] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [selectedSubcategory, setSelectedSubcategory] = useState("cod");
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
  const openPaymentModal = () => setIsPaymentModalOpen(true);
  const closePaymentModal = () => setIsPaymentModalOpen(false);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpenSubCategory(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const paymentOptions = [
    {
      label: t("PAYMENT_AT_DELIVERY"),
      value: "cash",
      subcategory: [
        { label: t("Cash"), value: "cod" },
        { label: t("TPE"), value: "TPE" },
      ],
    },
    { label: t("DEBIT_CARD"), value: "debit_card" },
    { label: t("Buy_Now_Pay_Later"), value: "bnpl" },
  ];

  useEffect(() => {
    if (product?.price) {
      const details = calculateFees(Number(product.price), isPremiumListing);
      setPriceDetails(details);
    }
  }, [product, isPremiumListing]);

  async function getProductById(productId) {
    try {
      setLoading(true);
      const getProductDetailResponse = await dispatch(
        fetchProductById(productId)
      );

      if (getProductDetailResponse?.payload.statusCode === 200) {
        const productData = getProductDetailResponse?.payload?.data;
        setSellerId(productData?.userId);
        setProduct(productData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const fetchProductsInCart = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/get-cart`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error("Error fetching cart products:", error);
    }
  };

  const handleRemoveFromCart = async (productId) => {
    try {
      if (productId !== "") {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/cart/${productId}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response?.status === 200) {
          dispatch(fetchAllCartItems());

          // fetchProductsInCart();
        }
      }
    } catch (error) {
      toast.error(t("ERROR_IN_REMOVING_FROM_CART"));
    }
  };
  const openModal = () => {
    setShowCustomModel(true);
    handleRemoveFromCart(productId);
  };

  const updatePaymentStatus = async () => {
    const sendData = {
      paymentOption: selectedPaymentOption,
      paymentStatus: true,
      status: true,
    };
    try {
      const updateResponse = await dispatch(
        updateSoldProductThunk({ productId, userId, sellerId, sendData })
      );
      return updateResponse?.data?.data;
    } catch (error) {
      toast.error("Error in updating payment status!");
      return error;
    }
  };

  const handlePayment = async () => {
    if (selectedPaymentOption) {
      if (selectedPaymentOption === "debit_card") {
        openPaymentModal();
      } else {
        updatePaymentStatus();
        openModal();
      }
    } else {
      toast.error(t("SELECT_PAYMENT_METHOD"));
    }
  };

  const handleModalClose = () => {
    // setCompleteDetails(null);
    setIsModalOpen(false);
  };

  const handleOpenTimeSlotBeforePayment = () => {
    if (!selectedPaymentOption) {
      toast.error(t("SELECT_PAYMENT_METHOD"));
    } else {
      setIsModalOpen(true);
    }
  };

  const handleOptionSelect = (option) => {
    if (option.value === "cash") {
      setIsOpenSubCategory(true);
    }
    setSelectedPaymentOption(option.value);
  };

  const handleSubcategorySelect = (subOption) => {
    setSelectedSubcategory(subOption.value);
  };

  useEffect(() => {
    productId && getProductById(productId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  if (loading) {
    return (
      <div className="loading-container">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <main className="PaymentMainWrapper">
        <div className="container">
          <div className="sectionHeader">
            <h3>{t("SELECT_PAYMENT_METHOD")}</h3>
          </div>
          <div className="payment-methods">
            {paymentOptions.map((option) => (
              <div key={option.value} className="payment-option">
                <Button
                  className={`payment-button  ${selectedPaymentOption === option.value ? "active" : ""
                    }`}
                  onClick={() => handleOptionSelect(option)}
                >
                  {option.label}
                </Button>
                {isOpenSubCategory &&
                  option.subcategory &&
                  selectedPaymentOption === option.value && (
                    <div className="subcategory-dropdown" ref={dropdownRef}>
                      {option.subcategory.map((subOption) => (
                        <button
                          key={subOption.value}
                          className={`subcategory-button ${selectedSubcategory === subOption.value
                              ? "active"
                              : ""
                            }`}
                          onClick={() => {
                            handleSubcategorySelect(subOption);
                            setIsOpenSubCategory(false);
                          }}
                        >
                          {subOption.label}
                        </button>
                      ))}
                    </div>
                  )}
              </div>
            ))}
          </div>

          <div className="sectionHeader">
            <h3>{t("ORDER_INFORMATION")}</h3>
          </div>
          <div className="orderInfo">
            <div className="orderInfo__inner">
              <div className="orderInfo__image">
                <img src={product?.productImage?.[0]} alt={product?.title} />
              </div>
              <div className="orderInfo__content">
                <div className="info">
                  <h4>{product?.title}</h4>


                  <p>

                    {product?.description}
                  </p>
                  <div className="info-list">
                    <span>
                      <b>{t("BRAND")}:</b>
                      {product?.brand}
                    </span>
                    <span>
                      <b>{t("CATEGORY")}:</b>
                      {product?.category}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="price-summary orderInfo__inner">
              <Box></Box>
              <Box className="price-summary-inner">
                <PriceItem label={t("SUBTOTAL")} amount={priceDetails?.price} />
                <PriceItem
                  label={t("DELIVERY_COSTS")}
                  amount={priceDetails?.deliveryFee}
                />

                <PriceItem
                  label={t("BUYER_FEE")}
                  amount={priceDetails?.buyerFee}
                  content={t("BUYER_PROTECTION_FEE")}
                />
                {isPremiumListing && (
                  <PriceItem
                    label={t("PREMIUS_LISTING_FEES")}
                    amount={priceDetails?.premiumListingFee}

                  />
                )}
                <div className="price-item total">
                  <span>Total</span>
                  <span>{priceDetails?.total.toFixed(2)} MAD</span>
                </div>
                <div className="PaymmentButton">
                  <Button
                    className="btn_dark"
                    onClick={() => handleOpenTimeSlotBeforePayment()}
                  >
                    {t("PAY_NOW")}
                  </Button>
                </div>
              </Box>
            </div>
          </div>
          {showCustomModel && (
            <BasicModal
              open={showCustomModel}
              title={t("PAYMENT_SUCCESS")}
              description={t("PAYMENT_SUCCESS_MESSAGE")}
            />
          )}
          <CreditDebitModal
            isOpen={isPaymentModalOpen}
            onClose={closePaymentModal}
            openModal={openModal}
            updatePaymentStatus={updatePaymentStatus}
          />
          <div>
            <ScheduleModal
              isOpen={isModalOpen}
              onClose={() => handleModalClose(false)}
              userId={userId}
              productId={productId}
              sellerId={sellerId}
              setEditTimeSlot={setEditTimeSlot}
              editTimeSlot={editTimeSlot}
              priceDetails={priceDetails}
              handlePayment={handlePayment}
            />
          </div>
        </div>
      </main>
    </>
  );
};

export default Payment;
