import Slider from "@mui/material/Slider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { brandOptions } from "../../constraints/Brand";
import { updateFilters } from "../../redux/slices/filterSlice";
import "./FilterPopup.scss";



const blackTheme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        root: {
          color: "black",
        },
        thumb: {
          color: "black",
          "&:hover, &.Mui-active": {
            boxShadow: "none",
          },
        },
        track: {
          color: "black",
        },
        rail: {
          color: "rgba(0,0,0,0.26)",
        },
        valueLabel: {
          backgroundColor: "black",
          color: "white",
        },
      },
    },
  },
});

const FilterPopup = ({
  isOpen,
  selectedBrands,
  priceRange,
  setSelectedBrands,
  onPriceRangeChange,
  handleReset,
}) => {
  const { t } = useTranslation();
  const popupRef = useRef(null);
  // const categoryOptions = getCategoryOptions(t);
  const dispatch = useDispatch()

  const handleBrandChange = (brandValue) => {
    if (selectedBrands.includes(brandValue)) {
      const newBrands = selectedBrands.filter((brand) => brand !== brandValue);
      dispatch(updateFilters({ brand: newBrands }));
      setSelectedBrands(newBrands);
    } else {
      const newBrands = [...selectedBrands, brandValue];
      dispatch(updateFilters({ brand: newBrands }));
      setSelectedBrands(newBrands);
    }
  };


  return (
    <>
      {isOpen ? (
        <div className="popup">
          <div className="popup-content" ref={popupRef}>

            <div className="filter-section">
              <h4>{t("FILTER_BY_BRAND")}</h4>
              <ul>
                {brandOptions?.map((brand) => (
                  <li key={brand.value}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedBrands?.includes(brand.value)}
                        onChange={() => handleBrandChange(brand.value)}
                      />
                      <div className="filter-brand-icon">{brand.icon}</div>
                      <div>{brand.label}</div>
                    </label>
                  </li>
                ))}
              </ul>
            </div>

            <div className="filter-section">
              <h4>{t("FILTER_BY_PRICE")}</h4>
              <div className="price-slider-container">
                <ThemeProvider theme={blackTheme}>
                  <Slider
                    value={priceRange}
                    onChange={onPriceRangeChange}
                    valueLabelDisplay="auto"
                    min={0}
                    max={100000}
                    step={100}
                  />
                </ThemeProvider>
                <div className="price-range-labels">
                  <span>{priceRange[0]} MAD</span>
                  <span>{priceRange[1]} MAD</span>
                </div>
              </div>
            </div>
            <button className="apply-filters-button" onClick={handleReset}>
              {t("CLEAR_FILTER")}
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default FilterPopup;
