import React, { useState, useRef } from "react";
import { FaCamera } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { toast } from "react-toastify";
import "./ImageUploader.scss";

const ImageUpload = ({ t, formData, setFormData, errors, validateForm }) => {
  const [fileItems, setFileItems] = useState(Array(6).fill(null));
  const imageInputRef = useRef(null);

  const labels = [
    "Front View – Full frontal shot showcasing the product required",
    "Back View – Full back view highlighting design details required",
    "Side View – Profile shot to show shape and dimension required",
    "Close-Up of Logo – Focused shot on the brand logo or emblem required",
    "Close-Up of Finish/Material – Highlight texture, stitching, or luxury finishes required",
    "Interior View – Show the inside, compartments, or lining details required",
  ];

  const handleImageChange = (e, index) => {

    const file = e.target.files[0];
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/webp",
      "image/heic",
    ];
    const maxSizeInMB = 5;

    if (!file) return;

    if (!allowedTypes.includes(file.type)) {
      toast.error(t("INVALID_FILE_TYPE", { fileName: file.name }));
      return;
    }

    const fileSizeInMB = file.size / (1024 * 1024);
    if (fileSizeInMB > maxSizeInMB) {
      toast.error(
        t("FILE_TOO_LARGE", { fileName: file.name, maxSize: maxSizeInMB })
      );
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {

      const newFileItems = [...fileItems];
      newFileItems[index] = reader.result;
      setFileItems(newFileItems);
      setFormData(prev => ({
        ...prev,
        pictureOfItem: [...(prev.pictureOfItem || []), file]
      }));
      validateForm(true)
    };
    reader.onerror = () => {
      toast.error(t("FILE_READ_ERROR", { fileName: file.name }));
    };
    reader.readAsDataURL(file);
  };

  const removeImage = (index) => {
    const newFileItems = fileItems.filter((_, idx) => idx !== index);
    setFileItems(newFileItems);

    const newFileUploadItems = formData?.pictureOfItem.filter((_, idx) => idx !== index);

    setFormData((prev) => ({
      ...prev,
      pictureOfItem: newFileUploadItems,
    }));
  };


  return (
    <div className="ImageUpload ImageUploadGrid">
      {labels.map((label, index) => (
        <div
          key={index}
          className="singleImageAngle"
        >
          {fileItems[index] ? (
            <div className="singleImageAngleImgPreview">
              <img
                src={fileItems[index]}
                alt={label}
                className="w-full h-full object-cover"
              />
              <button
                type="button"
                className="singleImageAngleClosebutton"
                onClick={() => removeImage(index)}
              >
                <RxCross1 className="h-4 w-4 text-white" />
              </button>
            </div>
          ) : (
            <>
              <div className="flex flex-col items-center singleImageAngleImgPreviewSkeleton">
                <label
                  className="relative aspect-square border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-gray-500 flex flex-col items-center justify-center p-4 transition-all"
                  style={{
                    width: "150px",
                    height: "150px",
                  }}
                >
                  <FaCamera className="h-8 w-8 mb-2 text-gray-600" />
                  <p className="singleAngleLabel">
                    {label}
                  </p>
                  <input
                    type="file"
                    name={`image-${index}`}
                    accept="image/*"
                    onChange={(e) => handleImageChange(e, index)}
                    ref={imageInputRef}
                    className="hidden"
                  />
                </label>

                <p
                  className="imageUploadError"
                >
                  {`${label.split("–")[0].trim()} is required.`}
                </p>
              </div>
            </>
          )}
        </div>
      ))}

    </div>
  );
};

export default ImageUpload;
