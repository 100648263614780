import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserService from "../services/UserService";

// ASYNC THUNKS

// Get User By ID
export const fetchUserById = createAsyncThunk(
  "user/fetchUserById",
  async (_, thunkAPI) => {
    try {
      return await UserService.getUserById();
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Notifications
export const fetchNotifications = createAsyncThunk(
  "user/fetchNotifications",
  async (userId, thunkAPI) => {
    try {
      return await UserService.getNotificationByUserId(userId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Cart Count
export const fetchCartCount = createAsyncThunk(
  "user/fetchCartCount",
  async (userId, thunkAPI) => {
    try {
      return await UserService.getCartCountByUserId(userId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateUserById = createAsyncThunk(
  "user/updateUserById",
  async (updatedValue, thunkAPI) => {
    try {
      return await UserService.updateUser(updatedValue);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Fetch Favorite Products
export const fetchFavoriteProduct = createAsyncThunk(
  "user/fetchFavoriteProduct",
  async (userId, thunkAPI) => {
    try {
      return await UserService.fetchFavoriteProductByUserId(userId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// SLICE
const userSlice = createSlice({
  name: "user",
  initialState: {
    userData: null,
    userInfo: null,
    notifications: [],
    cartCount: 0,
    favoriteProducts: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetUserState: (state) => {
      state.userData = null;
      state.notifications = [];
      state.cartCount = 0;
      state.favoriteProducts = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action.payload.data;
      })
      .addCase(fetchUserById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.notifications = action.payload.notifications;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchCartCount.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCartCount.fulfilled, (state, action) => {
        state.loading = false;
        state.cartCount = action.payload.count;
      })
      .addCase(fetchCartCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchFavoriteProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFavoriteProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.favoriteProducts = action.payload.data;
      })
      .addCase(fetchFavoriteProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateUserById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUserById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userInfo = action.payload?.data.user;
      })
      .addCase(updateUserById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

// EXPORT ACTIONS
export const { resetUserState } = userSlice.actions;

// EXPORT REDUCER
export default userSlice.reducer;
