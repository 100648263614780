import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import noImageFound from "../../../assets/images/noProductFound.png";
import icons from "../../../assets/images/svg/icons";
import FilterPopup from "../../../component/FilterPopup/FilterPopup";
import ItemCard from "../../../component/ItemCard/ItemCard";
import Partners from "../../../component/Partners/Partners";
import SimpleSlider from "../../../component/SimpleSlider/Slider";
import { CapitalizedText, useCategories } from "../../../constraints/Home";
import { fetchAllProduct } from "../../../redux/slices/productSlice";

import { toast } from "react-toastify";
import { addFavorite } from "../../../redux/slices/favoriteSlice";
import { fetchFavoriteProduct } from "../../../redux/slices/userSlice";

import "./Home.scss";
const { FilterIcon } = icons;

const Home = () => {
  const filterRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { allProductData, loading } = useSelector((state) => state.product);
  const { favoriteProducts: userFavoriteProduct } = useSelector(
    (state) => state.user
  );
  const userData = JSON.parse(localStorage.getItem("userData"));
  const { mainCategory, subCategory } = useCategories();
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 100000]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [favoriteProducts, setFavoriteProducts] = useState([]);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(0);
  const [activeSubCategoryIndex, setActiveSubCategoryIndex] = useState(0);

  useEffect(() => {
    const params = {};
    if (selectedGender) params.gender = selectedGender;
    if (selectedCategory) params.category = selectedCategory;

    dispatch(fetchAllProduct(params));
    fetchFavoriteProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedGender, selectedCategory]);

  const handleCloseBrandFilter = () => {
    setShowFilterPopup(!showFilterPopup);
  };

  const handleCategoryChange = (index, gender) => {
    setActiveCategoryIndex(index);
    setSelectedGender(gender);
  };

  const handleSubCategoryChange = (index, category) => {
    setActiveSubCategoryIndex(index);
    setSelectedCategory(category);
  };

  const handlePriceRangeChange = (event) => {
    setPriceRange(event.target.value);
  };

  const handleReset = () => {
    setPriceRange([0, 100000]);
    setSelectedBrands([]);
  };

  const handleSetFavorite = async (productId) => {
    if (!userData?.id) {
      navigate("/login");
    } else {
      try {
        const response = await dispatch(addFavorite(productId));
        if (response?.payload?.statusCode === 200) {
          const params = {};
          if (selectedGender) params.gender = selectedGender;
          if (selectedCategory) params.category = selectedCategory;
          dispatch(fetchAllProduct(params));
          toast.success(t(response?.payload?.message), { autoClose: 800 });

          await fetchFavoriteProducts();
        }
      } catch (error) {
        toast.error(t("ERROR_IN_TOGGLING_FAVORITE"));
        console.error("Error toggling favorite:", error);
      }
    }
  };

  const fetchFavoriteProducts = async () => {
    if (!userData?.id) return;
    try {
      const response = await dispatch(fetchFavoriteProduct(userData?.id));
      if (response?.payload?.data) {
        const data = response.payload.data;
        setFavoriteProducts(data);
      }
    } catch (error) {
      console.error("Error fetching favorite products:", error);
      setFavoriteProducts([]);
    }
  };

  const filteredProducts = Array.isArray(allProductData)
    ? allProductData.map((product) => {
      const isFavorite = favoriteProducts?.[product.id] !== undefined;
      return {
        ...product,
        isFavorite: isFavorite,
      };
    })
    : [];

  const finalProduct = Array.isArray(filteredProducts)
    ? filteredProducts?.filter((product) => {
      console.log(filteredProducts)
      const isBrandMatch =
        selectedBrands.length === 0 ||
        selectedBrands.includes(product?.brand);
      const isPriceMatch =
        parseFloat(product?.price) >= priceRange[0] &&
        parseFloat(product?.price) <= priceRange[1];
      const isVerified = product?.isVerified === true;



      return isBrandMatch && isPriceMatch && isVerified;
    })
    : [];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setShowFilterPopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <section className="HomeMainContainer">
        <Box className="container">
          {/* <Box className="CatOuterContainer mobile_visible">
            <Box className="CatBtnContainer">
              {mainCategory?.map((item, index) => (
                <Button
                  key={index}
                  className={
                    activeCategoryIndex === index ? "activeCategory" : ""
                  }
                  onClick={() => handleCategoryChange(index, item.gender)}
                >
                  {item?.icons} {item?.name}
                </Button>
              ))}
            </Box>
          </Box> */}
          <SimpleSlider />
          <Box className="CatContainer">
            <Box className="CatOuterContainer">
              <Box className="CatBtnOuterContainer">
                <Typography variant="h6">{t("CATEGORIES")}</Typography>
              </Box>
              <Box className="CatBtnContainer">
                {mainCategory?.map((item, index) => (
                  <React.Fragment key={index}>
                    <Button
                      className={
                        activeCategoryIndex === index ? "activeCategory" : ""
                      }
                      onClick={() => handleCategoryChange(index, item.gender)}
                    >
                      {item?.icon} {item?.name}
                    </Button>
                  </React.Fragment>
                ))}
              </Box>
            </Box>

            <Box ref={filterRef} className="CatBtnOuterContainer">
              <Typography variant="h6">{t("PRODUCTS")}</Typography>
              <Button
                className="IconBtn FilterBtn"
                onClick={() => setShowFilterPopup(!showFilterPopup)}
              >
                <FilterIcon />
              </Button>

              <FilterPopup
                isOpen={showFilterPopup}
                priceRange={priceRange}
                selectedBrands={selectedBrands}
                setPriceRange={setPriceRange}
                onClose={handleCloseBrandFilter}
                setSelectedBrands={setSelectedBrands}
                onPriceRangeChange={handlePriceRangeChange}
                handleReset={handleReset}
              />
            </Box>

            <Box className="CatOuterContainer">
              <Box className="CatBtnContainer">
                {subCategory?.map((item, index) => (
                  <React.Fragment key={index}>
                    <Button
                      className={
                        activeSubCategoryIndex === index
                          ? "activeSubCategory"
                          : ""
                      }
                      onClick={() =>
                        handleSubCategoryChange(index, item?.category)
                      }
                    >
                      {item?.icon} {item?.name}
                    </Button>
                  </React.Fragment>
                ))}
              </Box>
            </Box>
            {selectedBrands.length > 0 && (
              <Box className="SelectedFilterOuterContainer">
                <h3>{t("BRAND")}</h3>
                <Box className="SelectedFilterContainer">
                  {selectedBrands?.map((filter, index) => (
                    <div className="show-applied-filter" key={index}>
                      <CapitalizedText text={filter} />
                      <RxCross2
                        onClick={() => {
                          setSelectedBrands(
                            selectedBrands.filter((ele) => ele !== filter)
                          );
                        }}
                      />
                    </div>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </section>

      <section className="HomeMainContainer">
        {loading ? (
          <>
            <div className="loader-wrapper">
              <div className="loader"></div>
              <p>Loading...</p>
            </div>
          </>
        ) : (
          <Box className="container">
            {finalProduct?.length > 0 ? (
              <Box className="ProductCardGridContainer">
                {finalProduct.map((item, index) => {
                  return (
                    <ItemCard
                      userFavoriteProduct={userFavoriteProduct}
                      productId={item?.id}
                      key={index}
                      item={item}
                      name={item.title}
                      price={item.price}
                      description={item.description}
                      imageSrc={item?.productImage[0]}
                      likes={item.likes}
                      isFavorite={item.isFavorite}
                      isSold={item.isSold}
                      goToProductDetails={() => navigate(`/product/${item.id}`)}
                      handleSetFav={handleSetFavorite}
                      userData={userData}
                    />
                  );
                })}
              </Box>
            ) : (
              <div className="noDataBlock">
                <img
                  src={noImageFound}
                  alt={t("NO_PRODUCTS_FOUND")}
                  className="no-image-placeholder"
                />
                <p className="noDataBlock">{t("NO_PRODUCTS_FOUND")}</p>
              </div>
            )}
          </Box>
        )}
      </section>
      <Partners />
    </>
  );
};

export default Home;
