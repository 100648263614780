import { ThemeProvider } from "@mui/material";
import React from "react";
import {
  matchPath,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";

// ROUTING PUBLIC AND PRIVATE
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";

// COMPONENT USED
import Footer from "./component/Footer/Footer";
import Header from "./component/Header/Header";
import ChatInterface from "./component/ChatInterface/ChatInterface";
import NotificationPage from "./component/Modals/Notification/NotificationPage";
import SellerProfileForm from "./pages/private/SellerProfile/SellerProfileForm";
import BuyerChatInterface from "./component/ChatInterface/BuyerChatInterface/BuyerChatInterface";

// IMPORT PUBLIC PAGES
import FAQ from "./pages/public/Faq/FAQ";
import Home from "./pages/public/Home/Home";
import About from "./pages/public/About/About";
import Blog from "./pages/public/Blog/BlogPost";
import Signup from "./pages/public/SignUp/SignUp";
import SignIn from "./pages/public/SignIn/SignIn";
import NotFound from "./pages/public/NotFound/NotFound";
import Journey from "./pages/public/FooterLinks/Journey";
import ContactUs from "./pages/public/ContactUs/ContactUs";
import AcceptOffer from "./pages/public/AcceptOffer/AcceptOffer";
import SearchProduct from "./pages/public/SearchProduct/SearchProduct";
import ForgotPassword from "./pages/public/ForgotPassword/ForgotPassword";
import {
  DeclineAccount,
  VerifyEmail,
} from "./pages/public/VerifyEmail/VerifyDeclineEmail";

// IMPORT PRIVATE PAGES
import CartPage from "./pages/private/Cart/CartPage";
import ProfilePage from "./pages/private/Profile/ProfilePage";
import Payment from "./pages/private/PurchaseProduct/Payment";
import AddProduct from "./pages/private/AddProduct/AddProduct";
import RejectOffer from "./pages/public/RejectOffer/RejectOffer";
import ProductPage from "./pages/private/Productpage/ProductPage";
import ResetPassword from "./pages/public/ResetPassword/ResetPassword";
import ProductDetails from "./pages/private/ProductDetail/ProductDetails";
import FavoriteProducts from "./pages/private/FavoriteProduct/FavoriteProducts";

// PRIVATE ADMIN PAGES
import AdminDashboard from "./pages/admin/Dashboard/AdminDashboard";

// CONSTRAINTS
import { FooterPath, HeaderPath } from "./constraints/Header";
import theme from "./theme";

import "./App.scss";
import { useSelector } from "react-redux";
import TermAndConditions from "./pages/public/TermAndConditions/TermAndConditions";
import SecondHandGoods from "./pages/public/SecondHandGoods/SecondHandGoods";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AppContent />
      </Router>
    </ThemeProvider>
  );
}

function AppContent() {
  const location = useLocation();
  const productPageRegex = /^\/product\/\d+$/;
  const paymentPageRegex = /^\/payment\/\d+$/;
  const userData = JSON.parse(localStorage.getItem("userData"));
  const isAdminLogin = userData?.is_admin === true;

  const ShowHeader =
    HeaderPath.some((pattern) => matchPath(pattern, location.pathname)) ||
    productPageRegex.test(location.pathname) ||
    (paymentPageRegex.test(location.pathname) && !isAdminLogin);

  const ShowFooter =
    FooterPath.some((pattern) => matchPath(pattern, location.pathname)) ||
    productPageRegex.test(location.pathname) ||
    (paymentPageRegex.test(location.pathname) && !isAdminLogin);

  const { loading } = useSelector((state) => state.product);
  return (
    <React.Fragment>
      {ShowHeader && <Header />}

      <Routes>
        <Route
          path="/login"
          element={
            <PublicRoute>
              <SignIn />
            </PublicRoute>
          }
        />
        <Route
          path="/signup"
          element={
            <PublicRoute>
              <Signup />
            </PublicRoute>
          }
        />
        {isAdminLogin !== true ? (
          <>
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/buyer-chat/:token" element={<BuyerChatInterface />} />
            <Route path="/notification" element={<NotificationPage />} />
            <Route
              path="/search-product/:searchItem"
              element={<SearchProduct />}
            />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/verify/:token" element={<VerifyEmail />} />
            <Route path="/decline/:token" element={<DeclineAccount />} />
            <Route path="/offer-accept/:token" element={<AcceptOffer />} />
            <Route path="/offer-reject/:token" element={<RejectOffer />} />
            <Route path="/product/:id" element={<ProductDetails />} />
            <Route path="/about" element={<About />} />
            <Route path="/goods" element={<SecondHandGoods />} />
            <Route path="/terms-conditions" element={<TermAndConditions />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/journey" element={<Journey />} />
            <Route path="/" element={<Home />} />
          </>
        ) : null}

        <Route
          path="/products"
          element={
            <PrivateRoute>
              <ProductPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/add-product"
          element={
            <PrivateRoute>
              <AddProduct />
            </PrivateRoute>
          }
        />
        <Route
          path="/seller-profile"
          element={
            <PrivateRoute>
              <SellerProfileForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/favorites"
          element={
            <PrivateRoute>
              <FavoriteProducts />
            </PrivateRoute>
          }
        />
        <Route
          path="/cart"
          element={
            <PrivateRoute>
              <CartPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment/:productId"
          element={
            <PrivateRoute>
              <Payment />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <PrivateRoute adminOnly={true}>
              <AdminDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/chat"
          element={
            <PrivateRoute>
              <ChatInterface />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {loading ? null : ShowFooter && <Footer />}
    </React.Fragment>
  );
}

export default App;
