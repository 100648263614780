import { ReactComponent as AccessoriesIcon } from "./Icons/AccessoriesIcon.svg";
import { ReactComponent as Add } from "./Icons/Add.svg";
import { ReactComponent as BagIcon } from "./Icons/BagIcon.svg";
import { ReactComponent as ChildIcon } from "./Icons/ChildIcon.svg";
import { ReactComponent as CloseIcon } from "./Icons/CloseIcon.svg";
import { ReactComponent as FacebookIcon } from "./Icons/FacebookIcon.svg";
import { ReactComponent as FemaleIcon } from "./Icons/Female.svg";
import { ReactComponent as FilterIcon } from "./Icons/FilterIcon.svg";
import { ReactComponent as GoogleIcon } from "./Icons/GoogleIcon.svg";
import { ReactComponent as HeartIcon } from "./Icons/HeartIcon.svg";
import { ReactComponent as HideIcon } from "./Icons/HideIcon.svg";
import { ReactComponent as JewelleryIcon } from "./Icons/JewelleryIcon.svg";
import { ReactComponent as LockIcon } from "./Icons/LockIcon.svg";
import { ReactComponent as MailIcon } from "./Icons/MailIcon.svg";
import { ReactComponent as MaleIcon } from "./Icons/MaleIcon.svg";
import { ReactComponent as ProductBox } from "./Icons/ProductBox.svg";
import { ReactComponent as ShoesIcon } from "./Icons/ShoesIcon.svg";
import { ReactComponent as ShowIcon } from "./Icons/ShowIcon.svg";
import { ReactComponent as UserIcon } from "./Icons/UserIcon.svg";
import { ReactComponent as WatchIcon } from "./Icons/WatchIcon.svg";

import { ReactComponent as Globe } from "./Icons/Globe.svg";
import { ReactComponent as Heart } from "./Icons/Heart.svg";
import { ReactComponent as HeartFill } from "./Icons/Heartfill.svg";
import { ReactComponent as Home } from "./Icons/Home.svg";
import { ReactComponent as Bag } from "./Icons/Shopping Bag.svg";
import { ReactComponent as User } from "./Icons/User.svg";
import { ReactComponent as Chat } from "./Icons/chat.svg";

import { ReactComponent as GucciLogo } from "./Icons/GucciLogo.svg";
import { ReactComponent as LV } from "./Icons/LV.svg";
import { ReactComponent as Prada } from "./Icons/Prada.svg";
import { ReactComponent as Hermes } from "./Icons/Hermes.svg";
import { ReactComponent as Bulgari } from "./Icons/Bulgari.svg";
import { ReactComponent as Dior } from "./Icons/Dior.svg";
import { ReactComponent as Cartier } from "./Icons/Cartier.svg";
import { ReactComponent as Rolex } from "./Icons/Rolex.svg";

const icons = {
  Globe,
  Heart,
  HeartFill,
  Home,
  Bag,
  User,
  Chat,
  FilterIcon,
  FemaleIcon,
  ChildIcon,
  MaleIcon,
  ShoesIcon,
  AccessoriesIcon,
  BagIcon,
  JewelleryIcon,
  WatchIcon,
  HeartIcon,
  MailIcon,
  LockIcon,
  HideIcon,
  ShowIcon,
  CloseIcon,
  UserIcon,
  ProductBox,
  GoogleIcon,
  FacebookIcon,
  Add,
  GucciLogo,
  LV,
  Prada,
  Hermes,
  Bulgari,
  Dior,
  Cartier,
  Rolex,
};

export default icons;
