import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export function authHeader() {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
}

// OFFER REJECT REQUEST
async function offerAccept(endpoint, token) {
  try {
    const { data } = await axios.get(`${baseURL}/${endpoint}/${token}`, {
      headers: authHeader(),
    });

    if (!data.status) {
      throw new Error(data.message || "Offer accept failed");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
      e.response?.data?.Error ||
      e.message ||
      "An error occurred during offer accept"
    );
  }
}

// OFFER REJECT REQUEST
async function offerReject(endpoint, token) {
  try {
    const { data } = await axios.get(`${baseURL}/${endpoint}/${token}`, {
      headers: authHeader(),
    });

    if (!data.status) {
      throw new Error(data.message || "Offer rejection failed");
    }

    return data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
      e.response?.data?.Error ||
      e.message ||
      "An error occurred during offer rejection"
    );
  }
}

const deleteOffersWithoutChat = async (userId) => {
  try {
    const response = await axios.delete(`${baseURL}/offer/no-chat/${userId}`, {
      headers: {
        ...authHeader(),
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      throw new Error(response.data.message || "Failed to delete offer");
    }

    return response.data;
  } catch (e) {
    throw new Error(
      e.response?.data?.message ||
      e.response?.data?.Error ||
      e.message ||
      "An error occurred while deleting the offer"
    );
  }
};



// EXPORT ACTIONS
const OfferService = {
  offerReject,
  offerAccept,
  deleteOffersWithoutChat,
};

// EXPORT REDUCER
export default OfferService;
